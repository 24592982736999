export const products1 = [
  {
    id: 0,
    imgSrc: "/images/products/workwear_005.jpg",
    imgHoverSrc: "/images/products/Workwear_002.jpg",
    title: "7613 // MOTION TEX PLUS",
    sizes: ["XS", "S", "M", "L", "X", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    width: 800,
    height: 600,
    isAvailable: true,
  },
];

// * ================== Workwear ==================

export const workweare1 = [
  {
    id: 1,
    imgSrc: "/images/products/Workwear_001.jpg",
    imgHoverSrc: "/images/products/Workwear_002.jpg",
    title: "7613 // MOTION TEX PLUS",
    sizes: ["XS", "S", "M", "L", "X", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "On Sale"],

    width: 800, // Ensure these are present and are numbers
    height: 600,

    description: {
      images: [
        "/images/description_product/westen/1.png",
        "/images/description_product/westen/2.png",
        "/images/description_product/westen/3.png",
        "/images/description_product/westen/4.png",
        "/images/description_product/westen/5.png",
        "/images/description_product/westen/6.png",
        "/images/description_product/westen/7.png",
        "/images/description_product/westen/8.png",
        "/images/description_product/westen/9.png",
        "/images/description_product/westen/10.png",
      ],
      brand: "Arbeitskleidung",
      subcategory: "Westen",
      product_details: ["Oberstoff: Polyester: 94%; Futter: Polyester: 100%"],
      further_information: [
        "Oberstoff: Polyester / Elasthan, schwarz",
        "Futter: Polyester, schwarz",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Softshellweste, schwarz (Farbcode: 1000), 310 g/qm, angenehmer Tragekomfort dank weichem Softshell-Material mit wasser- und windabweisenden Eigenschaften, verlängertem Rücken und Stretchanteil, Kordelzug im Saum für optimalen Sitz, sportliches Design, Reflexelemente für bessere Sichtbarkeit, Stehkragen, durchgängiger YKK-Reißverschluss mit Kinnschutz und doppellagigem Windstopper innen, Innen-, Brust- und Seitentaschen, Oberstoff: Wassersäule: 10.000 mmH2O / Atmungsaktivität: 2.000 g/qm/24h, Regular Fit, OEKO-TEX STANDARD 100®, abnehmbare Kapuze optional erhältlich",
      ],
    },
    isAvailable: true,
  },

  {
    id: 2,
    imgSrc: "/images/products/workwear_005.jpg",
    imgHoverSrc: "/images/products/workwear_006.jpg",
    title: "7161 // MOTION TEX LIGHT",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals"],
    width: 800, // Ensure these are present and are numbers
    height: 600,
    description: {
      images: [
        "/images/description_product/westen/1.png",
        "/images/description_product/westen/2.png",
        "/images/description_product/westen/3.png",
        "/images/description_product/westen/4.png",
        "/images/description_product/westen/5.png",
        "/images/description_product/westen/6.png",
        "/images/description_product/westen/7.png",
        "/images/description_product/westen/8.png",
        "/images/description_product/westen/9.png",
        "/images/description_product/westen/10.png",
      ],
      brand: "Arbeitskleidung",
      subcategory: "Westen",
      product_details: [
        "Futter: Polyester: 100% ; Oberstoff: Polyester: 94%; Elasthan: 6%",
      ],
      further_information: [
        "Oberstoff: Polyester / Elasthan, schwarz",
        "Futter: Polyester, schwarz",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Softshellweste, schwarz (Farbcode: 1000), 310 g/qm, angenehmer Tragekomfort dank weichem Softshell-Material mit wasser- und windabweisenden Eigenschaften, verlängertem Rücken und Stretchanteil, Kordelzug im Saum für optimalen Sitz, sportliches Design, Reflexelemente für bessere Sichtbarkeit, Stehkragen, durchgängiger YKK-Reißverschluss mit Kinnschutz und doppellagigem Windstopper innen, Innen-, Brust- und Seitentaschen, Oberstoff: Wassersäule: 10.000 mmH2O / Atmungsaktivität: 2.000 g/qm/24h, Regular Fit, OEKO-TEX STANDARD 100®, abnehmbare Kapuze optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 3,
    imgSrc: "/images/products/workweare_007.jpg",
    imgHoverSrc: "/images/products/workweare_008.jpg",
    title: "7163 // MOTION TEX LIGHT",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "On Sale"],
    width: 800, // Ensure these are present and are numbers
    height: 600,
    brand: "Arbeitskleidung",
    subcategory: "Westen",
    description: {
      images: [
        "/images/description_product/westen/1.png",
        "/images/description_product/westen/2.png",
        "/images/description_product/westen/3.png",
        "/images/description_product/westen/4.png",
        "/images/description_product/westen/5.png",
        "/images/description_product/westen/6.png",
        "/images/description_product/westen/7.png",
        "/images/description_product/westen/8.png",
        "/images/description_product/westen/9.png",
        "/images/description_product/westen/10.png",
      ],
      product_details: [
        " Futter: Polyester: 100% ; Oberstoff: Polyester: 94%; Elasthan: 6%",
      ],
      further_information: [
        "Oberstoff: Polyester / Elasthan, grau / schwarz",
        "Futter: Polyester, schwarz",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Softshellweste, grau / schwarz (Farbcode: 1210), 310 g/qm, angenehmer Tragekomfort dank weichem Softshell-Material mit wasser- und windabweisenden Eigenschaften, verlängertem Rücken und Stretchanteil, Kordelzug im Saum für optimalen Sitz, sportliches Design, Reflexelemente für bessere Sichtbarkeit, Stehkragen, durchgängiger YKK-Reißverschluss mit Kinnschutz und doppellagigem Windstopper innen, Innen-, Brust- und Seitentaschen, Oberstoff: Wassersäule: 10.000 mmH2O / Atmungsaktivität: 2.000 g/qm/24h, Regular Fit, OEKO-TEX STANDARD 100®, abnehmbare Kapuze optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 4,
    imgSrc: "/images/products/workweare_8.jpg",
    imgHoverSrc: "/images/products/workweare_9.jpg",
    title: "7163 // MOTION TEX LIGHT",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Westen",
    description: {
      images: [
        "/images/description_product/westen/1.png",
        "/images/description_product/westen/2.png",
        "/images/description_product/westen/3.png",
        "/images/description_product/westen/4.png",
        "/images/description_product/westen/5.png",
        "/images/description_product/westen/6.png",
        "/images/description_product/westen/7.png",
        "/images/description_product/westen/8.png",
        "/images/description_product/westen/9.png",
        "/images/description_product/westen/10.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Oberstoff: Polyester: 94%; Elasthan: 6%",
      ],
      further_information: [
        "Oberstoff: Polyester / Elasthan, weiß / grau",
        "Futter: Polyester, schwarz",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Softshellweste, weiß / grau (Farbcode: 1112), 310 g/qm, angenehmer Tragekomfort dank weichem Softshell-Material mit wasser- und windabweisenden Eigenschaften, verlängertem Rücken und Stretchanteil, Kordelzug im Saum für optimalen Sitz, sportliches Design, Reflexelemente für bessere Sichtbarkeit, Stehkragen, durchgängiger YKK-Reißverschluss mit Kinnschutz und doppellagigem Windstopper innen, Innen-, Brust- und Seitentaschen, Oberstoff: Wassersäule: 10.000 mmH2O / Atmungsaktivität: 2.000 g/qm/24h, Regular Fit, abnehmbare Kapuze optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 5,
    imgSrc: "/images/products/workwear9.jpg",
    imgHoverSrc: "/images/products/workwear10.jpg",
    title: "7164 // MOTION TEX LIGHT",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals"],
    brand: "Arbeitskleidung",
    subcategory: "Westen",
    description: {
      images: [
        "/images/description_product/westen/1.png",
        "/images/description_product/westen/2.png",
        "/images/description_product/westen/3.png",
        "/images/description_product/westen/4.png",
        "/images/description_product/westen/5.png",
        "/images/description_product/westen/6.png",
        "/images/description_product/westen/7.png",
        "/images/description_product/westen/8.png",
        "/images/description_product/westen/9.png",
        "/images/description_product/westen/10.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 94%; Elasthan: 6%; Futter: Polyester: 100%",
      ],
      further_information: [
        "Oberstoff: Polyester / Elasthan, grün / schwarz",
        "Futter: Polyester, schwarz",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Softshellweste, grün / schwarz (Farbcode: 3010), 310 g/qm, angenehmer Tragekomfort dank weichem Softshell-Material mit wasser- und windabweisenden Eigenschaften, verlängertem Rücken und Stretchanteil, Kordelzug im Saum für optimalen Sitz, sportliches Design, Reflexelemente für bessere Sichtbarkeit, Stehkragen, durchgängiger YKK-Reißverschluss mit Kinnschutz und doppellagigem Windstopper innen, Innen-, Brust- und Seitentaschen, Oberstoff: Wassersäule: 10.000 mmH2O / Atmungsaktivität: 2.000 g/qm/24h, Regular Fit, OEKO-TEX STANDARD 100®, abnehmbare Kapuze optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 6,
    imgSrc: "/images/products/workwear11.jpg",
    imgHoverSrc: "/images/products/workwear12.jpg",
    title: "7166 // MOTION TEX LIGHT",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals"],
    brand: "Arbeitskleidung",
    subcategory: "Westen",
    description: {
      images: [
        "/images/description_product/westen/1.png",
        "/images/description_product/westen/2.png",
        "/images/description_product/westen/3.png",
        "/images/description_product/westen/4.png",
        "/images/description_product/westen/5.png",
        "/images/description_product/westen/6.png",
        "/images/description_product/westen/7.png",
        "/images/description_product/westen/8.png",
        "/images/description_product/westen/9.png",
        "/images/description_product/westen/10.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Oberstoff: Polyester: 94%; Elasthan: 6%",
      ],
      further_information: [
        "Oberstoff: Polyester / Elasthan, königsblau / schwarz",
        "Futter: Polyester, schwarz",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Softshellweste, königsblau / schwarz (Farbcode: 2010), 310 g/qm, angenehmer Tragekomfort dank weichem Softshell-Material mit wasser- und windabweisenden Eigenschaften, verlängertem Rücken und Stretchanteil, Kordelzug im Saum für optimalen Sitz, sportliches Design, Reflexelemente für bessere Sichtbarkeit, Stehkragen, durchgängiger YKK-Reißverschluss mit Kinnschutz und doppellagigem Windstopper innen, Innen-, Brust- und Seitentaschen, Oberstoff: Wassersäule: 10.000 mmH2O / Atmungsaktivität: 2.000 g/qm/24h, Regular Fit, OEKO-TEX STANDARD 100®, abnehmbare Kapuze optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 7,
    imgSrc: "/images/products/workwear13.jpg",
    imgHoverSrc: "/images/products/workwear14.jpg",
    title: "7167 // MOTION TEX LIGHT",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Westen",
    description: {
      images: [
        "/images/description_product/westen/1.png",
        "/images/description_product/westen/2.png",
        "/images/description_product/westen/3.png",
        "/images/description_product/westen/4.png",
        "/images/description_product/westen/5.png",
        "/images/description_product/westen/6.png",
        "/images/description_product/westen/7.png",
        "/images/description_product/westen/8.png",
        "/images/description_product/westen/9.png",
        "/images/description_product/westen/10.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 94%; Elasthan: 6%; Futter: Polyester: 100%",
      ],
      further_information: [
        "Oberstoff: Polyester / Elasthan, braun / schwarz",
        "Futter: Polyester, schwarz",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Softshellweste, braun / schwarz (Farbcode: 5010), 310 g/qm, angenehmer Tragekomfort dank weichem Softshell-Material mit wasser- und windabweisenden Eigenschaften, verlängertem Rücken und Stretchanteil, Kordelzug im Saum für optimalen Sitz, sportliches Design, Reflexelemente für bessere Sichtbarkeit, Stehkragen, durchgängiger YKK-Reißverschluss mit Kinnschutz und doppellagigem Windstopper innen, Innen-, Brust- und Seitentaschen, Oberstoff: Wassersäule: 10.000 mmH2O / Atmungsaktivität: 2.000 g/qm/24h, Regular Fit, OEKO-TEX STANDARD 100®, abnehmbare Kapuze optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 8,
    imgSrc: "/images/products/workwear15.jpg",
    imgHoverSrc: "/images/products/workwear16.jpg",
    title: "7613 // MOTION TEX PLUS",
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, weiß / grau",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, lange Arbeitshose, weiß / grau (Farbcode: 1112), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund mit erhöhter Rückenpartie, Verstärkung an rückseitiger Gürtelschlaufe und Verstärkungen aus Canvas an Gesäß-, Knie-, Beintaschen und Beinabschlüssen, robuste Dreifachnähte, verdeckter Knopf zum Produktschutz (z. B. Kratzer), Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 9,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],
    imgSrc: "/images/products/workwear17.jpg",
    imgHoverSrc: "/images/products/workwear18.jpg",
    title: "7614 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, grün / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, lange Arbeitshose, grün / schwarz (Farbcode: 3010), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund mit erhöhter Rückenpartie, Verstärkung an rückseitiger Gürtelschlaufe und Verstärkungen aus Canvas an Gesäß-, Knie-, Beintaschen und Beinabschlüssen, robuste Dreifachnähte, verdeckter Knopf zum Produktschutz (z. B. Kratzer), Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 10,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],
    imgSrc: "/images/products/workwear19.jpg",
    imgHoverSrc: "/images/products/workwear20.jpg",
    title: "7616 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, lange Arbeitshose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund mit erhöhter Rückenpartie, Verstärkung an rückseitiger Gürtelschlaufe und Verstärkungen aus Canvas an Gesäß-, Knie-, Beintaschen und Beinabschlüssen, robuste Dreifachnähte, verdeckter Knopf zum Produktschutz (z. B. Kratzer), Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 11,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],
    imgSrc: "/images/products/workwear21.jpg",
    imgHoverSrc: "/images/products/workwear22.jpg",
    title: "7617 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "New arrivals"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, braun / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, lange Arbeitshose, braun / schwarz (Farbcode: 5010), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund mit erhöhter Rückenpartie, Verstärkung an rückseitiger Gürtelschlaufe und Verstärkungen aus Canvas an Gesäß-, Knie-, Beintaschen und Beinabschlüssen, robuste Dreifachnähte, verdeckter Knopf zum Produktschutz (z. B. Kratzer), Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 12,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear23.jpg",
    imgHoverSrc: "/images/products/workwear24.jpg",

    title: "7620 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, königsblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, königsblau / schwarz (Farbcode: 2010), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 13,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear25.jpg",
    imgHoverSrc: "/images/products/workwear26.jpg",

    title: "7621 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    subcategory: "Trousers",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, königsblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, königsblau / schwarz (Farbcode: 2010), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 14,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear27.jpg",
    imgHoverSrc: "/images/products/workwear28.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Shirts/1.png",
        "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%; EN 13758-2; UFP 40+"],
      further_information: [
        "Oberstoff: Baumwolle, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, braun (Farbcode: 5000), 160 g/qm, angenehmer Tragekomfort dank weichem Material, feiner Single-Jersey, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, besonders weich und glatt, leicht abreißbare Pflegeetiketten, UV-Schutz UPF 40+, OEKO-TEX STANDARD 100®, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll, PSA-Risikokategorie I",
      ],
    },
    isAvailable: true,
  },
  {
    id: 15,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear29.jpg",
    imgHoverSrc: "/images/products/workwear30.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Shirts/1.png",
        "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%; EN 13758-2; UFP 40+"],
      further_information: [
        "Oberstoff: Baumwolle, grau",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, grau (Farbcode: 1200), 160 g/qm, angenehmer Tragekomfort dank weichem Material, feiner Single-Jersey, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, besonders weich und glatt, leicht abreißbare Pflegeetiketten, UV-Schutz UPF 40+, OEKO-TEX STANDARD 100®, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll, PSA-Risikokategorie I",
      ],
    },
    isAvailable: true,
  },
  {
    id: 16,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear31.jpg",
    imgHoverSrc: "/images/products/workwear32.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Shirts/1.png",
        "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%; EN 13758-2; UFP 40+"],
      further_information: [
        "Oberstoff: Baumwolle, grün",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, grün (Farbcode: 3000), 160 g/qm, angenehmer Tragekomfort dank weichem Material, feiner Single-Jersey, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, besonders weich und glatt, leicht abreißbare Pflegeetiketten, UV-Schutz UPF 40+, OEKO-TEX STANDARD 100®, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll, PSA-Risikokategorie I",
      ],
    },
    isAvailable: true,
  },
  {
    id: 17,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear33.jpg",
    imgHoverSrc: "/images/products/workwear34.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Shirts/1.png",
        "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 98%; Viskose: 2%"],
      further_information: [
        "Oberstoff: Baumwolle / Viskose, hellgrau",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, hellgrau (Farbcode: 1300), 160 g/qm, angenehmer Tragekomfort dank weichem Material, feiner Single-Jersey, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, besonders weich und glatt, leicht abreißbare Pflegeetiketten, OEKO-TEX STANDARD 100®, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll",
      ],
    },
    isAvailable: true,
  },
  {
    id: 18,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear35.jpg",
    imgHoverSrc: "/images/products/workwear36.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Shirts/1.png",
        "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%; EN 13758-2; UFP 40+"],
      further_information: [
        "Oberstoff: Baumwolle, königsblau",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, königsblau (Farbcode: 2000), 160 g/qm, angenehmer Tragekomfort dank weichem Material, feiner Single-Jersey, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, besonders weich und glatt, leicht abreißbare Pflegeetiketten, UV-Schutz UPF 40+, OEKO-TEX STANDARD 100®, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll, PSA-Risikokategorie I",
      ],
    },
    isAvailable: true,
  },
  {
    id: 19,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear37.jpg",
    imgHoverSrc: "/images/products/workwear38.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Shirts/1.png",
        "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%; EN 13758-2; UFP 40+"],
      further_information: [
        "Oberstoff: Baumwolle, königsblau",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, königsblau (Farbcode: 2000), 160 g/qm, angenehmer Tragekomfort dank weichem Material, feiner Single-Jersey, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, besonders weich und glatt, leicht abreißbare Pflegeetiketten, UV-Schutz UPF 40+, OEKO-TEX STANDARD 100®, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll, PSA-Risikokategorie I",
      ],
    },
    isAvailable: true,
  },
  {
    id: 20,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear39.jpg",
    imgHoverSrc: "/images/products/workwear40.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Shirts/1.png",
        "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%; EN 13758-2; UFP 40+"],
      further_information: [
        "Oberstoff: Baumwolle, marineblau",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, marineblau (Farbcode: 2100), 160 g/qm, angenehmer Tragekomfort dank weichem Material, feiner Single-Jersey, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, besonders weich und glatt, leicht abreißbare Pflegeetiketten, UV-Schutz UPF 40+, OEKO-TEX STANDARD 100®, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll, PSA-Risikokategorie I",
      ],
    },
    isAvailable: true,
  },
  {
    id: 21,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear41.jpg",
    imgHoverSrc: "/images/products/workwear42.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Shirts/1.png",
        "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%; EN 13758-2; UFP 40+  "],
      further_information: [
        "Oberstoff: Baumwolle, rot",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, rot (Farbcode: 6000), 160 g/qm, angenehmer Tragekomfort dank weichem Material, feiner Single-Jersey, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, besonders weich und glatt, leicht abreißbare Pflegeetiketten, UV-Schutz UPF 40+, OEKO-TEX STANDARD 100®, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll, PSA-Risikokategorie I",
      ],
    },
    isAvailable: true,
  },
  {
    id: 22,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear44.jpg",
    imgHoverSrc: "/images/products/workwear43.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Shirts/1.png",
        "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%; EN 13758-2; UFP 40+"],
      further_information: [
        "Oberstoff: Baumwolle, schwarz",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, schwarz (Farbcode: 1000), 160 g/qm, angenehmer Tragekomfort dank weichem Material, feiner Single-Jersey, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, besonders weich und glatt, leicht abreißbare Pflegeetiketten, UV-Schutz UPF 40+, OEKO-TEX STANDARD 100®, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll, PSA-Risikokategorie I",
      ],
    },
    isAvailable: true,
  },
  {
    id: 23,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear45.jpg",
    imgHoverSrc: "/images/products/workwear46.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        // "/images/description_product/Shirts/1.png",
        "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%"],
      further_information: [
        "Oberstoff: Baumwolle, weiß",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, weiß (Farbcode: 1100), 160 g/qm, angenehmer Tragekomfort dank weichem Material, feiner Single-Jersey, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, besonders weich und glatt, leicht abreißbare Pflegeetiketten, OEKO-TEX STANDARD 100®, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll",
      ],
    },
    isAvailable: true,
  },
  {
    id: 24,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear47.jpg",
    imgHoverSrc: "/images/products/workwear48.jpg",

    title: "7622 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, grau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, grau / schwarz (Farbcode: 1210), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 25,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear49.jpg",
    imgHoverSrc: "/images/products/workwear50.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Shirts/1.png",
        "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%; EN 13758-2; 40+"],
      further_information: [
        "Oberstoff: Baumwolle, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Poloshirt, Kurzarm, khaki (Farbcode: 5100), 200 g/qm, angenehmer Tragekomfort dank weichem Material, feinmaschiges Piqué, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, verlängerter Rücken für mehr Komfort, gerippter Kragen, gerippte Ärmelbündchen, Knopfleiste mit drei gleichfarbigen Knöpfen, robuste Doppelnähte am Saum, doppelt geriegelte Seitenschlitze, besonders weich und leicht, atmungsaktiv, leicht abreißbare Pflegeetiketten, UV-Schutz UPF 40+, OEKO-TEX STANDARD 100®, Regular Fit, PSA-Risikokategorie I",
      ],
    },
    isAvailable: true,
  },
  {
    id: 26,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear51.jpg",
    imgHoverSrc: "/images/products/workwear52.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Shirts/1.png",
        "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%; EN 13758-2; 40+"],
      further_information: [
        "Oberstoff: Baumwolle, grau",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Poloshirt, Kurzarm, grau (Farbcode: 1200), 200 g/qm, angenehmer Tragekomfort dank weichem Material, feinmaschiges Piqué, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, verlängerter Rücken für mehr Komfort, gerippter Kragen, gerippte Ärmelbündchen, Knopfleiste mit drei gleichfarbigen Knöpfen, robuste Doppelnähte am Saum, doppelt geriegelte Seitenschlitze, besonders weich und leicht, atmungsaktiv, leicht abreißbare Pflegeetiketten, UV-Schutz UPF 40+, OEKO-TEX STANDARD 100®, Regular Fit, PSA-Risikokategorie I",
      ],
    },
    isAvailable: true,
  },
  {
    id: 27,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear54.jpg",
    imgHoverSrc: "/images/products/workwear53.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Shirts/1.png",
        "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%; EN 13758-2; 40+"],
      further_information: [
        "Oberstoff: Baumwolle, grün",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Poloshirt, Kurzarm, grün (Farbcode: 3000), 200 g/qm, angenehmer Tragekomfort dank weichem Material, feinmaschiges Piqué, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, verlängerter Rücken für mehr Komfort, gerippter Kragen, gerippte Ärmelbündchen, Knopfleiste mit drei gleichfarbigen Knöpfen, robuste Doppelnähte am Saum, doppelt geriegelte Seitenschlitze, besonders weich und leicht, atmungsaktiv, leicht abreißbare Pflegeetiketten, UV-Schutz UPF 40+, OEKO-TEX STANDARD 100®, Regular Fit, PSA-Risikokategorie I",
      ],
    },
    isAvailable: true,
  },
  {
    id: 28,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear55.jpg",
    imgHoverSrc: "/images/products/workwear56.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        // "/images/description_product/Shirts/1.png",
        // "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Shell fabric: Polyester: 65%; Cotton: 35%"],
      further_information: [
        "Oberstoff: Baumwolle, weiß",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Poloshirt, Kurzarm, weiß (Farbcode: 1100), 200 g/qm, angenehmer Tragekomfort dank weichem Material, feinmaschiges Piqué, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, verlängerter Rücken für mehr Komfort, gerippter Kragen, gerippte Ärmelbündchen, Knopfleiste mit drei gleichfarbigen Knöpfen, robuste Doppelnähte am Saum, doppelt geriegelte Seitenschlitze, besonders weich und leicht, atmungsaktiv, leicht abreißbare Pflegeetiketten, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 29,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear57.jpg",
    imgHoverSrc: "/images/products/workwear58.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Shirts/1.png",
        "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%; EN 13758-2; 40+"],
      further_information: [
        "Oberstoff: Baumwolle, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Poloshirt, Kurzarm, khaki (Farbcode: 5100), 200 g/qm, angenehmer Tragekomfort dank weichem Material, feinmaschiges Piqué, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, verlängerter Rücken für mehr Komfort, gerippter Kragen, gerippte Ärmelbündchen, Knopfleiste mit drei gleichfarbigen Knöpfen, robuste Doppelnähte am Saum, doppelt geriegelte Seitenschlitze, besonders weich und leicht, atmungsaktiv, leicht abreißbare Pflegeetiketten, UV-Schutz UPF 40+, OEKO-TEX STANDARD 100®, Regular Fit, PSA-Risikokategorie I",
      ],
    },
    isAvailable: true,
  },
  {
    id: 30,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear59.jpg",
    imgHoverSrc: "/images/products/workwear60.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Shirts/1.png",
        "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%; EN 13758-2; 40+"],
      further_information: [
        "Oberstoff: Baumwolle, königsblau",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Poloshirt, Kurzarm, königsblau (Farbcode: 2000), 200 g/qm, angenehmer Tragekomfort dank weichem Material, feinmaschiges Piqué, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, verlängerter Rücken für mehr Komfort, gerippter Kragen, gerippte Ärmelbündchen, Knopfleiste mit drei gleichfarbigen Knöpfen, robuste Doppelnähte am Saum, doppelt geriegelte Seitenschlitze, besonders weich und leicht, atmungsaktiv, leicht abreißbare Pflegeetiketten, UV-Schutz UPF 40+, OEKO-TEX STANDARD 100®, Regular Fit, PSA-Risikokategorie I",
      ],
    },
    isAvailable: true,
  },
  {
    id: 31,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear61.jpg",
    imgHoverSrc: "/images/products/workwear62.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Shirts/1.png",
        "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%; EN 13758-2; 40+"],
      further_information: [
        "Oberstoff: Baumwolle, marineblau",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Poloshirt, Kurzarm, marineblau (Farbcode: 2100), 200 g/qm, angenehmer Tragekomfort dank weichem Material, feinmaschiges Piqué, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, verlängerter Rücken für mehr Komfort, gerippter Kragen, gerippte Ärmelbündchen, Knopfleiste mit drei gleichfarbigen Knöpfen, robuste Doppelnähte am Saum, doppelt geriegelte Seitenschlitze, besonders weich und leicht, atmungsaktiv, leicht abreißbare Pflegeetiketten, UV-Schutz UPF 40+, OEKO-TEX STANDARD 100®, Regular Fit, PSA-Risikokategorie I",
      ],
    },
    isAvailable: true,
  },

  {
    id: 32,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear63.jpg",
    imgHoverSrc: "/images/products/workwear64.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Shirts/1.png",
        "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%; EN 13758-2; 40+"],
      further_information: [
        "Oberstoff: Baumwolle, rot",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Poloshirt, Kurzarm, rot (Farbcode: 6000), 200 g/qm, angenehmer Tragekomfort dank weichem Material, feinmaschiges Piqué, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, verlängerter Rücken für mehr Komfort, gerippter Kragen, gerippte Ärmelbündchen, Knopfleiste mit drei gleichfarbigen Knöpfen, robuste Doppelnähte am Saum, doppelt geriegelte Seitenschlitze, besonders weich und leicht, atmungsaktiv, leicht abreißbare Pflegeetiketten, UV-Schutz UPF 40+, OEKO-TEX STANDARD 100®, Regular Fit, PSA-Risikokategorie I",
      ],
    },
    isAvailable: true,
  },

  {
    id: 33,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear65.jpg",
    imgHoverSrc: "/images/products/workwear66.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Shirts/1.png",
        "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%; EN 13758-2; 40+"],
      further_information: [
        "Oberstoff: Baumwolle, schwarz",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Poloshirt, Kurzarm, schwarz (Farbcode: 1000), 200 g/qm, angenehmer Tragekomfort dank weichem Material, feinmaschiges Piqué, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, verlängerter Rücken für mehr Komfort, gerippter Kragen, gerippte Ärmelbündchen, Knopfleiste mit drei gleichfarbigen Knöpfen, robuste Doppelnähte am Saum, doppelt geriegelte Seitenschlitze, besonders weich und leicht, atmungsaktiv, leicht abreißbare Pflegeetiketten, UV-Schutz UPF 40+, OEKO-TEX STANDARD 100®, Regular Fit, PSA-Risikokategorie I",
      ],
    },
    isAvailable: true,
  },
  {
    id: 34,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear67.jpg",
    imgHoverSrc: "/images/products/workwear68.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        // "/images/description_product/Shirts/1.png",
        // "/images/description_product/Shirts/2.png",
        "/images/description_product/Shirts/3.png",
        "/images/description_product/Shirts/4.png",
        "/images/description_product/Shirts/5.png",
        "/images/description_product/Shirts/6.png",
        "/images/description_product/Shirts/7.png",
        "/images/description_product/Shirts/8.png",
        "/images/description_product/Shirts/9.png",
        "/images/description_product/Shirts/10.png",
      ],
      product_details: ["Shell fabric: Polyester: 65%; Cotton: 35%"],
      further_information: [
        "Oberstoff: Cotton / Viskose, light grey",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Poloshirt, Kurzarm, hellgrau (Farbcode: 1300), 200 g/qm, angenehmer Tragekomfort dank weichem Material, feinmaschiges Piqué, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, verlängerter Rücken für mehr Komfort, gerippter Kragen, gerippte Ärmelbündchen, Knopfleiste mit drei gleichfarbigen Knöpfen, robuste Doppelnähte am Saum, doppelt geriegelte Seitenschlitze, besonders weich und leicht, atmungsaktiv, leicht abreißbare Pflegeetiketten, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 35,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],
    imgSrc: "/images/products/workwear69.jpg",
    imgHoverSrc: "/images/products/workwear70.jpg",

    title: "7623 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, weiß / grau",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, weiß / grau (Farbcode: 1112), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 36,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear71.jpg",
    imgHoverSrc: "/images/products/workwear72.jpg",

    title: "7015 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Pullover, braun (Farbcode: 5000), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gekämmte Baumwolle / Polyester, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Rundhalsausschnitt, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, langlebige Doppelnähte an Bund, Ärmeln und Schulter, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 37,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear73.jpg",
    imgHoverSrc: "/images/products/workwear74.jpg",

    title: "7015 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, grau",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Pullover, grau (Farbcode: 1200), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gekämmte Baumwolle / Polyester, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Rundhalsausschnitt, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, langlebige Doppelnähte an Bund, Ärmeln und Schulter, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 38,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear75.jpg",
    imgHoverSrc: "/images/products/workwear76.jpg",

    title: "7015 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, grün",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Pullover, grün (Farbcode: 3000), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Rundhalsausschnitt, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, langlebige Doppelnähte an Bund, Ärmeln und Schulter, STANDARD 100 by OEKO-TEX®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 39,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear77.jpg",
    imgHoverSrc: "/images/products/workwear78.jpg",

    title: "7015 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 70%; Polyester: 29%; Viskose: 1%",
      ],
      further_information: [
        "Oberstoff: Baumwolle / Polyester / Viskose, hellgrau",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Pullover, hellgrau (Farbcode 1300), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gekämmte Baumwolle / Polyester, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Rundhalsausschnitt, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, langlebige Doppelnähte an Bund, Ärmeln und Schulter, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 40,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear79.jpg",
    imgHoverSrc: "/images/products/workwear80.jpg",

    title: "7015 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Pullover, khaki (Farbcode: 5100), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gekämmte Baumwolle / Polyester, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Rundhalsausschnitt, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, langlebige Doppelnähte an Bund, Ärmeln und Schulter, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 41,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear81.jpg",
    imgHoverSrc: "/images/products/workwear82.jpg",

    title: "7015 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, königsblau",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Pullover, königsblau (Farbcode: 2000), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gekämmte Baumwolle / Polyester, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Rundhalsausschnitt, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, langlebige Doppelnähte an Bund, Ärmeln und Schulter, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 42,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear85.jpg",
    imgHoverSrc: "/images/products/workwear86.jpg",

    title: "7015 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, rot",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Pullover, rot (Farbcode: 6000), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gekämmte Baumwolle / Polyester, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Rundhalsausschnitt, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, langlebige Doppelnähte an Bund, Ärmeln und Schulter, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 43,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear87.jpg",
    imgHoverSrc: "/images/products/workwear88.jpg",

    title: "7015 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, schwarz",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Pullover, schwarz (Farbcode: 1000), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gekämmte Baumwolle / Polyester, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Rundhalsausschnitt, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, langlebige Doppelnähte an Bund, Ärmeln und Schulter, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 44,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear89.jpg",
    imgHoverSrc: "/images/products/workwear90.jpg",

    title: "7015 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, weiß",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Pullover, weiß (Farbcode: 1100), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gekämmte Baumwolle / Polyester, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Rundhalsausschnitt, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, langlebige Doppelnähte an Bund, Ärmeln und Schulter, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 45,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],
    imgSrc: "/images/products/workwear91.jpg",
    imgHoverSrc: "/images/products/workwear92.jpg",

    title: "7624 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, grün / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, grün / schwarz (Farbcode: 3010), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },

  {
    id: 46,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
    imgSrc: "/images/products/workwear93.jpg",
    imgHoverSrc: "/images/products/workwear94.jpg",

    title: "7020 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, braun (Farbcode: 5000), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 47,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear97.jpg",
    imgHoverSrc: "/images/products/workwear98.jpg",

    title: "7020 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, grün",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, grün (Farbcode: 3000), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 48,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear99.jpg",
    imgHoverSrc: "/images/products/workwear100.jpg",

    title: "7020 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 70%; Polyester: 29%; Viskose: 1%",
      ],
      further_information: [
        "Oberstoff: Baumwolle / Polyester / Viskose, hellgrau",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, hellgrau (Farbcode: 1300), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 49,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear101.jpg",
    imgHoverSrc: "/images/products/workwear102.jpg",

    title: "7020 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 50,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear103.jpg",
    imgHoverSrc: "/images/products/workwear104.jpg",

    title: "7020 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 51,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear105.jpg",
    imgHoverSrc: "/images/products/workwear106.jpg",

    title: "7020 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 52,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear107.jpg",
    imgHoverSrc: "/images/products/workwear108.jpg",

    title: "7020 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 53,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear109.jpg",
    imgHoverSrc: "/images/products/workwear110.jpg",

    title: "7020 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 54,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear111.jpg",
    imgHoverSrc: "/images/products/workwear112.jpg",

    title: "7020 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 55,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear113.jpg",
    imgHoverSrc: "/images/products/workwear114.jpg",

    title: "7626 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },

  {
    id: 56,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear115.jpg",
    imgHoverSrc: "/images/products/workwear116.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Kapuzenpullover, braun (Farbcode: 5000), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Kapuze mit farblich abgestimmtem Kordelzug, langlebige Doppelnähte an Bund, Ärmeln, Kapuze und aufgesetzter Kängurutasche, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 57,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear118.jpg",
    imgHoverSrc: "/images/products/workwear117.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Kapuzenpullover, braun (Farbcode: 5000), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Kapuze mit farblich abgestimmtem Kordelzug, langlebige Doppelnähte an Bund, Ärmeln, Kapuze und aufgesetzter Kängurutasche, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 58,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear119.jpg",
    imgHoverSrc: "/images/products/workwear120.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Kapuzenpullover, braun (Farbcode: 5000), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Kapuze mit farblich abgestimmtem Kordelzug, langlebige Doppelnähte an Bund, Ärmeln, Kapuze und aufgesetzter Kängurutasche, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 59,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear121.jpg",
    imgHoverSrc: "/images/products/workwear122.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Kapuzenpullover, braun (Farbcode: 5000), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Kapuze mit farblich abgestimmtem Kordelzug, langlebige Doppelnähte an Bund, Ärmeln, Kapuze und aufgesetzter Kängurutasche, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 60,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear124.jpg",
    imgHoverSrc: "/images/products/workwear123.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Kapuzenpullover, braun (Farbcode: 5000), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Kapuze mit farblich abgestimmtem Kordelzug, langlebige Doppelnähte an Bund, Ärmeln, Kapuze und aufgesetzter Kängurutasche, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 61,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear125.jpg",
    imgHoverSrc: "/images/products/workwear126.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Kapuzenpullover, braun (Farbcode: 5000), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Kapuze mit farblich abgestimmtem Kordelzug, langlebige Doppelnähte an Bund, Ärmeln, Kapuze und aufgesetzter Kängurutasche, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 62,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear127.jpg",
    imgHoverSrc: "/images/products/workwear128.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Kapuzenpullover, braun (Farbcode: 5000), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Kapuze mit farblich abgestimmtem Kordelzug, langlebige Doppelnähte an Bund, Ärmeln, Kapuze und aufgesetzter Kängurutasche, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 63,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear129.jpg",
    imgHoverSrc: "/images/products/workwear130.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Kapuzenpullover, braun (Farbcode: 5000), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Kapuze mit farblich abgestimmtem Kordelzug, langlebige Doppelnähte an Bund, Ärmeln, Kapuze und aufgesetzter Kängurutasche, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 64,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear131.jpg",
    imgHoverSrc: "/images/products/workwear132.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Kapuzenpullover, braun (Farbcode: 5000), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Kapuze mit farblich abgestimmtem Kordelzug, langlebige Doppelnähte an Bund, Ärmeln, Kapuze und aufgesetzter Kängurutasche, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 65,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear133.jpg",
    imgHoverSrc: "/images/products/workwear134.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Kapuzenpullover, braun (Farbcode: 5000), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Kapuze mit farblich abgestimmtem Kordelzug, langlebige Doppelnähte an Bund, Ärmeln, Kapuze und aufgesetzter Kängurutasche, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 66,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear135.jpg",
    imgHoverSrc: "/images/products/workwear136.jpg",

    title: "7627 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },

  {
    id: 67,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear137.jpg",
    imgHoverSrc: "/images/products/workwear138.jpg",

    title: "7030 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 68,
    sizes: [
      "42",
      "44",
      "46",
      "48",
      "50",
      "50",
      "52",
      "52",
      "54",
      "56",
      "56",
      "58",
      "60",
      "60",
      "62",
      "64",
      "66",
      "68",
    ],

    imgSrc: "/images/products/workwear139.jpg",
    imgHoverSrc: "/images/products/workwear140.jpg",

    title: "7650 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 69,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear141.jpg",
    imgHoverSrc: "/images/products/workwear142.jpg",

    title: "7031 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 70,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear143.jpg",
    imgHoverSrc: "/images/products/workwear144.jpg",

    title: "7032 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 71,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear145.jpg",
    imgHoverSrc: "/images/products/workwear146.jpg",

    title: "7034 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 72,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear149.jpg",
    imgHoverSrc: "/images/products/workwear150.jpg",

    title: "7180 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 73,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear151.jpg",
    imgHoverSrc: "/images/products/workwear152.jpg",

    title: "7180W // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 74,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear153.jpg",
    imgHoverSrc: "/images/products/workwear154.jpg",

    title: "7181 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 75,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear155.jpg",
    imgHoverSrc: "/images/products/workwear156.jpg",

    title: "7181W // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 76,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear157.jpg",
    imgHoverSrc: "/images/products/workwear158.jpg",

    title: "7182 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 77,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear159.jpg",
    imgHoverSrc: "/images/products/workwear160.jpg",

    title: "7182W // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 78,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear161.jpg",
    imgHoverSrc: "/images/products/workwear162.jpg",

    title: "7190 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 79,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear163.jpg",
    imgHoverSrc: "/images/products/workwear164.jpg",

    title: "7036 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Kapuzenpullover, braun (Farbcode: 5000), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Kapuze mit farblich abgestimmtem Kordelzug, langlebige Doppelnähte an Bund, Ärmeln, Kapuze und aufgesetzter Kängurutasche, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 80,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear165.jpg",
    imgHoverSrc: "/images/products/workwear166.jpg",

    title: "7192 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 81,
    sizes: [
      "42",
      "44",
      "46",
      "48",
      "88",
      "50",
      "52",
      "54",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
      "89",
    ],

    imgSrc: "/images/products/workwear167.jpg",
    imgHoverSrc: "/images/products/workwear168.jpg",

    title: "7651 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 82,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear171.jpg",
    imgHoverSrc: "/images/products/workwear172.jpg",

    title: "7195 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Kapuzenpullover, braun (Farbcode: 5000), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Kapuze mit farblich abgestimmtem Kordelzug, langlebige Doppelnähte an Bund, Ärmeln, Kapuze und aufgesetzter Kängurutasche, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 83,
    sizes: [
      "42",
      "44",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
    ],

    imgSrc: "/images/products/workwear173.jpg",
    imgHoverSrc: "/images/products/workwear174.jpg",

    title: "7652 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],

    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: [
        "Futter (Kragen): Polyester: 100%; Oberstoff: Polyester: 65%; Baumwolle: 35%",
      ],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, grau / schwarz",
        "Futter: -",
        "Größe(n): 42-68",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Arbeitsjacke, grau / schwarz (Farbcode: 1210), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, verstellbare Ärmelabschlüsse mit Druckknöpfen, verlängerter Rücken, angenehm weicher Fleece-Stehkragen, Verstärkungen an Taschen und Ärmeln sowie Schultern und Verstärkungen aus Canvas an Ellbogenpartie und Rücken, robuste Dreifachnähte, durchgängiger YKK-Reißverschluss mit Kinnschutz und Windstopper außen, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Zwei Brusttaschen mit Patten und aufgesetzter Smartphone-Tasche, zwei Stifttaschen, Seitentaschen, Innentasche, OEKO-TEX STANDARD 100®, Comfort Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 84,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear175.jpg",
    imgHoverSrc: "/images/products/workwear176.jpg",

    title: "7038 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],

    brand: "Arbeitskleidung",
    subcategory: "Pullover",
    description: {
      images: [
        "/images/description_product/Pullover/1.png",
        "/images/description_product/Pullover/2.png",
        "/images/description_product/Pullover/3.png",
        "/images/description_product/Pullover/4.png",
        "/images/description_product/Pullover/5.png",
        "/images/description_product/Pullover/6.png",
        "/images/description_product/Pullover/7.png",
        "/images/description_product/Pullover/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Kapuzenpullover, braun (Farbcode: 5000), 300 g/qm, atmungsaktiv, angenehmer Tragekomfort dank aufgerauter und weicher Innenseite, gerippte Ärmelabschlüsse und gerippter Bund für optimalen Sitz, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerader Schnitt, sportliches Design, Kapuze mit farblich abgestimmtem Kordelzug, langlebige Doppelnähte an Bund, Ärmeln, Kapuze und aufgesetzter Kängurutasche, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 85,
    sizes: [
      "42",
      "44",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
    ],

    imgSrc: "/images/products/workwear177.jpg",
    imgHoverSrc: "/images/products/workwear178.jpg",

    title: "7653 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],

    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: [
        "Futter (Kragen): Polyester: 100%; Oberstoff: Polyester: 65%; Baumwolle: 35%",
      ],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, grau / schwarz",
        "Futter: -",
        "Größe(n): 42-68",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Arbeitsjacke, grau / schwarz (Farbcode: 1210), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, verstellbare Ärmelabschlüsse mit Druckknöpfen, verlängerter Rücken, angenehm weicher Fleece-Stehkragen, Verstärkungen an Taschen und Ärmeln sowie Schultern und Verstärkungen aus Canvas an Ellbogenpartie und Rücken, robuste Dreifachnähte, durchgängiger YKK-Reißverschluss mit Kinnschutz und Windstopper außen, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Zwei Brusttaschen mit Patten und aufgesetzter Smartphone-Tasche, zwei Stifttaschen, Seitentaschen, Innentasche, OEKO-TEX STANDARD 100®, Comfort Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 86,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear179.jpg",
    imgHoverSrc: "/images/products/workwear180.jpg",

    title: "7039 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],

    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 87,
    sizes: ["One Size"],

    imgSrc: "/images/products/workwear181.jpg",
    imgHoverSrc: "/images/products/workwear182.jpg",

    title: "731",
    filterCategories: ["Best seller", "On Sale"],

    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        "/images/description_product/Kopfbedeckung/1.png",
        "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
        "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Oberstoff: Polyacryl: 100%; Futter: Polyester: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },

  {
    id: 88,
    sizes: ["One Size"],

    imgSrc: "/images/products/workwear183.jpg",
    imgHoverSrc: "/images/products/workwear184.jpg",

    title: "731",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        "/images/description_product/Kopfbedeckung/1.png",
        "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
        "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Oberstoff: Polyacryl: 100%; Futter: Polyester: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },

  {
    id: 89,
    sizes: ["One Size"],

    imgSrc: "/images/products/workwear185.jpg",
    imgHoverSrc: "/images/products/workwear186.jpg",

    title: "731",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        "/images/description_product/Kopfbedeckung/1.png",
        "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
        "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Oberstoff: Polyacryl: 100%; Futter: Polyester: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },
  {
    id: 90,
    sizes: [
      "42",
      "44",
      "46",
      "46",
      "48",
      "50",
      "50",
      "52",
      "54",
      "56",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
    ],

    imgSrc: "/images/products/workwear187.jpg",
    imgHoverSrc: "/images/products/workwear188.jpg",

    title: "7654 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 91,
    sizes: ["One Size"],

    imgSrc: "/images/products/workwear192.jpg",
    imgHoverSrc: "/images/products/workwear192.jpg",

    title: "731P",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        "/images/description_product/Kopfbedeckung/1.png",
        "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
        "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Oberstoff: Polyacryl: 100%; Futter: Polyester: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },

  {
    id: 92,
    sizes: [
      "42",
      "44",
      "46",
      "48",
      "59",
      "50",
      "52",
      "52",
      "54",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "69",
      "66",
      "68",
    ],

    imgSrc: "/images/products/workwear193.jpg",
    imgHoverSrc: "/images/products/workwear194.jpg",

    title: "7656 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 93,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear195.jpg",
    imgHoverSrc: "/images/products/workwear196.jpg",

    title: "7041 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 94,
    sizes: ["One Size"],

    imgSrc: "/images/products/workwear197.jpg",
    imgHoverSrc: "/images/products/workwear198.jpg",

    title: "732",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        "/images/description_product/Kopfbedeckung/1.png",
        "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
        "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Oberstoff: Polyacryl: 100%; Futter: Polyester: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },
  {
    id: 95,
    sizes: ["One Size"],

    imgSrc: "/images/products/workwear199.jpg",
    imgHoverSrc: "/images/products/workwear200.jpg",

    title: "732",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        "/images/description_product/Kopfbedeckung/1.png",
        "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
        "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Oberstoff: Polyacryl: 100%; Futter: Polyester: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },
  {
    id: 96,
    sizes: ["One Size"],

    imgSrc: "/images/products/workwear201.jpg",
    imgHoverSrc: "/images/products/workwear202.jpg",

    title: "732",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        "/images/description_product/Kopfbedeckung/1.png",
        "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
        "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Oberstoff: Polyacryl: 100%; Futter: Polyester: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },
  {
    id: 97,
    sizes: [42, 44, 46, 48, 50, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear203.jpg",
    imgHoverSrc: "/images/products/workwear204.jpg",

    title: "7657 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 98,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear205.jpg",
    imgHoverSrc: "/images/products/workwear206.jpg",

    title: "7043 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 99,
    sizes: [42, 44, 46, 48, 50, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear207.jpg",
    imgHoverSrc: "/images/products/workwear208.jpg",

    title: "7500 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 100,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear209.jpg",
    imgHoverSrc: "/images/products/workwear210.jpg",

    title: "7044 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 101,
    sizes: [42, 44, 46, 48, 50, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear211.jpg",
    imgHoverSrc: "/images/products/workwear211.jpg",

    title: "7501 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 102,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear213.jpg",
    imgHoverSrc: "/images/products/workwear214.jpg",

    title: "7047 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 103,
    sizes: [42, 44, 46, 48, 50, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear215.jpg",
    imgHoverSrc: "/images/products/workwear216.jpg",

    title: "7502 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],

    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },

    isAvailable: true,
  },
  {
    id: 104,
    sizes: [42, 44, 46, 48, 50, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear217.jpg",
    imgHoverSrc: "/images/products/workwear218.jpg",

    title: "7503 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 105,
    sizes: [42, 44, 46, 48, 50, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear219.jpg",
    imgHoverSrc: "/images/products/workwear220.jpg",

    title: "7504 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 106,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear221.jpg",
    imgHoverSrc: "/images/products/workwear222.jpg",

    title: "7510 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 107,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear223.jpg",
    imgHoverSrc: "/images/products/workwear224.jpg",

    title: "7511 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 108,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear225.jpg",
    imgHoverSrc: "/images/products/workwear226.jpg",

    title: "7512 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 109,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear227.jpg",
    imgHoverSrc: "/images/products/workwear228.jpg",

    title: "7513 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 110,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear227.jpg",
    imgHoverSrc: "/images/products/workwear228.jpg",

    title: "7513 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 111,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear229.jpg",
    imgHoverSrc: "/images/products/workwear230.jpg",

    title: "7514 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 112,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear231.jpg",
    imgHoverSrc: "/images/products/workwear232.jpg",

    title: "7520 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 113,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear231.jpg",
    imgHoverSrc: "/images/products/workwear232.jpg",

    title: "7520 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 114,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear233.jpg",
    imgHoverSrc: "/images/products/workwear234.jpg",

    title: "7150 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 115,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear235.jpg",
    imgHoverSrc: "/images/products/workwear236.jpg",

    title: "7521 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 116,
    sizes: ["/"],

    imgSrc: "/images/products/workwear237.jpg",
    imgHoverSrc: "/images/products/workwear238.jpg",

    title: "7150K",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        // "/images/description_product/Kopfbedeckung/1.png",
        "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
        "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Oberstoff: Polyacryl: 100%; Futter: Polyester: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },
  {
    id: 117,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear241.jpg",
    imgHoverSrc: "/images/products/workwear242.jpg",

    title: "7151 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 118,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear243.jpg",
    imgHoverSrc: "/images/products/workwear244.jpg",

    title: "7523 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 119,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear245.jpg",
    imgHoverSrc: "/images/products/workwear246.jpg",

    title: "7151K",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        // "/images/description_product/Kopfbedeckung/1.png",
        "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
        "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Oberstoff: Polyacryl: 100%; Futter: Polyester: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },
  {
    id: 120,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear247.jpg",
    imgHoverSrc: "/images/products/workwear248.jpg",

    title: "7524 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 121,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear249.jpg",
    imgHoverSrc: "/images/products/workwear250.jpg",

    title: "7152 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 122,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear251.jpg",
    imgHoverSrc: "/images/products/workwear252.jpg",

    title: "7550 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 123,
    sizes: ["/"],

    imgSrc: "/images/products/workwear253.jpg",
    imgHoverSrc: "/images/products/workwear254.jpg",

    title: "7152K",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        // "/images/description_product/Kopfbedeckung/1.png",
        "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
        "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Oberstoff: Polyacryl: 100%; Futter: Polyester: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },
  {
    id: 124,
    sizes: ["/"],

    imgSrc: "/images/products/workwear255.jpg",
    imgHoverSrc: "/images/products/workwear256.jpg",

    title: "7551 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 125,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear257.jpg",
    imgHoverSrc: "/images/products/workwear258.jpg",

    title: "7153 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 126,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear259.jpg",
    imgHoverSrc: "/images/products/workwear260.jpg",

    title: "7552 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 127,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear263.jpg",
    imgHoverSrc: "/images/products/workwear264.jpg",

    title: "7553 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 128,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear265.jpg",
    imgHoverSrc: "/images/products/workwear266.jpg",

    title: "7154 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 129,
    sizes: ["/"],

    imgSrc: "/images/products/workwear269.jpg",
    imgHoverSrc: "/images/products/workwear270.jpg",

    title: "7154K",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        // "/images/description_product/Kopfbedeckung/1.png",
        "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
        "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Oberstoff: Polyacryl: 100%; Futter: Polyester: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },

  {
    id: 130,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear271.jpg",
    imgHoverSrc: "/images/products/workwear272.jpg",

    title: "7600 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 131,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear273.jpg",
    imgHoverSrc: "/images/products/workwear274.jpg",

    title: "7156 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 132,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear275.jpg",
    imgHoverSrc: "/images/products/workwear276.jpg",

    title: "7601 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 133,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear277.jpg",
    imgHoverSrc: "/images/products/workwear278.jpg",

    title: "7602 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 134,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear279.jpg",
    imgHoverSrc: "/images/products/workwear280.jpg",

    title: "7157 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 70%; Polyester: 30%"],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, khaki",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, khaki (Farbcode: 5100), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 135,
    sizes: ["/"],

    imgSrc: "/images/products/workwear283.jpg",
    imgHoverSrc: "/images/products/workwear284.jpg",

    title: "7157K",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        // "/images/description_product/Kopfbedeckung/1.png",
        "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
        "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Oberstoff: Polyacryl: 100%; Futter: Polyester: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },
  {
    id: 136,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear285.jpg",
    imgHoverSrc: "/images/products/workwear286.jpg",

    title: "7604 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },

  {
    id: 137,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear287.jpg",
    imgHoverSrc: "/images/products/workwear288.jpg",

    title: "7606 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 138,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear289.jpg",
    imgHoverSrc: "/images/products/workwear290.jpg",

    title: "7607 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 139,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear291.jpg",
    imgHoverSrc: "/images/products/workwear292.jpg",

    title: "7610 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 140,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear293.jpg",
    imgHoverSrc: "/images/products/workwear294.jpg",

    title: "7611 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, marineblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, Latzhose, marineblau / schwarz (Farbcode: 2110), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen aus Canvas an Bein-, Knie-, Gesäßtaschen sowie Latz-Außentasche und Beinabschlüssen, robuste Dreifachnähte, Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Latz-Innentasche mit YKK-Reißverschluss und aufgesetzten Außentaschen, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, stabile Clipverschlüsse, elastische Trägerabschlüsse, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 141,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear295.jpg",
    imgHoverSrc: "/images/products/workwear296.jpg",

    title: "7660 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Westen",
    description: {
      images: [
        // "/images/description_product/Westen/1.png",
        // "/images/description_product/Westen/2.png",
        "/images/description_product/Westen/3.png",
        "/images/description_product/Westen/4.png",
        "/images/description_product/Westen/5.png",
        "/images/description_product/Westen/6.png",
        "/images/description_product/Westen/7.png",
        "/images/description_product/Westen/8.png",
        "/images/description_product/Westen/9.png",
        "/images/description_product/Westen/10.png",
      ],
      product_details: [
        "Wattierung: Polyester: 100%; Futter: Polyester: 100%; Oberstoff 2: Polyester: 94%; Elasthan: 6%; Oberstoff: Polyester: 100%",
      ],
      further_information: [
        "Oberstoff: Polyester, schwarz | Polyester / Elasthan, schwarz",
        "Futter: Polyester, schwarz",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS MOTION TEX PLUS, gesteppte Softshellweste, schwarz (Farbcode: 1000), 340 g/qm, angenehmer Tragekomfort dank verlängertem Rücken, Kordelzug im Saum für optimalen Halt, sportliches Design, Steppungen an Vorder- und Rückseite, besonders wärmend, Reflexelemente für bessere Sichtbarkeit, Stehkragen, durchgängiger YKK-Reißverschluss mit doppellagigem Windstopper innen, Brusttasche, Seitentaschen, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 142,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear297.jpg",
    imgHoverSrc: "/images/products/workwear298.jpg",

    title: "7700 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Suchergebnis für 7700",
    subcategory: " MOTION TEX PRO FX",
    description: {
      images: [
        "/images/description_product/suchergebnisfur7700/1.png",
        "/images/description_product/suchergebnisfur7700/2.png",
        "/images/description_product/suchergebnisfur7700/3.png",
        "/images/description_product/suchergebnisfur7700/4.png",
        "/images/description_product/suchergebnisfur7700/5.png",
        "/images/description_product/suchergebnisfur7700/6.png",
        "/images/description_product/suchergebnisfur7700/7.png",
        "/images/description_product/suchergebnisfur7700/8.png",
        "/images/description_product/suchergebnisfur7700/9.png",
        "/images/description_product/suchergebnisfur7700/10.png",
        "/images/description_product/suchergebnisfur7700/11.png",
        "/images/description_product/suchergebnisfur7700/12.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 65%; Baumwolle: 33%; Elasthan: 2%",
      ],
      further_information: [
        "Oberstoff: Polyester / Baumwolle / Elasthan, schwarz",
        "Futter: -",
        "Größe(n): 42-68",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PRO FX, kurze Arbeitshose, schwarz (Farbcode: 1000), 280 g/qm, atmungsaktives Material, besonders hoher Tragekomfort (2-Wege-Stretch), dehnbarer Bund mit erhöhter Rückenpartie, Verstärkungen an rückseitiger Gürtelschlaufe und Schritt und Verstärkungen aus CORDURA® an Gesäß- und Beintaschen, robuste Dreifachnähte, verdeckter Knopf zum Produktschutz (z. B. Kratzer), 3M Scotchlite™ Reflective Material für bessere Sichtbarkeit, vielfältig und praktisch ausgestattet: Schenkeltaschen mit Patten, Zollstocktasche, verstellbare Hammerschlaufe, D-Ring, ID-Kartenhalter, Premium-Qualität, OEKO-TEX STANDARD 100®, Comfort Fit, Werkzeugtaschen 770P optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 143,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear299.jpg",
    imgHoverSrc: "/images/products/workwear230.jpg",

    title: "7701 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Suchergebnis für 7700",
    subcategory: " MOTION TEX PRO FX",
    description: {
      images: [
        "/images/description_product/suchergebnisfur7700/1.png",
        "/images/description_product/suchergebnisfur7700/2.png",
        "/images/description_product/suchergebnisfur7700/3.png",
        "/images/description_product/suchergebnisfur7700/4.png",
        "/images/description_product/suchergebnisfur7700/5.png",
        "/images/description_product/suchergebnisfur7700/6.png",
        "/images/description_product/suchergebnisfur7700/7.png",
        "/images/description_product/suchergebnisfur7700/8.png",
        "/images/description_product/suchergebnisfur7700/9.png",
        "/images/description_product/suchergebnisfur7700/10.png",
        "/images/description_product/suchergebnisfur7700/11.png",
        "/images/description_product/suchergebnisfur7700/12.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 65%; Baumwolle: 33%; Elasthan: 2%",
      ],
      further_information: [
        "Oberstoff: Polyester / Baumwolle / Elasthan, schwarz",
        "Futter: -",
        "Größe(n): 42-68",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PRO FX, kurze Arbeitshose, schwarz (Farbcode: 1000), 280 g/qm, atmungsaktives Material, besonders hoher Tragekomfort (2-Wege-Stretch), dehnbarer Bund mit erhöhter Rückenpartie, Verstärkungen an rückseitiger Gürtelschlaufe und Schritt und Verstärkungen aus CORDURA® an Gesäß- und Beintaschen, robuste Dreifachnähte, verdeckter Knopf zum Produktschutz (z. B. Kratzer), 3M Scotchlite™ Reflective Material für bessere Sichtbarkeit, vielfältig und praktisch ausgestattet: Schenkeltaschen mit Patten, Zollstocktasche, verstellbare Hammerschlaufe, D-Ring, ID-Kartenhalter, Premium-Qualität, OEKO-TEX STANDARD 100®, Comfort Fit, Werkzeugtaschen 770P optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 144,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear301.jpg",
    imgHoverSrc: "/images/products/workwear302.jpg",

    title: "7702 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Suchergebnis für 7700",
    subcategory: " MOTION TEX PRO FX",
    description: {
      images: [
        "/images/description_product/suchergebnisfur7700/1.png",
        "/images/description_product/suchergebnisfur7700/2.png",
        "/images/description_product/suchergebnisfur7700/3.png",
        "/images/description_product/suchergebnisfur7700/4.png",
        "/images/description_product/suchergebnisfur7700/5.png",
        "/images/description_product/suchergebnisfur7700/6.png",
        "/images/description_product/suchergebnisfur7700/7.png",
        "/images/description_product/suchergebnisfur7700/8.png",
        "/images/description_product/suchergebnisfur7700/9.png",
        "/images/description_product/suchergebnisfur7700/10.png",
        "/images/description_product/suchergebnisfur7700/11.png",
        "/images/description_product/suchergebnisfur7700/12.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 65%; Baumwolle: 33%; Elasthan: 2%",
      ],
      further_information: [
        "Oberstoff: Polyester / Baumwolle / Elasthan, schwarz",
        "Futter: -",
        "Größe(n): 42-68",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PRO FX, kurze Arbeitshose, schwarz (Farbcode: 1000), 280 g/qm, atmungsaktives Material, besonders hoher Tragekomfort (2-Wege-Stretch), dehnbarer Bund mit erhöhter Rückenpartie, Verstärkungen an rückseitiger Gürtelschlaufe und Schritt und Verstärkungen aus CORDURA® an Gesäß- und Beintaschen, robuste Dreifachnähte, verdeckter Knopf zum Produktschutz (z. B. Kratzer), 3M Scotchlite™ Reflective Material für bessere Sichtbarkeit, vielfältig und praktisch ausgestattet: Schenkeltaschen mit Patten, Zollstocktasche, verstellbare Hammerschlaufe, D-Ring, ID-Kartenhalter, Premium-Qualität, OEKO-TEX STANDARD 100®, Comfort Fit, Werkzeugtaschen 770P optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 145,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear304.jpg",
    imgHoverSrc: "/images/products/workwear305.jpg",

    title: "7705 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Suchergebnis für 7700",
    subcategory: " MOTION TEX PRO FX",
    description: {
      images: [
        "/images/description_product/suchergebnisfur7700/1.png",
        "/images/description_product/suchergebnisfur7700/2.png",
        "/images/description_product/suchergebnisfur7700/3.png",
        "/images/description_product/suchergebnisfur7700/4.png",
        "/images/description_product/suchergebnisfur7700/5.png",
        "/images/description_product/suchergebnisfur7700/6.png",
        "/images/description_product/suchergebnisfur7700/7.png",
        "/images/description_product/suchergebnisfur7700/8.png",
        "/images/description_product/suchergebnisfur7700/9.png",
        "/images/description_product/suchergebnisfur7700/10.png",
        "/images/description_product/suchergebnisfur7700/11.png",
        "/images/description_product/suchergebnisfur7700/12.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 65%; Baumwolle: 33%; Elasthan: 2%",
      ],
      further_information: [
        "Oberstoff: Polyester / Baumwolle / Elasthan, schwarz",
        "Futter: -",
        "Größe(n): 42-68",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PRO FX, kurze Arbeitshose, schwarz (Farbcode: 1000), 280 g/qm, atmungsaktives Material, besonders hoher Tragekomfort (2-Wege-Stretch), dehnbarer Bund mit erhöhter Rückenpartie, Verstärkungen an rückseitiger Gürtelschlaufe und Schritt und Verstärkungen aus CORDURA® an Gesäß- und Beintaschen, robuste Dreifachnähte, verdeckter Knopf zum Produktschutz (z. B. Kratzer), 3M Scotchlite™ Reflective Material für bessere Sichtbarkeit, vielfältig und praktisch ausgestattet: Schenkeltaschen mit Patten, Zollstocktasche, verstellbare Hammerschlaufe, D-Ring, ID-Kartenhalter, Premium-Qualität, OEKO-TEX STANDARD 100®, Comfort Fit, Werkzeugtaschen 770P optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 146,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear306.jpg",
    imgHoverSrc: "/images/products/workwear307.jpg",

    title: "7710 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        // "/images/description_product/Hosen/1.png",
        // "/images/description_product/Hosen/2.png",
        // "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, weiß / grau",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, lange Arbeitshose, weiß / grau (Farbcode: 1112), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund mit erhöhter Rückenpartie, Verstärkung an rückseitiger Gürtelschlaufe und Verstärkungen aus Canvas an Gesäß-, Knie-, Beintaschen und Beinabschlüssen, robuste Dreifachnähte, verdeckter Knopf zum Produktschutz (z. B. Kratzer), Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 147,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear308.jpg",
    imgHoverSrc: "/images/products/workwear309.jpg",

    title: "7711 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/suchergebnisfur7700/1.png",
        "/images/description_product/suchergebnisfur7700/2.png",
        "/images/description_product/suchergebnisfur7700/3.png",
        "/images/description_product/suchergebnisfur7700/4.png",
        "/images/description_product/suchergebnisfur7700/5.png",
        "/images/description_product/suchergebnisfur7700/6.png",
        "/images/description_product/suchergebnisfur7700/7.png",
        "/images/description_product/suchergebnisfur7700/8.png",
        "/images/description_product/suchergebnisfur7700/9.png",
        "/images/description_product/suchergebnisfur7700/10.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 65%; Baumwolle: 33%; Elasthan: 2%",
      ],
      further_information: [
        "Oberstoff: Polyester / Baumwolle / Elasthan, königsblau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PRO FX, lange Arbeitshose, königsblau / schwarz (Farbcode: 2010), 280 g/qm, atmungsaktives Material, besonders hoher Tragekomfort (2-Wege-Stretch), dehnbarer Bund mit erhöhter Rückenpartie, Verstärkungen an rückseitiger Gürtelschlaufe und Schritt und Verstärkungen aus CORDURA® an Gesäß-, Knie-, Beintaschen und Beinabschlüssen, robuste Dreifachnähte, verdeckter Knopf zum Produktschutz (z. B. Kratzer), 3M Scotchlite™ Reflective Material für bessere Sichtbarkeit, vielfältig und praktisch ausgestattet: Schenkeltaschen mit Patten, Zollstocktasche, Knietaschen für Polster mit Einschub von oben, verstellbare Hammerschlaufe, D-Ring, ID-Kartenhalter, Premium-Qualität, OEKO-TEX STANDARD 100®, Comfort Fit, Werkzeugtaschen 770P und Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 148,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear310.jpg",
    imgHoverSrc: "/images/products/workwear311.jpg",

    title: "7712 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/suchergebnisfur7700/1.png",
        "/images/description_product/suchergebnisfur7700/2.png",
        "/images/description_product/suchergebnisfur7700/3.png",
        "/images/description_product/suchergebnisfur7700/4.png",
        "/images/description_product/suchergebnisfur7700/5.png",
        "/images/description_product/suchergebnisfur7700/6.png",
        "/images/description_product/suchergebnisfur7700/7.png",
        "/images/description_product/suchergebnisfur7700/8.png",
        "/images/description_product/suchergebnisfur7700/9.png",
        "/images/description_product/suchergebnisfur7700/10.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 65%; Baumwolle: 33%; Elasthan: 2%",
      ],
      further_information: [
        "Oberstoff: Polyester / Baumwolle / Elasthan, grau / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PRO FX, lange Arbeitshose, grau / schwarz (Farbcode: 1210), 280 g/qm, atmungsaktives Material, besonders hoher Tragekomfort (2-Wege-Stretch), dehnbarer Bund mit erhöhter Rückenpartie, Verstärkungen an rückseitiger Gürtelschlaufe und Schritt und Verstärkungen aus CORDURA® an Gesäß-, Knie-, Beintaschen und Beinabschlüssen, robuste Dreifachnähte, verdeckter Knopf zum Produktschutz (z. B. Kratzer), 3M Scotchlite™ Reflective Material für bessere Sichtbarkeit, vielfältig und praktisch ausgestattet: Schenkeltaschen mit Patten, Zollstocktasche, Knietaschen für Polster mit Einschub von oben, verstellbare Hammerschlaufe, D-Ring, ID-Kartenhalter, Premium-Qualität, OEKO-TEX STANDARD 100®, Comfort Fit, Werkzeugtaschen 770P und Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 149,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear312.jpg",
    imgHoverSrc: "/images/products/workwear313.jpg",

    title: "7715 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/suchergebnisfur7700/1.png",
        "/images/description_product/suchergebnisfur7700/2.png",
        "/images/description_product/suchergebnisfur7700/3.png",
        "/images/description_product/suchergebnisfur7700/4.png",
        "/images/description_product/suchergebnisfur7700/5.png",
        "/images/description_product/suchergebnisfur7700/6.png",
        "/images/description_product/suchergebnisfur7700/7.png",
        "/images/description_product/suchergebnisfur7700/8.png",
        "/images/description_product/suchergebnisfur7700/9.png",
        "/images/description_product/suchergebnisfur7700/10.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 65%; Baumwolle: 33%; Elasthan: 2%",
      ],
      further_information: [
        "Oberstoff: Polyester / Baumwolle / Elasthan, khaki / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PRO FX, lange Arbeitshose, khaki / schwarz (Farbcode: 5110), 280 g/qm, atmungsaktives Material, besonders hoher Tragekomfort (2-Wege-Stretch), dehnbarer Bund mit erhöhter Rückenpartie, Verstärkungen an rückseitiger Gürtelschlaufe und Schritt und Verstärkungen aus CORDURA® an Gesäß-, Knie-, Beintaschen und Beinabschlüssen, robuste Dreifachnähte, verdeckter Knopf zum Produktschutz (z. B. Kratzer), 3M Scotchlite™ Reflective Material für bessere Sichtbarkeit, vielfältig und praktisch ausgestattet: Schenkeltaschen mit Patten, Zollstocktasche, Knietaschen für Polster mit Einschub von oben, verstellbare Hammerschlaufe, D-Ring, ID-Kartenhalter, Premium-Qualität, OEKO-TEX STANDARD 100®, Comfort Fit, Werkzeugtaschen 770P und Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 150,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear314.jpg",
    imgHoverSrc: "/images/products/workwear315.jpg",

    title: "7720 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/suchergebnisfur7700/1.png",
        "/images/description_product/suchergebnisfur7700/2.png",
        "/images/description_product/suchergebnisfur7700/3.png",
        "/images/description_product/suchergebnisfur7700/4.png",
        "/images/description_product/suchergebnisfur7700/5.png",
        "/images/description_product/suchergebnisfur7700/6.png",
        "/images/description_product/suchergebnisfur7700/7.png",
        "/images/description_product/suchergebnisfur7700/8.png",
        "/images/description_product/suchergebnisfur7700/9.png",
        "/images/description_product/suchergebnisfur7700/10.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 65%; Baumwolle: 33%; Elasthan: 2%",
      ],
      further_information: [
        "Oberstoff: Polyester / Baumwolle / Elasthan, khaki / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PRO FX, lange Arbeitshose, khaki / schwarz (Farbcode: 5110), 280 g/qm, atmungsaktives Material, besonders hoher Tragekomfort (2-Wege-Stretch), dehnbarer Bund mit erhöhter Rückenpartie, Verstärkungen an rückseitiger Gürtelschlaufe und Schritt und Verstärkungen aus CORDURA® an Gesäß-, Knie-, Beintaschen und Beinabschlüssen, robuste Dreifachnähte, verdeckter Knopf zum Produktschutz (z. B. Kratzer), 3M Scotchlite™ Reflective Material für bessere Sichtbarkeit, vielfältig und praktisch ausgestattet: Schenkeltaschen mit Patten, Zollstocktasche, Knietaschen für Polster mit Einschub von oben, verstellbare Hammerschlaufe, D-Ring, ID-Kartenhalter, Premium-Qualität, OEKO-TEX STANDARD 100®, Comfort Fit, Werkzeugtaschen 770P und Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 151,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear316.jpg",
    imgHoverSrc: "/images/products/workwear317.jpg",

    title: "7725 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/suchergebnisfur7700/1.png",
        "/images/description_product/suchergebnisfur7700/2.png",
        "/images/description_product/suchergebnisfur7700/3.png",
        "/images/description_product/suchergebnisfur7700/4.png",
        "/images/description_product/suchergebnisfur7700/5.png",
        "/images/description_product/suchergebnisfur7700/6.png",
        "/images/description_product/suchergebnisfur7700/7.png",
        "/images/description_product/suchergebnisfur7700/8.png",
        "/images/description_product/suchergebnisfur7700/9.png",
        "/images/description_product/suchergebnisfur7700/10.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 65%; Baumwolle: 33%; Elasthan: 2%",
      ],
      further_information: [
        "Oberstoff: Polyester / Baumwolle / Elasthan, khaki / schwarz",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PRO FX, lange Arbeitshose, khaki / schwarz (Farbcode: 5110), 280 g/qm, atmungsaktives Material, besonders hoher Tragekomfort (2-Wege-Stretch), dehnbarer Bund mit erhöhter Rückenpartie, Verstärkungen an rückseitiger Gürtelschlaufe und Schritt und Verstärkungen aus CORDURA® an Gesäß-, Knie-, Beintaschen und Beinabschlüssen, robuste Dreifachnähte, verdeckter Knopf zum Produktschutz (z. B. Kratzer), 3M Scotchlite™ Reflective Material für bessere Sichtbarkeit, vielfältig und praktisch ausgestattet: Schenkeltaschen mit Patten, Zollstocktasche, Knietaschen für Polster mit Einschub von oben, verstellbare Hammerschlaufe, D-Ring, ID-Kartenhalter, Premium-Qualität, OEKO-TEX STANDARD 100®, Comfort Fit, Werkzeugtaschen 770P und Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 152,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear318.jpg",
    imgHoverSrc: "/images/products/workwear319.jpg",

    title: "7750 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/suchergebnisfur7700/1.png",
        "/images/description_product/suchergebnisfur7700/2.png",
        "/images/description_product/suchergebnisfur7700/3.png",
        "/images/description_product/suchergebnisfur7700/4.png",
        "/images/description_product/suchergebnisfur7700/5.png",
        "/images/description_product/suchergebnisfur7700/6.png",
        "/images/description_product/suchergebnisfur7700/7.png",
        "/images/description_product/suchergebnisfur7700/8.png",
        "/images/description_product/suchergebnisfur7700/9.png",
        "/images/description_product/suchergebnisfur7700/10.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 65%; Baumwolle: 33%; Elasthan: 2%; Futter (Kragen): Polyester: 100%",
      ],
      further_information: [
        "Oberstoff: Polyester / Baumwolle / Elasthan, schwarz",
        "Futter: -",
        "Größe(n): 42-68",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PRO FX, Arbeitsjacke, schwarz (Farbcode: 1000), 280 g/qm, atmungsaktives Material, besonders hoher Tragekomfort (2-Wege-Stretch), verstellbare Ärmelabschlüsse mit Klettverschluss, verlängerter Rücken, angenehm weicher Fleece-Stehkragen, Verstärkungen an Taschen und Ärmeln sowie Schultern und Verstärkungen aus CORDURA® an Ellbogenpartie und Rücken, robuste Dreifachnähte, durchgängiger YKK-Reißverschluss mit Kinnschutz und Windstopper innen, 3M Scotchlite™ Reflective Material für bessere Sichtbarkeit, vielfältig und praktisch ausgestattet: Zwei Brusttaschen mit Patten, Seitentaschen, Tablet-, Innen- und Napoleontasche, Ärmeltasche mit Stiftschlaufen, Premium-Qualität, OEKO-TEX STANDARD 100®, Comfort Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 153,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear322.jpg",
    imgHoverSrc: "/images/products/workwear323.jpg",

    title: "7752 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/suchergebnisfur7700/1.png",
        "/images/description_product/suchergebnisfur7700/2.png",
        "/images/description_product/suchergebnisfur7700/3.png",
        "/images/description_product/suchergebnisfur7700/4.png",
        "/images/description_product/suchergebnisfur7700/5.png",
        "/images/description_product/suchergebnisfur7700/6.png",
        "/images/description_product/suchergebnisfur7700/7.png",
        "/images/description_product/suchergebnisfur7700/8.png",
        "/images/description_product/suchergebnisfur7700/9.png",
        "/images/description_product/suchergebnisfur7700/10.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 65%; Baumwolle: 33%; Elasthan: 2%; Futter (Kragen): Polyester: 100%",
      ],
      further_information: [
        "Oberstoff: Polyester / Baumwolle / Elasthan, schwarz",
        "Futter: -",
        "Größe(n): 42-68",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PRO FX, Arbeitsjacke, schwarz (Farbcode: 1000), 280 g/qm, atmungsaktives Material, besonders hoher Tragekomfort (2-Wege-Stretch), verstellbare Ärmelabschlüsse mit Klettverschluss, verlängerter Rücken, angenehm weicher Fleece-Stehkragen, Verstärkungen an Taschen und Ärmeln sowie Schultern und Verstärkungen aus CORDURA® an Ellbogenpartie und Rücken, robuste Dreifachnähte, durchgängiger YKK-Reißverschluss mit Kinnschutz und Windstopper innen, 3M Scotchlite™ Reflective Material für bessere Sichtbarkeit, vielfältig und praktisch ausgestattet: Zwei Brusttaschen mit Patten, Seitentaschen, Tablet-, Innen- und Napoleontasche, Ärmeltasche mit Stiftschlaufen, Premium-Qualität, OEKO-TEX STANDARD 100®, Comfort Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 154,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear324.jpg",
    imgHoverSrc: "/images/products/workwear325.jpg",

    title: "7755 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/suchergebnisfur7700/1.png",
        "/images/description_product/suchergebnisfur7700/2.png",
        "/images/description_product/suchergebnisfur7700/3.png",
        "/images/description_product/suchergebnisfur7700/4.png",
        "/images/description_product/suchergebnisfur7700/5.png",
        "/images/description_product/suchergebnisfur7700/6.png",
        "/images/description_product/suchergebnisfur7700/7.png",
        "/images/description_product/suchergebnisfur7700/8.png",
        "/images/description_product/suchergebnisfur7700/9.png",
        "/images/description_product/suchergebnisfur7700/10.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 65%; Baumwolle: 33%; Elasthan: 2%; Futter (Kragen): Polyester: 100%",
      ],
      further_information: [
        "Oberstoff: Polyester / Baumwolle / Elasthan, schwarz",
        "Futter: -",
        "Größe(n): 42-68",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PRO FX, Arbeitsjacke, schwarz (Farbcode: 1000), 280 g/qm, atmungsaktives Material, besonders hoher Tragekomfort (2-Wege-Stretch), verstellbare Ärmelabschlüsse mit Klettverschluss, verlängerter Rücken, angenehm weicher Fleece-Stehkragen, Verstärkungen an Taschen und Ärmeln sowie Schultern und Verstärkungen aus CORDURA® an Ellbogenpartie und Rücken, robuste Dreifachnähte, durchgängiger YKK-Reißverschluss mit Kinnschutz und Windstopper innen, 3M Scotchlite™ Reflective Material für bessere Sichtbarkeit, vielfältig und praktisch ausgestattet: Zwei Brusttaschen mit Patten, Seitentaschen, Tablet-, Innen- und Napoleontasche, Ärmeltasche mit Stiftschlaufen, Premium-Qualität, OEKO-TEX STANDARD 100®, Comfort Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 155,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear324.jpg",
    imgHoverSrc: "/images/products/workwear325.jpg",

    title: "7755 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        "/images/description_product/suchergebnisfur7700/1.png",
        "/images/description_product/suchergebnisfur7700/2.png",
        "/images/description_product/suchergebnisfur7700/3.png",
        "/images/description_product/suchergebnisfur7700/4.png",
        "/images/description_product/suchergebnisfur7700/5.png",
        "/images/description_product/suchergebnisfur7700/6.png",
        "/images/description_product/suchergebnisfur7700/7.png",
        "/images/description_product/suchergebnisfur7700/8.png",
        "/images/description_product/suchergebnisfur7700/9.png",
        "/images/description_product/suchergebnisfur7700/10.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 65%; Baumwolle: 33%; Elasthan: 2%; Futter (Kragen): Polyester: 100%",
      ],
      further_information: [
        "Oberstoff: Polyester / Baumwolle / Elasthan, schwarz",
        "Futter: -",
        "Größe(n): 42-68",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PRO FX, Arbeitsjacke, schwarz (Farbcode: 1000), 280 g/qm, atmungsaktives Material, besonders hoher Tragekomfort (2-Wege-Stretch), verstellbare Ärmelabschlüsse mit Klettverschluss, verlängerter Rücken, angenehm weicher Fleece-Stehkragen, Verstärkungen an Taschen und Ärmeln sowie Schultern und Verstärkungen aus CORDURA® an Ellbogenpartie und Rücken, robuste Dreifachnähte, durchgängiger YKK-Reißverschluss mit Kinnschutz und Windstopper innen, 3M Scotchlite™ Reflective Material für bessere Sichtbarkeit, vielfältig und praktisch ausgestattet: Zwei Brusttaschen mit Patten, Seitentaschen, Tablet-, Innen- und Napoleontasche, Ärmeltasche mit Stiftschlaufen, Premium-Qualität, OEKO-TEX STANDARD 100®, Comfort Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 156,
    sizes: ["/"],

    imgSrc: "/images/products/workwear326.jpg",
    imgHoverSrc: "/images/products/workwear326.jpg",

    title: "780P",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Zubehör",
    description: {
      images: [
        "/images/description_product/Zubehor/1.png",
        "/images/description_product/Zubehor/2.png",
        "/images/description_product/Zubehor/3.png",
        "/images/description_product/Zubehor/4.png",
        "/images/description_product/Zubehor/5.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 65%; Baumwolle: 33%; Elasthan: 2%",
      ],
      further_information: [
        "Material: Polyester / Baumwolle / Elasthan, schwarz",
        "Sonstiges: -",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 50 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Werkzeugtasche, schwarz (Farbcode: 1000), 280 g/qm, aus widerstandsfähigem Canvas, 24 x 27 cm, besonders viel Platz, funktionale Details: Jeweils drei Stifttaschen und Werkzeugschlaufen, Zollstocktasche, verstellbare Hammerschlaufe, verschließbare Innentasche mit YKK-Reißverschluss, zwei Außentaschen mit Klettverschluss, robuste Gürtelschlaufen mit Druckknöpfen zur einfachen Fixierung am Gürtel, nützlicher und praktischer Begleiter",
      ],
    },
    isAvailable: true,
  },
  {
    id: 157,
    sizes: ["/"],

    imgSrc: "/images/products/workwear327.jpg",
    imgHoverSrc: "/images/products/workwear327.jpg",

    title: "G115BP",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Suchergebnis für 'G115BP'",
    description: {
      images: [
        "/images/description_product/Zubehor/1.png",
        "/images/description_product/Zubehor/2.png",
        "/images/description_product/Zubehor/3.png",
        "/images/description_product/Zubehor/4.png",
        "/images/description_product/Zubehor/5.png",
        "/images/description_product/Zubehor/6.png",
      ],
      product_details: ["Oberstoff: Polyester: 100%"],
      further_information: [
        "Material: Polyester / Baumwolle / Elasthan, schwarz",
        "Sonstiges: -",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 50 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Werkzeugtasche, schwarz (Farbcode: 1000), 280 g/qm, aus widerstandsfähigem Canvas, 24 x 27 cm, besonders viel Platz, funktionale Details: Jeweils drei Stifttaschen und Werkzeugschlaufen, Zollstocktasche, verstellbare Hammerschlaufe, verschließbare Innentasche mit YKK-Reißverschluss, zwei Außentaschen mit Klettverschluss, robuste Gürtelschlaufen mit Druckknöpfen zur einfachen Fixierung am Gürtel, nützlicher und praktischer Begleiter",
      ],
    },
    isAvailable: true,
  },
  {
    id: 158,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear328.jpg",
    imgHoverSrc: "/images/products/workwear329.jpg",

    title: "7612 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Hosen",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Polyester: 65%; Baumwolle: 35%"],
      further_information: [
        "Oberstoff: Polyester / Baumwolle, weiß / grau",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX PLUS, lange Arbeitshose, weiß / grau (Farbcode: 1112), 280 g/qm, atmungsaktives Material, hoher Tragekomfort, dehnbarer Bund mit erhöhter Rückenpartie, Verstärkung an rückseitiger Gürtelschlaufe und Verstärkungen aus Canvas an Gesäß-, Knie-, Beintaschen und Beinabschlüssen, robuste Dreifachnähte, verdeckter Knopf zum Produktschutz (z. B. Kratzer), Reflexelemente für bessere Sichtbarkeit, praktische und nützliche Extras: Schenkeltaschen mit Patten, Zollstocktasche mit aufgesetzter Tasche, Knietaschen für Polster mit Einschub von oben, Hammerschlaufe, D-Ring, OEKO-TEX STANDARD 100®, Comfort Fit, Knieschutz 76K (EN 14404) optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 159,
    sizes: ["/"],

    imgSrc: "/images/products/workwear330.jpg",
    imgHoverSrc: "/images/products/workwear330.jpg",

    title: "G130BP",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Suchergebnis für 'G130BP'",
    description: {
      images: [
        "/images/description_product/Zubehor/1.png",
        "/images/description_product/Zubehor/2.png",
        "/images/description_product/Zubehor/3.png",
        "/images/description_product/Zubehor/4.png",
        "/images/description_product/Zubehor/5.png",
        "/images/description_product/Zubehor/6.png",
      ],
      product_details: ["Oberstoff: Polyester: 100%"],
      further_information: [
        "Material: Polyester, schwarz",
        "Sonstiges: Schnalle aus Kunststoff, schwarz",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 100 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Gürtel mit Kunststoffschnalle, schwarz (Farbcode: 1000), Breite ca. 3,8 cm, besonders robust und strapazierfähig, Schnappverschluss für optimale Fixierung, stufenlos verstellbar, perfekter Tragekomfort, 130 cm inkl. Schnalle",
      ],
    },
    isAvailable: true,
  },
  {
    id: 160,
    sizes: ["/"],

    imgSrc: "/images/products/workwear331.jpg",
    imgHoverSrc: "/images/products/workwear331.jpg",

    title: "G145BP",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Suchergebnis für 'G145BP'",
    description: {
      images: [
        "/images/description_product/Zubehor/1.png",
        "/images/description_product/Zubehor/2.png",
        "/images/description_product/Zubehor/3.png",
        "/images/description_product/Zubehor/4.png",
        "/images/description_product/Zubehor/5.png",
        "/images/description_product/Zubehor/6.png",
      ],
      product_details: ["Oberstoff: Polyester: 100%"],
      further_information: [
        "Material: Polyester, schwarz",
        "Sonstiges: Schnalle aus Kunststoff, schwarz",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 100 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Gürtel mit Kunststoffschnalle, schwarz (Farbcode: 1000), Breite ca. 3,8 cm, besonders robust und strapazierfähig, Schnappverschluss für optimale Fixierung, stufenlos verstellbar, perfekter Tragekomfort, 145 cm inkl. Schnalle",
      ],
    },
    isAvailable: true,
  },
  {
    id: 161,
    sizes: ["/"],

    imgSrc: "/images/products/workwear332.jpg",
    imgHoverSrc: "/images/products/workwear333.jpg",

    title: "NHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Zubehör",
    description: {
      images: [
        "/images/description_product/Zubehor/1.png",
        "/images/description_product/Zubehor/2.png",
        "/images/description_product/Zubehor/3.png",
        "/images/description_product/Zubehor/4.png",
        "/images/description_product/Zubehor/5.png",
      ],
      product_details: [
        "Oberstoff 2: Polyester: 100%; Oberstoff: Polyester: 100%",
      ],
      further_information: [
        "Material: Polyester, anthrazit",
        "Sonstiges: -",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 100 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Multifunktions-Schlauchtuch, wärmendes und weiches Material aus Fleece im unteren Teil, anthrazit, 210 g/qm, atmungsaktives Jersey im oberen Teil, anthrazit (Farbcode: 1500), 150 g/qm, Abmessungen: 24 x 70 cm (inkl. 22 cm Fleece), waschbar, vielseitige Tragemöglichkeiten (Halstuch, Kopfbedeckung, Mund-Nasen-Bedeckung), hohe Elastizität, perfekter Tragekomfort",
      ],
    },
    isAvailable: true,
  },
  {
    id: 162,
    sizes: ["35/38", "39/42", "43/46", "47/50"],

    imgSrc: "/images/products/workwear334.jpg",
    imgHoverSrc: "/images/products/workwear335.jpg",

    title: "720",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Socken",
    description: {
      images: [
        "/images/description_product/Socken/1.png",
        "/images/description_product/Socken/2.png",
        "/images/description_product/Socken/3.png",
        "/images/description_product/Socken/4.png",
        "/images/description_product/Socken/5.png",
        "/images/description_product/Socken/6.png",
        "/images/description_product/Socken/7.png",
        "/images/description_product/Socken/8.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 54%; Polyester: 44%; Elasthan: 2%",
      ],
      further_information: [
        "Material: Baumwolle / Polyester / Elasthan, schwarz",
        "Sonstiges: -",
        "Größe(n): 35/38, 39/42, 43/46, 47/50",
        "Verpackungseinheit: 160 Paar",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS Basic-Socken, schwarz (Farbcode: 1000), wadenhoch, gute Passform, guter Halt dank breitem und elastischem Bund, gerippter Bund, angenehmer Tragekomfort, OEKO-TEX STANDARD 100®",
      ],
    },
    isAvailable: true,
  },
  {
    id: 163,
    sizes: ["35/38", "39/42", "43/46", "47/50"],

    imgSrc: "/images/products/workwear336.jpg",
    imgHoverSrc: "/images/products/workwear337.jpg",

    title: "720",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Socken",
    description: {
      images: [
        "/images/description_product/Socken/1.png",
        "/images/description_product/Socken/2.png",
        "/images/description_product/Socken/3.png",
        "/images/description_product/Socken/4.png",
        "/images/description_product/Socken/5.png",
        "/images/description_product/Socken/6.png",
        "/images/description_product/Socken/7.png",
        "/images/description_product/Socken/8.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 54%; Polyester: 44%; Elasthan: 2%",
      ],
      further_information: [
        "Material: Baumwolle / Polyester / Elasthan, schwarz",
        "Sonstiges: -",
        "Größe(n): 35/38, 39/42, 43/46, 47/50",
        "Verpackungseinheit: 160 Paar",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS Basic-Socken, schwarz (Farbcode: 1000), wadenhoch, gute Passform, guter Halt dank breitem und elastischem Bund, gerippter Bund, angenehmer Tragekomfort, OEKO-TEX STANDARD 100®",
      ],
    },
    isAvailable: true,
  },
  {
    id: 164,
    sizes: ["35/38", "39/42", "43/46", "47/50"],

    imgSrc: "/images/products/workwear338.jpg",
    imgHoverSrc: "/images/products/workwear339.jpg",

    title: "721",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Socken",
    description: {
      images: [
        "/images/description_product/Socken/1.png",
        "/images/description_product/Socken/2.png",
        "/images/description_product/Socken/3.png",
        "/images/description_product/Socken/4.png",
        "/images/description_product/Socken/5.png",
        "/images/description_product/Socken/6.png",
        "/images/description_product/Socken/7.png",
        "/images/description_product/Socken/8.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 54%; Polyester: 44%; Elasthan: 2%",
      ],
      further_information: [
        "Material: Baumwolle / Polyester / Elasthan, schwarz",
        "Sonstiges: -",
        "Größe(n): 35/38, 39/42, 43/46, 47/50",
        "Verpackungseinheit: 160 Paar",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS Basic-Socken, schwarz (Farbcode: 1000), wadenhoch, gute Passform, guter Halt dank breitem und elastischem Bund, gerippter Bund, angenehmer Tragekomfort, OEKO-TEX STANDARD 100®",
      ],
    },
    isAvailable: true,
  },
  {
    id: 165,
    sizes: ["35/38", "39/42", "43/46", "47/50"],

    imgSrc: "/images/products/workwear340.jpg",
    imgHoverSrc: "/images/products/workwear341.jpg",

    title: "721",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Socken",
    description: {
      images: [
        "/images/description_product/Socken/1.png",
        "/images/description_product/Socken/2.png",
        "/images/description_product/Socken/3.png",
        "/images/description_product/Socken/4.png",
        "/images/description_product/Socken/5.png",
        "/images/description_product/Socken/6.png",
        "/images/description_product/Socken/7.png",
        "/images/description_product/Socken/8.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 54%; Polyester: 44%; Elasthan: 2%",
      ],
      further_information: [
        "Material: Baumwolle / Polyester / Elasthan, schwarz",
        "Sonstiges: -",
        "Größe(n): 35/38, 39/42, 43/46, 47/50",
        "Verpackungseinheit: 160 Paar",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS Basic-Socken, schwarz (Farbcode: 1000), wadenhoch, gute Passform, guter Halt dank breitem und elastischem Bund, gerippter Bund, angenehmer Tragekomfort, OEKO-TEX STANDARD 100®",
      ],
    },
    isAvailable: true,
  },
  {
    id: 166,
    sizes: ["35/38", "39/42", "43/46", "47/50"],

    imgSrc: "/images/products/workwear342.jpg",
    imgHoverSrc: "/images/products/workwear343.jpg",

    title: "722",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Socken",
    description: {
      images: [
        "/images/description_product/Socken/1.png",
        "/images/description_product/Socken/2.png",
        "/images/description_product/Socken/3.png",
        "/images/description_product/Socken/4.png",
        "/images/description_product/Socken/5.png",
        "/images/description_product/Socken/6.png",
        "/images/description_product/Socken/7.png",
        "/images/description_product/Socken/8.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 54%; Polyester: 44%; Elasthan: 2%",
      ],
      further_information: [
        "Material: Baumwolle / Polyester / Elasthan, schwarz",
        "Sonstiges: -",
        "Größe(n): 35/38, 39/42, 43/46, 47/50",
        "Verpackungseinheit: 160 Paar",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS Basic-Socken, schwarz (Farbcode: 1000), wadenhoch, gute Passform, guter Halt dank breitem und elastischem Bund, gerippter Bund, angenehmer Tragekomfort, OEKO-TEX STANDARD 100®",
      ],
    },
    isAvailable: true,
  },
  {
    id: 167,
    sizes: ["35/38", "39/42", "43/46", "47/50"],

    imgSrc: "/images/products/workwear344.jpg",
    imgHoverSrc: "/images/products/workwear345.jpg",

    title: "722",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Socken",
    description: {
      images: [
        "/images/description_product/Socken/1.png",
        "/images/description_product/Socken/2.png",
        "/images/description_product/Socken/3.png",
        "/images/description_product/Socken/4.png",
        "/images/description_product/Socken/5.png",
        "/images/description_product/Socken/6.png",
        "/images/description_product/Socken/7.png",
        "/images/description_product/Socken/8.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 54%; Polyester: 44%; Elasthan: 2%",
      ],
      further_information: [
        "Material: Baumwolle / Polyester / Elasthan, schwarz",
        "Sonstiges: -",
        "Größe(n): 35/38, 39/42, 43/46, 47/50",
        "Verpackungseinheit: 160 Paar",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS Basic-Socken, schwarz (Farbcode: 1000), wadenhoch, gute Passform, guter Halt dank breitem und elastischem Bund, gerippter Bund, angenehmer Tragekomfort, OEKO-TEX STANDARD 100®",
      ],
    },
    isAvailable: true,
  },
  {
    id: 168,
    sizes: ["35/38", "39/42", "43/46", "47/50"],

    imgSrc: "/images/products/workwear346.jpg",
    imgHoverSrc: "/images/products/workwear347.jpg",

    title: "723",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Socken",
    description: {
      images: [
        "/images/description_product/Socken/1.png",
        "/images/description_product/Socken/2.png",
        "/images/description_product/Socken/3.png",
        "/images/description_product/Socken/4.png",
        "/images/description_product/Socken/5.png",
        "/images/description_product/Socken/6.png",
        "/images/description_product/Socken/7.png",
        "/images/description_product/Socken/8.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 54%; Polyester: 44%; Elasthan: 2%",
      ],
      further_information: [
        "Material: Baumwolle / Polyester / Elasthan, schwarz",
        "Sonstiges: -",
        "Größe(n): 35/38, 39/42, 43/46, 47/50",
        "Verpackungseinheit: 160 Paar",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS Basic-Socken, schwarz (Farbcode: 1000), wadenhoch, gute Passform, guter Halt dank breitem und elastischem Bund, gerippter Bund, angenehmer Tragekomfort, OEKO-TEX STANDARD 100®",
      ],
    },
    isAvailable: true,
  },
  {
    id: 169,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear348.jpg",
    imgHoverSrc: "/images/products/workwear349.jpg",

    title: "7004 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%"],
      further_information: [
        "Oberstoff: Baumwolle, schwarz",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 100 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, schwarz (Farbcode: 1000), 145 g/qm, angenehmer Tragekomfort dank weichem Material, Single-Jersey, kardierte Baumwolle, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, ohne Seitennaht, besonders weich und glatt, für Promotionzwecke geeignet, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 170,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear350.jpg",
    imgHoverSrc: "/images/products/workwear351.jpg",

    title: "7004 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%"],
      further_information: [
        "Oberstoff: Baumwolle, schwarz",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 100 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, schwarz (Farbcode: 1000), 145 g/qm, angenehmer Tragekomfort dank weichem Material, Single-Jersey, kardierte Baumwolle, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, ohne Seitennaht, besonders weich und glatt, für Promotionzwecke geeignet, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 171,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear352.jpg",
    imgHoverSrc: "/images/products/workwear353.jpg",

    title: "7004 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%"],
      further_information: [
        "Oberstoff: Baumwolle, schwarz",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 100 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, schwarz (Farbcode: 1000), 145 g/qm, angenehmer Tragekomfort dank weichem Material, Single-Jersey, kardierte Baumwolle, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, ohne Seitennaht, besonders weich und glatt, für Promotionzwecke geeignet, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 172,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear355.jpg",
    imgHoverSrc: "/images/products/workwear354.jpg",

    title: "7004 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%"],
      further_information: [
        "Oberstoff: Baumwolle, schwarz",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 100 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, schwarz (Farbcode: 1000), 145 g/qm, angenehmer Tragekomfort dank weichem Material, Single-Jersey, kardierte Baumwolle, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, ohne Seitennaht, besonders weich und glatt, für Promotionzwecke geeignet, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 173,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear356.jpg",
    imgHoverSrc: "/images/products/workwear357.jpg",

    title: "7004 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%"],
      further_information: [
        "Oberstoff: Baumwolle, schwarz",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 100 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, schwarz (Farbcode: 1000), 145 g/qm, angenehmer Tragekomfort dank weichem Material, Single-Jersey, kardierte Baumwolle, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, ohne Seitennaht, besonders weich und glatt, für Promotionzwecke geeignet, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 174,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear358.jpg",
    imgHoverSrc: "/images/products/workwear359.jpg",

    title: "7004 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%"],
      further_information: [
        "Oberstoff: Baumwolle, schwarz",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 100 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, schwarz (Farbcode: 1000), 145 g/qm, angenehmer Tragekomfort dank weichem Material, Single-Jersey, kardierte Baumwolle, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, ohne Seitennaht, besonders weich und glatt, für Promotionzwecke geeignet, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 175,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear362.jpg",
    imgHoverSrc: "/images/products/workwear363.jpg",

    title: "7004 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%"],
      further_information: [
        "Oberstoff: Baumwolle, schwarz",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 100 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, schwarz (Farbcode: 1000), 145 g/qm, angenehmer Tragekomfort dank weichem Material, Single-Jersey, kardierte Baumwolle, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, ohne Seitennaht, besonders weich und glatt, für Promotionzwecke geeignet, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 176,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear364.jpg",
    imgHoverSrc: "/images/products/workwear365.jpg",

    title: "7004 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%"],
      further_information: [
        "Oberstoff: Baumwolle, schwarz",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 100 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, schwarz (Farbcode: 1000), 145 g/qm, angenehmer Tragekomfort dank weichem Material, Single-Jersey, kardierte Baumwolle, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, ohne Seitennaht, besonders weich und glatt, für Promotionzwecke geeignet, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 177,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear366.jpg",
    imgHoverSrc: "/images/products/workwear367.jpg",

    title: "7004 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Shirts",
    description: {
      images: [
        "/images/description_product/Hosen/1.png",
        "/images/description_product/Hosen/2.png",
        "/images/description_product/Hosen/3.png",
        "/images/description_product/Hosen/4.png",
        "/images/description_product/Hosen/5.png",
        "/images/description_product/Hosen/6.png",
        "/images/description_product/Hosen/7.png",
        "/images/description_product/Hosen/8.png",
        "/images/description_product/Hosen/9.png",
        "/images/description_product/Hosen/10.png",
      ],
      product_details: ["Oberstoff: Baumwolle: 100%"],
      further_information: [
        "Oberstoff: Baumwolle, schwarz",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 100 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100®",
        "Beschreibung: NITRAS MOTION TEX LIGHT, T-Shirt, Kurzarm, schwarz (Farbcode: 1000), 145 g/qm, angenehmer Tragekomfort dank weichem Material, Single-Jersey, kardierte Baumwolle, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, ohne Seitennaht, besonders weich und glatt, für Promotionzwecke geeignet, OEKO-TEX STANDARD 100®, Regular Fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 178,
    sizes: ["One Size"],

    imgSrc: "/images/products/workwear368.jpg",
    imgHoverSrc: "/images/products/workwear368.jpg",

    title: "734",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        // "/images/description_product/Kopfbedeckung/1.png",
        // "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
        // "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Oberstoff: Polyacryl: 100%; Futter: Polyester: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },

  {
    id: 179,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear369.jpg",
    imgHoverSrc: "/images/products/workwear370.jpg",

    title: "7661 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        // "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: [
        "Wattierung: Polyester: 100%; Futter: Polyester: 100%; Oberstoff 2: Polyester: 92%; Elasthan: 8%; Oberstoff: Polyester: 100%",
      ],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, braun (Farbcode: 5000), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 180,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear371.jpg",
    imgHoverSrc: "/images/products/workwear372.jpg",

    title: "7042 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        // "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: [
        "Wattierung: Polyester: 100%; Futter: Polyester: 100%; Oberstoff 2: Polyester: 92%; Elasthan: 8%; Oberstoff: Polyester: 100%",
      ],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, braun (Farbcode: 5000), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },

  {
    id: 181,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear373.jpg",
    imgHoverSrc: "/images/products/workwear374.jpg",

    title: "7042 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Jacken",
    description: {
      images: [
        // "/images/description_product/Jacken/1.png",
        "/images/description_product/Jacken/2.png",
        "/images/description_product/Jacken/3.png",
        "/images/description_product/Jacken/4.png",
        "/images/description_product/Jacken/5.png",
        "/images/description_product/Jacken/6.png",
        "/images/description_product/Jacken/7.png",
        "/images/description_product/Jacken/8.png",
      ],
      product_details: [
        "Wattierung: Polyester: 100%; Futter: Polyester: 100%; Oberstoff 2: Polyester: 92%; Elasthan: 8%; Oberstoff: Polyester: 100%",
      ],
      further_information: [
        "Oberstoff: Baumwolle / Polyester, braun",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 15 Stück",
        "Zertifizierung: OEKO-TEX STANDARD 100",
        "Beschreibung: NITRAS MOTION TEX LIGHT, Sweatjacke, braun (Farbcode: 5000), 300 g/qm, angenehmer Tragekomfort, gekämmte Baumwolle, einlaufvorbehandelt, enzym-behandelt, gerippter und hoher Stehkragen, Ziernaht im Nacken, gerippte Ärmelbündchen und gerippter Bund für optimalen Halt, zwei Seitentaschen, durchgehender YKK-Reißverschluss, farblich abgestimmt, weiche und angeraute Innenseite, OEKO-TEX STANDARD 100®, Regular fit",
      ],
    },
    isAvailable: true,
  },
];

// * ================== Protective clothing ==================

export const protectiveclothing = [
  {
    id: 182,
    imgSrc: "/images/products/protectiveclothing1.jpg",
    imgHoverSrc: "/images/products/protectiveclothing2.jpg",
    title: "730",
    sizes: ["One Size"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        "/images/description_product/Kopfbedeckung/1.png",
        "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
        "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Oberstoff: Polyacryl: 100%; Futter: Polyester: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },
  {
    id: 183,
    imgSrc: "/images/products/protectiveclothing3.jpg",
    imgHoverSrc: "/images/products/protectiveclothing4.jpg",
    title: "730",
    sizes: ["One Size"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        "/images/description_product/Kopfbedeckung/1.png",
        "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
        "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Oberstoff: Polyacryl: 100%; Futter: Polyester: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },
  {
    id: 184,
    imgSrc: "/images/products/protectiveclothing5.jpg",
    imgHoverSrc: "/images/products/protectiveclothing6.jpg",
    title: "4500 // PP",
    sizes: ["M", "L", "XL", "2XL", "3XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        "/images/description_product/Kopfbedeckung/1.png",
        "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
        // "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Futter: Polyester: 100%; Oberstoff: Polyacryl: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },
  {
    id: 185,
    imgSrc: "/images/products/protectiveclothing7.jpg",
    imgHoverSrc: "/images/products/protectiveclothing8.jpg",
    title: "4510 // PP",
    sizes: ["L", "XL", "2XL", "3XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        "/images/description_product/Kopfbedeckung/1.png",
        "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
        // "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Futter: Polyester: 100%; Oberstoff: Polyacryl: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },
  {
    id: 186,
    imgSrc: "/images/products/protectiveclothing9.jpg",
    imgHoverSrc: "/images/products/protectiveclothing10.jpg",
    title: "4600 // PROTECT",
    sizes: ["M", "L", "XL", "2XL", "3XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        "/images/description_product/Kopfbedeckung/1.png",
        "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
        // "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Futter: Polyester: 100%; Oberstoff: Polyacryl: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },
  {
    id: 187,
    imgSrc: "/images/products/protectiveclothing11.jpg",
    imgHoverSrc: "/images/products/protectiveclothing12.jpg",
    title: "7008 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 55%; Polyester: 45%; EN 13758-2l; EN 13758-2; UPF 40+",
      ],
      further_information: [
        "Oberstoff: Baumwolle / Polyester",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-T-Shirt, Kurzarm, neongelb (Farbcode: 4000), 190 g/qm, angenehmer Tragekomfort dank weichem Material, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, besonders weich und glatt, zwei umlaufende Reflexstreifen und Schulterreflexstreifen für besonders gute Sichtbarkeit, UV-Schutz UPF 40+, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll",
      ],
    },
    isAvailable: true,
  },
  {
    id: 188,
    imgSrc: "/images/products/protectiveclothing13.jpg",
    imgHoverSrc: "/images/products/protectiveclothing14.jpg",
    title: "7008 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 55%; Polyester: 45%; EN 13758-2l; EN 13758-2; UPF 40+",
      ],
      further_information: [
        "Oberstoff: Baumwolle / Polyester",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-T-Shirt, Kurzarm, neongelb (Farbcode: 4000), 190 g/qm, angenehmer Tragekomfort dank weichem Material, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, robuste Doppelnähte an Saum und Ärmeln, besonders weich und glatt, zwei umlaufende Reflexstreifen und Schulterreflexstreifen für besonders gute Sichtbarkeit, UV-Schutz UPF 40+, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll",
      ],
    },
    isAvailable: true,
  },
  {
    id: 189,
    imgSrc: "/images/products/protectiveclothing15.jpg",
    imgHoverSrc: "/images/products/protectiveclothing16.jpg",
    title: "7009 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 55%; Polyester: 45%; EN 13758-2l; EN 13758-2; UPF 40+",
      ],
      further_information: [
        "Oberstoff: Baumwolle / Polyester",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Langarmshirt, neongelb (Farbcode: 4000), 190 g/qm, angenehmer Tragekomfort dank weichem Material, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, Strickbund an Ärmeln für optimalen Sitz, robuste Doppelnähte am Saum, besonders weich und glatt, umlaufende Reflexstreifen und Schulterreflexstreifen für besonders gute Sichtbarkeit, UV-Schutz UPF 40+, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll",
      ],
    },
    isAvailable: true,
  },
  {
    id: 190,
    imgSrc: "/images/products/protectiveclothing17.jpg",
    imgHoverSrc: "/images/products/protectiveclothing18.jpg",
    title: "7009 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 55%; Polyester: 45%; EN 13758-2l; EN 13758-2; UPF 40+",
      ],
      further_information: [
        "Oberstoff: Baumwolle / Polyester",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Langarmshirt, neongelb (Farbcode: 4000), 190 g/qm, angenehmer Tragekomfort dank weichem Material, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, Strickbund an Ärmeln für optimalen Sitz, robuste Doppelnähte am Saum, besonders weich und glatt, umlaufende Reflexstreifen und Schulterreflexstreifen für besonders gute Sichtbarkeit, UV-Schutz UPF 40+, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll",
      ],
    },
    isAvailable: true,
  },
  {
    id: 191,
    imgSrc: "/images/products/protectiveclothing21.jpg",
    imgHoverSrc: "/images/products/protectiveclothing22.jpg",
    title: "7013 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 55%; Polyester: 45%; EN 13758-2l; EN 13758-2; UPF 40+",
      ],
      further_information: [
        "Oberstoff: Baumwolle / Polyester",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Langarmshirt, neongelb (Farbcode: 4000), 190 g/qm, angenehmer Tragekomfort dank weichem Material, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, Strickbund an Ärmeln für optimalen Sitz, robuste Doppelnähte am Saum, besonders weich und glatt, umlaufende Reflexstreifen und Schulterreflexstreifen für besonders gute Sichtbarkeit, UV-Schutz UPF 40+, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll",
      ],
    },
    isAvailable: true,
  },
  {
    id: 192,
    imgSrc: "/images/products/protectiveclothing23.jpg",
    imgHoverSrc: "/images/products/protectiveclothing24.jpg",
    title: "7013 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 55%; Polyester: 45%; EN 13758-2l; EN 13758-2; UPF 40+",
      ],
      further_information: [
        "Oberstoff: Baumwolle / Polyester",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Langarmshirt, neongelb (Farbcode: 4000), 190 g/qm, angenehmer Tragekomfort dank weichem Material, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, Strickbund an Ärmeln für optimalen Sitz, robuste Doppelnähte am Saum, besonders weich und glatt, umlaufende Reflexstreifen und Schulterreflexstreifen für besonders gute Sichtbarkeit, UV-Schutz UPF 40+, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll",
      ],
    },
    isAvailable: true,
  },
  {
    id: 193,
    imgSrc: "/images/products/protectiveclothing25.jpg",
    imgHoverSrc: "/images/products/protectiveclothing26.jpg",
    title: "7014 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 55%; Polyester: 45%; EN 13758-2l; EN 13758-2; UPF 40+",
      ],
      further_information: [
        "Oberstoff: Baumwolle / Polyester",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Langarmshirt, neongelb (Farbcode: 4000), 190 g/qm, angenehmer Tragekomfort dank weichem Material, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, Strickbund an Ärmeln für optimalen Sitz, robuste Doppelnähte am Saum, besonders weich und glatt, umlaufende Reflexstreifen und Schulterreflexstreifen für besonders gute Sichtbarkeit, UV-Schutz UPF 40+, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll",
      ],
    },
    isAvailable: true,
  },
  {
    id: 194,
    imgSrc: "/images/products/protectiveclothing27.jpg",
    imgHoverSrc: "/images/products/protectiveclothing28.jpg",
    title: "7014 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 55%; Polyester: 45%; EN 13758-2l; EN 13758-2; UPF 40+",
      ],
      further_information: [
        "Oberstoff: Baumwolle / Polyester",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Langarmshirt, neongelb (Farbcode: 4000), 190 g/qm, angenehmer Tragekomfort dank weichem Material, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, Strickbund an Ärmeln für optimalen Sitz, robuste Doppelnähte am Saum, besonders weich und glatt, umlaufende Reflexstreifen und Schulterreflexstreifen für besonders gute Sichtbarkeit, UV-Schutz UPF 40+, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll",
      ],
    },
    isAvailable: true,
  },
  {
    id: 195,
    imgSrc: "/images/products/protectiveclothing29.jpg",
    imgHoverSrc: "/images/products/protectiveclothing30.jpg",
    title: "7110",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 55%; Polyester: 45%; EN 13758-2l; EN 13758-2; UPF 40+",
      ],
      further_information: [
        "Oberstoff: Baumwolle / Polyester",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Langarmshirt, neongelb (Farbcode: 4000), 190 g/qm, angenehmer Tragekomfort dank weichem Material, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, Strickbund an Ärmeln für optimalen Sitz, robuste Doppelnähte am Saum, besonders weich und glatt, umlaufende Reflexstreifen und Schulterreflexstreifen für besonders gute Sichtbarkeit, UV-Schutz UPF 40+, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll",
      ],
    },
    isAvailable: true,
  },
  {
    id: 196,
    imgSrc: "/images/products/protectiveclothing31.jpg",
    imgHoverSrc: "/images/products/protectiveclothing32.jpg",
    title: "7111",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 55%; Polyester: 45%; EN 13758-2l; EN 13758-2; UPF 40+",
      ],
      further_information: [
        "Oberstoff: Baumwolle / Polyester",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Langarmshirt, neongelb (Farbcode: 4000), 190 g/qm, angenehmer Tragekomfort dank weichem Material, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, Strickbund an Ärmeln für optimalen Sitz, robuste Doppelnähte am Saum, besonders weich und glatt, umlaufende Reflexstreifen und Schulterreflexstreifen für besonders gute Sichtbarkeit, UV-Schutz UPF 40+, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll",
      ],
    },
    isAvailable: true,
  },
  {
    id: 197,
    imgSrc: "/images/products/protectiveclothing33.jpg",
    imgHoverSrc: "/images/products/protectiveclothing34.jpg",
    title: "7111",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
      ],
      product_details: [
        "Oberstoff: Baumwolle: 55%; Polyester: 45%; EN 13758-2l; EN 13758-2; UPF 40+",
      ],
      further_information: [
        "Oberstoff: Baumwolle / Polyester",
        "Futter: -",
        "Größe(n): XS-6XL",
        "Verpackungseinheit: 20 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Langarmshirt, neongelb (Farbcode: 4000), 190 g/qm, angenehmer Tragekomfort dank weichem Material, gerippter Rundhalsausschnitt mit gleichfarbigem Nackenband, Strickbund an Ärmeln für optimalen Sitz, robuste Doppelnähte am Saum, besonders weich und glatt, umlaufende Reflexstreifen und Schulterreflexstreifen für besonders gute Sichtbarkeit, UV-Schutz UPF 40+, Regular Fit, Long Fit für einen optimalen Sitz, macht jede Bewegung mit und das Shirt bleibt, wo es sein soll",
      ],
    },
    isAvailable: true,
  },
  {
    id: 198,
    imgSrc: "/images/products/protectiveclothing37.jpg",
    imgHoverSrc: "/images/products/protectiveclothing38.jpg",
    title: "7140 // MOTION TEX VIZ PLUS",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        "/images/description_product/Warnkleidung/10.png",
        "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 100%; Wattierung: Polyester: 100%; Futter: Polyester: 100%; Polar-Fleece: Polyester: 100%",
      ],
      further_information: [
        "Oberstoff: Polyester, neonorange / marineblau",
        "Futter: Polyester, orange",
        "Größe(n): XS-4XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471, EN 343",
        "Beschreibung: NITRAS MOTION TEX VIZ PLUS, Warnschutz-Pilotenjacke, neonorange / marineblau (Farbcode: 4121), 300 g/qm, angenehmer Tragekomfort, verstellbare Ärmelabschlüsse mit Klettverschluss, weicher Fleece-Stehkragen, 4-in-1 Pilotenjacke (abnehmbare Ärmel und hochwertiges, herausnehmbares Polar-Fleece), umlaufendes 3M Scotchlite™ Reflective Material für besonders gute Sichtbarkeit, durchgängiger und praktischer 2-Wege-Reißverschluss mit Windstopper außen, je zwei Brust- und Seitentaschen (mit innenliegender Schlaufe für Ausweishüllen), Smartphone-, Ärmel-, Napoleon- und Innentaschen, abnehmbare und ungefütterte Kapuze, innenliegender Zugang am Rücken für Veredelungen, Premium-Qualität, komplette Jacke: Wassersäule: 2.000 mmH2O, Regular Fit, abnehmbare Winterkapuze optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 199,
    imgSrc: "/images/products/protectiveclothing39.jpg",
    imgHoverSrc: "/images/products/protectiveclothing40.jpg",
    title: "7140K",
    sizes: ["/"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        // "/images/description_product/Kopfbedeckung/1.png",
        // "/images/description_product/Kopfbedeckung/2.png",
        // "/images/description_product/Kopfbedeckung/3.png",
        // "/images/description_product/Kopfbedeckung/4.png",
        // "/images/description_product/Kopfbedeckung/5.png",
        // "/images/description_product/Kopfbedeckung/6.png",
        // "/images/description_product/Kopfbedeckung/7.png",
        // "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Oberstoff: Polyacryl: 100%; Futter: Polyester: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },
  {
    id: 200,
    imgSrc: "/images/products/protectiveclothing41.jpg",
    imgHoverSrc: "/images/products/protectiveclothing42.jpg",
    title: "7141 // MOTION TEX VIZ PLUS",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        "/images/description_product/Warnkleidung/10.png",
        "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 100%; Wattierung: Polyester: 100%; Futter: Polyester: 100%; Polar-Fleece: Polyester: 100%",
      ],
      further_information: [
        "Oberstoff: Polyester, neonorange / marineblau",
        "Futter: Polyester, orange",
        "Größe(n): XS-4XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471, EN 343",
        "Beschreibung: NITRAS MOTION TEX VIZ PLUS, Warnschutz-Pilotenjacke, neonorange / marineblau (Farbcode: 4121), 300 g/qm, angenehmer Tragekomfort, verstellbare Ärmelabschlüsse mit Klettverschluss, weicher Fleece-Stehkragen, 4-in-1 Pilotenjacke (abnehmbare Ärmel und hochwertiges, herausnehmbares Polar-Fleece), umlaufendes 3M Scotchlite™ Reflective Material für besonders gute Sichtbarkeit, durchgängiger und praktischer 2-Wege-Reißverschluss mit Windstopper außen, je zwei Brust- und Seitentaschen (mit innenliegender Schlaufe für Ausweishüllen), Smartphone-, Ärmel-, Napoleon- und Innentaschen, abnehmbare und ungefütterte Kapuze, innenliegender Zugang am Rücken für Veredelungen, Premium-Qualität, komplette Jacke: Wassersäule: 2.000 mmH2O, Regular Fit, abnehmbare Winterkapuze optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 201,
    imgSrc: "/images/products/protectiveclothing43.jpg",
    imgHoverSrc: "/images/products/protectiveclothing44.jpg",
    title: "7141K",
    sizes: ["/"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        // "/images/description_product/Kopfbedeckung/1.png",
        // "/images/description_product/Kopfbedeckung/2.png",
        // "/images/description_product/Kopfbedeckung/3.png",
        // "/images/description_product/Kopfbedeckung/4.png",
        // "/images/description_product/Kopfbedeckung/5.png",
        // "/images/description_product/Kopfbedeckung/6.png",
        // "/images/description_product/Kopfbedeckung/7.png",
        // "/images/description_product/Kopfbedeckung/8.png",
      ],
      product_details: ["Oberstoff: Polyacryl: 100%; Futter: Polyester: 100%"],
      further_information: [
        "Material: Polyacryl, schwarz",
        "Sonstiges: Futter aus 3M Thinsulate™",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 120 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Strickmütze, schwarz (Farbcode: 1000), wärmendes Winterfutter aus 3M® Thinsulate, Rollrand, besonders weich, angenehmer Tragekomfort, besonders elastisch, schmiegt sich an jede Kopfform an, waschbar",
      ],
    },
    isAvailable: true,
  },
  {
    id: 202,
    imgSrc: "/images/products/protectiveclothing45.jpg",
    imgHoverSrc: "/images/products/protectiveclothing46.jpg",
    title: "7142 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        "/images/description_product/Warnkleidung/10.png",
        "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 100%;Futter: Polyester: 100%;Wattierung: Polyester: 100%;EN 343;EN 343;EN ISO 13688;EN ISO 20471;EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neonorange / marineblau",
        "Futter: Polyester, orange",
        "Größe(n): XS-4XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471, EN 343",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Pilotenjacke, neonorange / marineblau (Farbcode: 4121), 300 g/qm, angenehmer Tragekomfort dank weichem Fleece-Stehkragen, verstellbare Ärmelabschlüsse mit Klettverschluss zur Fixierung, umlaufende Reflexstreifen für besonders gute Sichtbarkeit, durchgängiger Reißverschluss mit Windstopper außen, je zwei Brust- und Seitentaschen (mit innenliegender Schlaufe für Ausweishüllen), Smartphone-, Ärmel- und Innentasche, einrollbare Kapuze zum Schutz gegen Regen, innenliegender Zugang am Rücken für Veredelungen, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 203,
    imgSrc: "/images/products/protectiveclothing47.jpg",
    imgHoverSrc: "/images/products/protectiveclothing48.jpg",
    title: "7143 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        "/images/description_product/Warnkleidung/11.png",
        // "/images/description_product/Warnkleidung/12.png",
        "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        "/images/description_product/Warnkleidung/15.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 100%;Futter: Polyester: 100%;Wattierung: Polyester: 100%;EN 343;EN 343;EN ISO 13688;EN ISO 20471;EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neonorange / marineblau",
        "Futter: Polyester, orange",
        "Größe(n): XS-4XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471, EN 343",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Pilotenjacke, neonorange / marineblau (Farbcode: 4121), 300 g/qm, angenehmer Tragekomfort dank weichem Fleece-Stehkragen, verstellbare Ärmelabschlüsse mit Klettverschluss zur Fixierung, umlaufende Reflexstreifen für besonders gute Sichtbarkeit, durchgängiger Reißverschluss mit Windstopper außen, je zwei Brust- und Seitentaschen (mit innenliegender Schlaufe für Ausweishüllen), Smartphone-, Ärmel- und Innentasche, einrollbare Kapuze zum Schutz gegen Regen, innenliegender Zugang am Rücken für Veredelungen, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 204,
    imgSrc: "/images/products/protectiveclothing49.jpg",
    imgHoverSrc: "/images/products/protectiveclothing50.jpg",
    title: "7144 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        "/images/description_product/Warnkleidung/11.png",
        // "/images/description_product/Warnkleidung/12.png",
        "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        "/images/description_product/Warnkleidung/15.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 100%;Futter: Polyester: 100%;Wattierung: Polyester: 100%;EN 343;EN 343;EN ISO 13688;EN ISO 20471;EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neonorange / marineblau",
        "Futter: Polyester, orange",
        "Größe(n): XS-4XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471, EN 343",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Pilotenjacke, neonorange / marineblau (Farbcode: 4121), 300 g/qm, angenehmer Tragekomfort dank weichem Fleece-Stehkragen, verstellbare Ärmelabschlüsse mit Klettverschluss zur Fixierung, umlaufende Reflexstreifen für besonders gute Sichtbarkeit, durchgängiger Reißverschluss mit Windstopper außen, je zwei Brust- und Seitentaschen (mit innenliegender Schlaufe für Ausweishüllen), Smartphone-, Ärmel- und Innentasche, einrollbare Kapuze zum Schutz gegen Regen, innenliegender Zugang am Rücken für Veredelungen, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 205,
    imgSrc: "/images/products/protectiveclothing51.jpg",
    imgHoverSrc: "/images/products/protectiveclothing52.jpg",
    title: "7145 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        "/images/description_product/Warnkleidung/11.png",
        // "/images/description_product/Warnkleidung/12.png",
        "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        "/images/description_product/Warnkleidung/15.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 100%;Futter: Polyester: 100%;Wattierung: Polyester: 100%;EN 343;EN 343;EN ISO 13688;EN ISO 20471;EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neonorange / marineblau",
        "Futter: Polyester, orange",
        "Größe(n): XS-4XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471, EN 343",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Pilotenjacke, neonorange / marineblau (Farbcode: 4121), 300 g/qm, angenehmer Tragekomfort dank weichem Fleece-Stehkragen, verstellbare Ärmelabschlüsse mit Klettverschluss zur Fixierung, umlaufende Reflexstreifen für besonders gute Sichtbarkeit, durchgängiger Reißverschluss mit Windstopper außen, je zwei Brust- und Seitentaschen (mit innenliegender Schlaufe für Ausweishüllen), Smartphone-, Ärmel- und Innentasche, einrollbare Kapuze zum Schutz gegen Regen, innenliegender Zugang am Rücken für Veredelungen, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 206,
    imgSrc: "/images/products/protectiveclothing53.jpg",
    imgHoverSrc: "/images/products/protectiveclothing54.jpg",
    title: "7170 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        "/images/description_product/Warnkleidung/11.png",
        // "/images/description_product/Warnkleidung/12.png",
        "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        "/images/description_product/Warnkleidung/15.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 100%;Futter: Polyester: 100%;Wattierung: Polyester: 100%;EN 343;EN 343;EN ISO 13688;EN ISO 20471;EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neonorange / marineblau",
        "Futter: Polyester, orange",
        "Größe(n): XS-4XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471, EN 343",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Pilotenjacke, neonorange / marineblau (Farbcode: 4121), 300 g/qm, angenehmer Tragekomfort dank weichem Fleece-Stehkragen, verstellbare Ärmelabschlüsse mit Klettverschluss zur Fixierung, umlaufende Reflexstreifen für besonders gute Sichtbarkeit, durchgängiger Reißverschluss mit Windstopper außen, je zwei Brust- und Seitentaschen (mit innenliegender Schlaufe für Ausweishüllen), Smartphone-, Ärmel- und Innentasche, einrollbare Kapuze zum Schutz gegen Regen, innenliegender Zugang am Rücken für Veredelungen, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 207,
    imgSrc: "/images/products/protectiveclothing55.jpg",
    imgHoverSrc: "/images/products/protectiveclothing56.jpg",
    title: "7170K",
    sizes: ["/"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        "/images/description_product/Kopfbedeckung/1.png",
        "/images/description_product/Kopfbedeckung/2.png",
        "/images/description_product/Kopfbedeckung/3.png",
        "/images/description_product/Kopfbedeckung/4.png",
        "/images/description_product/Kopfbedeckung/5.png",
        "/images/description_product/Kopfbedeckung/6.png",
        "/images/description_product/Kopfbedeckung/7.png",
      ],
      product_details: ["Oberstoff: Polyester: 100%"],
      further_information: [
        "Oberstoff: Polyester, neonorange / schwarz",
        "Futter: -",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 50 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS abnehmbare Kapuze, neonorange / schwarz (Farbcode: 4110), passend zum Artikel 7170, mit Klettverschluss am Hals und Kordelzug an der Seite für besseren Halt, auffallende Neonfarbe, mit Schirm zum Schutz gegen Niederschlag, Fixierung mit Reißverschluss, Reflexbiesen für bessere Sichtbarkeit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 208,
    imgSrc: "/images/products/protectiveclothing57.jpg",
    imgHoverSrc: "/images/products/protectiveclothing58.jpg",
    title: "7171 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        "/images/description_product/Warnkleidung/11.png",
        // "/images/description_product/Warnkleidung/12.png",
        "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 100%;Futter: Polyester: 100%;Wattierung: Polyester: 100%;EN 343;EN 343;EN ISO 13688;EN ISO 20471;EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neonorange / marineblau",
        "Futter: Polyester, orange",
        "Größe(n): XS-4XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471, EN 343",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Pilotenjacke, neonorange / marineblau (Farbcode: 4121), 300 g/qm, angenehmer Tragekomfort dank weichem Fleece-Stehkragen, verstellbare Ärmelabschlüsse mit Klettverschluss zur Fixierung, umlaufende Reflexstreifen für besonders gute Sichtbarkeit, durchgängiger Reißverschluss mit Windstopper außen, je zwei Brust- und Seitentaschen (mit innenliegender Schlaufe für Ausweishüllen), Smartphone-, Ärmel- und Innentasche, einrollbare Kapuze zum Schutz gegen Regen, innenliegender Zugang am Rücken für Veredelungen, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 209,
    imgSrc: "/images/products/protectiveclothing59.jpg",
    imgHoverSrc: "/images/products/protectiveclothing60.jpg",
    title: "7171K",
    sizes: ["/"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        // "/images/description_product/Kopfbedeckung/1.png",
        // "/images/description_product/Kopfbedeckung/2.png",
        // "/images/description_product/Kopfbedeckung/3.png",
        // "/images/description_product/Kopfbedeckung/4.png",
        // "/images/description_product/Kopfbedeckung/5.png",
        // "/images/description_product/Kopfbedeckung/6.png",
        // "/images/description_product/Kopfbedeckung/7.png",
      ],
      product_details: ["Oberstoff: Polyester: 100%"],
      further_information: [
        "Oberstoff: Polyester, neonorange / schwarz",
        "Futter: -",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 50 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS abnehmbare Kapuze, neonorange / schwarz (Farbcode: 4110), passend zum Artikel 7170, mit Klettverschluss am Hals und Kordelzug an der Seite für besseren Halt, auffallende Neonfarbe, mit Schirm zum Schutz gegen Niederschlag, Fixierung mit Reißverschluss, Reflexbiesen für bessere Sichtbarkeit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 210,
    imgSrc: "/images/products/protectiveclothing61.jpg",
    imgHoverSrc: "/images/products/protectiveclothing62.jpg",
    title: "1020609 // POLYSAFE BASIC II",
    sizes: ["M", "L", "XL", "2XL", "3XL", "4XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    subcategory: "Schutzkleidung",
    brand: "Schutzoveralls",
    description: {
      images: [
        "/images/description_product/Schutzoveralls/1.png",
        "/images/description_product/Schutzoveralls/2.png",
        "/images/description_product/Schutzoveralls/3.png",
        "/images/description_product/Schutzoveralls/4.png",
        "/images/description_product/Schutzoveralls/5.png",
        "/images/description_product/Schutzoveralls/6.png",
        "/images/description_product/Schutzoveralls/7.png",
      ],
      product_details: [
        "Oberstoff: Polypropylen: 100%; EN 13034; EN 13034; EN ISO 13688; EN ISO 13982-1",
      ],
      further_information: [
        "Oberstoff: SMS, weiß",
        "Futter: -",
        "Größe(n): M-3XL",
        "Verpackungseinheit: 50 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 13982-1, EN 13034",
        "Beschreibung: NITRAS POLYSAFE BASIC II, Chemikalienschutzoverall, weiß (Farbcode: 1100), SMS, angenehmes Tragegefühl, atmungsaktives Material, Gummizug an Taille, Kapuze, Ärmel- und Beinabschlüssen für bessere Passform und festen Sitz, durchgängiger Reißverschluss mit Abdeckung, dreiteiliger Zwickel im Schritt für zusätzlichen Komfort, Typ 5, Typ 6, PSA-Risikokategorie III",
      ],
      isAvailable: true,
    },
  },
  {
    id: 211,
    imgSrc: "/images/products/protectiveclothing63.jpg",
    imgHoverSrc: "/images/products/protectiveclothing64.jpg",
    title: "1020609B // POLYSAFE BASIC II",
    sizes: ["M", "L", "XL", "2XL", "3XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    subcategory: "Schutzkleidung",
    brand: "Schutzoveralls",
    description: {
      images: [
        "/images/description_product/Schutzoveralls/1.png",
        "/images/description_product/Schutzoveralls/2.png",
        "/images/description_product/Schutzoveralls/3.png",
        "/images/description_product/Schutzoveralls/4.png",
        "/images/description_product/Schutzoveralls/5.png",
        "/images/description_product/Schutzoveralls/6.png",
        "/images/description_product/Schutzoveralls/7.png",
      ],
      product_details: [
        "Oberstoff: Polypropylen: 100%; EN 13034; EN 13034; EN ISO 13688; EN ISO 13982-1",
      ],
      further_information: [
        "Oberstoff: SMS, weiß",
        "Futter: -",
        "Größe(n): M-3XL",
        "Verpackungseinheit: 50 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 13982-1, EN 13034",
        "Beschreibung: NITRAS POLYSAFE BASIC II, Chemikalienschutzoverall, weiß (Farbcode: 1100), SMS, angenehmes Tragegefühl, atmungsaktives Material, Gummizug an Taille, Kapuze, Ärmel- und Beinabschlüssen für bessere Passform und festen Sitz, durchgängiger Reißverschluss mit Abdeckung, dreiteiliger Zwickel im Schritt für zusätzlichen Komfort, Typ 5, Typ 6, PSA-Risikokategorie III",
      ],
    },
    isAvailable: true,
  },
  {
    id: 212,
    imgSrc: "/images/products/protectiveclothing65.jpg",
    imgHoverSrc: "/images/products/protectiveclothing66.jpg",
    title: "7175 // MOTION TEX VIZ PLUS",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb / marineblau",
        "Futter: Polyester, grau",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ PLUS, Warnschutz-Winter-Softshelljacke, neongelb / marineblau (Farbcode: 4021), 340 g/qm, hoher Tragekomfort dank weichem Softshell-Material mit wasser- und windabweisenden Eigenschaften und verlängertem Rücken, verstellbare Ärmelabschlüsse mit Klettverschluss und Kordelzug im Saum für optimalen Sitz und Halt, weiches und wärmendes Winterfutter, umlaufende segmentierte Reflexstreifen für bessere Sichtbarkeit, Stehkragen, durchgehender YKK-Reißverschluss mit Kinnschutz und Windstopper innen und außen, Innen-, Brust- und Seitentaschen, Pulswärmer für kältere Temperaturen, Kapuze mit Kordelzug zur Fixierung, Comfort Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 213,
    imgSrc: "/images/products/protectiveclothing68.jpg",
    imgHoverSrc: "/images/products/protectiveclothing69.jpg",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    title: "7175 // MOTION TEX VIZ PLUS",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb / marineblau",
        "Futter: Polyester, grau",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ PLUS, Warnschutz-Winter-Softshelljacke, neongelb / marineblau (Farbcode: 4021), 340 g/qm, hoher Tragekomfort dank weichem Softshell-Material mit wasser- und windabweisenden Eigenschaften und verlängertem Rücken, verstellbare Ärmelabschlüsse mit Klettverschluss und Kordelzug im Saum für optimalen Sitz und Halt, weiches und wärmendes Winterfutter, umlaufende segmentierte Reflexstreifen für bessere Sichtbarkeit, Stehkragen, durchgehender YKK-Reißverschluss mit Kinnschutz und Windstopper innen und außen, Innen-, Brust- und Seitentaschen, Pulswärmer für kältere Temperaturen, Kapuze mit Kordelzug zur Fixierung, Comfort Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 214,
    imgSrc: "/images/products/protectiveclothing70.jpg",
    imgHoverSrc: "/images/products/protectiveclothing71.jpg",
    title: "7172 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        "/images/description_product/Warnkleidung/11.png",
        // "/images/description_product/Warnkleidung/12.png",
        "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb / marineblau",
        "Futter: Polyester, grau",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ PLUS, Warnschutz-Winter-Softshelljacke, neongelb / marineblau (Farbcode: 4021), 340 g/qm, hoher Tragekomfort dank weichem Softshell-Material mit wasser- und windabweisenden Eigenschaften und verlängertem Rücken, verstellbare Ärmelabschlüsse mit Klettverschluss und Kordelzug im Saum für optimalen Sitz und Halt, weiches und wärmendes Winterfutter, umlaufende segmentierte Reflexstreifen für bessere Sichtbarkeit, Stehkragen, durchgehender YKK-Reißverschluss mit Kinnschutz und Windstopper innen und außen, Innen-, Brust- und Seitentaschen, Pulswärmer für kältere Temperaturen, Kapuze mit Kordelzug zur Fixierung, Comfort Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 215,
    imgSrc: "/images/products/protectiveclothing72.jpg",
    imgHoverSrc: "/images/products/protectiveclothing73.jpg",
    title: "7172 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        "/images/description_product/Warnkleidung/11.png",
        // "/images/description_product/Warnkleidung/12.png",
        "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb / marineblau",
        "Futter: Polyester, schwarz",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Softshelljacke, neongelb / marineblau (Farbcode: 4021), 310 g/qm, angenehmer Tragekomfort dank weichem Softshell-Material mit wasser- und windabweisenden Eigenschaften und verlängertem Rücken, Klettverschluss und Gummizug an den Ärmelabschlüssen und Kordelzug im Saum für optimalen Sitz und Halt, sportliches Design, Stehkragen, umlaufende segmentierte Reflexstreifen für bessere Sichtbarkeit, durchgängiger YKK-Reißverschluss mit Kinnschutz und doppellagigem Windstopper innen, Innen-, Brust- und Seitentaschen, Regular Fit, abnehmbare Kapuze optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 216,
    imgSrc: "/images/products/protectiveclothing74.jpg",
    imgHoverSrc: "/images/products/protectiveclothing75.jpg",
    title: "7172K",
    sizes: ["One Size"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        // "/images/description_product/Kopfbedeckung/1.png",
        // "/images/description_product/Kopfbedeckung/2.png",
        // "/images/description_product/Kopfbedeckung/3.png",
        // "/images/description_product/Kopfbedeckung/4.png",
        // "/images/description_product/Kopfbedeckung/5.png",
        // "/images/description_product/Kopfbedeckung/6.png",
        // "/images/description_product/Kopfbedeckung/7.png",
      ],
      product_details: ["Oberstoff: Polyester: 100%"],
      further_information: [
        "Oberstoff: Polyester, neonorange / schwarz",
        "Futter: -",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 50 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS abnehmbare Kapuze, neonorange / schwarz (Farbcode: 4110), passend zum Artikel 7170, mit Klettverschluss am Hals und Kordelzug an der Seite für besseren Halt, auffallende Neonfarbe, mit Schirm zum Schutz gegen Niederschlag, Fixierung mit Reißverschluss, Reflexbiesen für bessere Sichtbarkeit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 217,
    imgSrc: "/images/products/protectiveclothing76.jpg",
    imgHoverSrc: "/images/products/protectiveclothing77.jpg",
    title: "7172K",
    sizes: ["One Size"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Arbeitskleidung",
    subcategory: "Kopfbedeckung",
    description: {
      images: [
        // "/images/description_product/Kopfbedeckung/1.png",
        // "/images/description_product/Kopfbedeckung/2.png",
        // "/images/description_product/Kopfbedeckung/3.png",
        // "/images/description_product/Kopfbedeckung/4.png",
        // "/images/description_product/Kopfbedeckung/5.png",
        // "/images/description_product/Kopfbedeckung/6.png",
        // "/images/description_product/Kopfbedeckung/7.png",
      ],
      product_details: ["Oberstoff: Polyester: 100%"],
      further_information: [
        "Oberstoff: Polyester, neonorange / schwarz",
        "Futter: -",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 50 Stück",
        "Zertifizierung: -",
        "Beschreibung: NITRAS abnehmbare Kapuze, neonorange / schwarz (Farbcode: 4110), passend zum Artikel 7170, mit Klettverschluss am Hals und Kordelzug an der Seite für besseren Halt, auffallende Neonfarbe, mit Schirm zum Schutz gegen Niederschlag, Fixierung mit Reißverschluss, Reflexbiesen für bessere Sichtbarkeit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 218,
    imgSrc: "/images/products/protectiveclothing78.jpg",
    imgHoverSrc: "/images/products/protectiveclothing79.jpg",
    title: "7573 // MOTION TEX VIZ",
    sizes: [
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "42",
      "44",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
      "90",
      "94",
      "98",
      "102",
      "106",
      "110",
    ],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        // "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        // "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb / marineblau",
        "Futter: Polyester, schwarz",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Softshelljacke, neongelb / marineblau (Farbcode: 4021), 310 g/qm, angenehmer Tragekomfort dank weichem Softshell-Material mit wasser- und windabweisenden Eigenschaften und verlängertem Rücken, Klettverschluss und Gummizug an den Ärmelabschlüssen und Kordelzug im Saum für optimalen Sitz und Halt, sportliches Design, Stehkragen, umlaufende segmentierte Reflexstreifen für bessere Sichtbarkeit, durchgängiger YKK-Reißverschluss mit Kinnschutz und doppellagigem Windstopper innen, Innen-, Brust- und Seitentaschen, Regular Fit, abnehmbare Kapuze optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 219,
    imgSrc: "/images/products/protectiveclothing80.jpg",
    imgHoverSrc: "/images/products/protectiveclothing81.jpg",
    title: "7573 // MOTION TEX VIZ",
    sizes: [
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "42",
      "44",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
      "90",
      "94",
      "98",
      "102",
      "106",
      "110",
    ],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        // "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        // "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb / marineblau",
        "Futter: Polyester, schwarz",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Softshelljacke, neongelb / marineblau (Farbcode: 4021), 310 g/qm, angenehmer Tragekomfort dank weichem Softshell-Material mit wasser- und windabweisenden Eigenschaften und verlängertem Rücken, Klettverschluss und Gummizug an den Ärmelabschlüssen und Kordelzug im Saum für optimalen Sitz und Halt, sportliches Design, Stehkragen, umlaufende segmentierte Reflexstreifen für bessere Sichtbarkeit, durchgängiger YKK-Reißverschluss mit Kinnschutz und doppellagigem Windstopper innen, Innen-, Brust- und Seitentaschen, Regular Fit, abnehmbare Kapuze optional erhältlich",
      ],
    },
    isAvailable: true,
  },
  {
    id: 220,
    imgSrc: "/images/products/protectiveclothing82.jpg",
    imgHoverSrc: "/images/products/protectiveclothing83.jpg",
    title: "7572 // MOTION TEX VIZ",
    sizes: [
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "42",
      "44",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
      "90",
      "94",
      "98",
      "102",
      "106",
      "110",
    ],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        // "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        // "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Baumwolle, Polyester",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Latzhose, 280 g/qm, atmungsaktives und leichtes Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen an Bein-, Knie-, Gesäßtaschen und Latz-Außentaschen, umlaufende Reflexstreifen und Reflexbiesen an den Seiten- und Knietaschen für besonders gute Sichtbarkeit, praktische und funktionale Details: Schenkeltasche mit Patte, Zollstocktasche mit aufgesetzter Tasche, Knietaschen für Polster mit Einschub von unten, Hammerschlaufe, stabile Clipverschlüsse, elastische Trägerabschlüsse, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 221,
    imgSrc: "/images/products/protectiveclothing84.jpg",
    imgHoverSrc: "/images/products/protectiveclothing85.jpg",
    title: "7572 // MOTION TEX VIZ",
    sizes: [
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "42",
      "44",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
      "90",
      "94",
      "98",
      "102",
      "106",
      "110",
    ],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        // "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        // "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Baumwolle, Polyester",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Latzhose, 280 g/qm, atmungsaktives und leichtes Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen an Bein-, Knie-, Gesäßtaschen und Latz-Außentaschen, umlaufende Reflexstreifen und Reflexbiesen an den Seiten- und Knietaschen für besonders gute Sichtbarkeit, praktische und funktionale Details: Schenkeltasche mit Patte, Zollstocktasche mit aufgesetzter Tasche, Knietaschen für Polster mit Einschub von unten, Hammerschlaufe, stabile Clipverschlüsse, elastische Trägerabschlüsse, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 222,
    imgSrc: "/images/products/protectiveclothing86.jpg",
    imgHoverSrc: "/images/products/protectiveclothing87.jpg",
    title: "7571 // MOTION TEX VIZ",
    sizes: [
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "42",
      "44",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
      "90",
      "94",
      "98",
      "102",
      "106",
      "110",
    ],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        // "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        // "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Baumwolle, Polyester",
        "Futter: -",
        "Größe(n): 24-29, 42-68, 90-110",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, lange Warnschutz-Arbeitshose, 280 g/qm, atmungsaktives und leichtes Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen an rückseitiger Gürtelschlaufe, Knie-, Gesäß- und Beintaschen, umlaufende Reflexstreifen und Reflexbiesen an den Seiten- und Knietaschen für besonders gute Sichtbarkeit, praktische und funktionale Details: Schenkeltasche mit Patte, Zollstocktasche mit aufgesetzter Tasche, Knietaschen für Polster mit Einschub von unten, Hammerschlaufe, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 223,
    imgSrc: "/images/products/protectiveclothing90.jpg",
    imgHoverSrc: "/images/products/protectiveclothing91.jpg",
    title: "7570 // MOTION TEX VIZ",
    sizes: [
      "42",
      "44",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
    ],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        // "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        // "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Baumwolle, Polyester",
        "Futter: -",
        "Größe(n): 42-68",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, kurze Warnschutz-Arbeitshose, 280 g/qm, atmungsaktives und leichtes Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen an rückseitiger Gürtelschlaufe und Gesäßtaschen sowie Beintaschen, umlaufende Reflexstreifen und Reflexbiesen an den Seitentaschen für besonders gute Sichtbarkeit, praktische und funktionale Details: Schenkeltasche mit Patte, Zollstocktasche mit aufgesetzter Tasche, Hammerschlaufe, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 224,
    imgSrc: "/images/products/protectiveclothing92.jpg",
    imgHoverSrc: "/images/products/protectiveclothing93.jpg",
    title: "7570 // MOTION TEX VIZ",
    sizes: [
      "42",
      "44",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
    ],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        // "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        // "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Baumwolle, Polyester",
        "Futter: -",
        "Größe(n): 42-68",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, kurze Warnschutz-Arbeitshose, 280 g/qm, atmungsaktives und leichtes Material, hoher Tragekomfort, dehnbarer Bund, Verstärkungen an rückseitiger Gürtelschlaufe und Gesäßtaschen sowie Beintaschen, umlaufende Reflexstreifen und Reflexbiesen an den Seitentaschen für besonders gute Sichtbarkeit, praktische und funktionale Details: Schenkeltasche mit Patte, Zollstocktasche mit aufgesetzter Tasche, Hammerschlaufe, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 225,
    imgSrc: "/images/products/protectiveclothing94.jpg",
    imgHoverSrc: "/images/products/protectiveclothing95.jpg",
    title: "7196 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        // "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb",
        "Futter: Polyester, neongelb",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Windbreaker, neongelb (Farbcode: 4000), 180 g/qm, besonders leicht und angenehmer Tragekomfort dank weichem Softshell-Material mit wasser- und windabweisenden Eigenschaften, Gummizug an den Ärmelabschlüssen für optimalen Sitz, dezentes und sportliches Design, angenehmes Innenfutter (Vorderseite), kleines Packmaß und besonders schnell zur Hand, umlaufende segmentierte Reflexstreifen für bessere Sichtbarkeit, durchgehender YKK-Reißverschluss mit Kinnschutz und Windstopper innen, Brust- und Seitentaschen, Kapuze mit Kordelzug zur optimalen Fixierung, Oberstoff: Wassersäule: 25.000 mmH2O / Atmungsaktivität: 2.000 g/qm/24h, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 226,
    imgSrc: "/images/products/protectiveclothing96.jpg",
    imgHoverSrc: "/images/products/protectiveclothing97.jpg",
    title: "7196 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        // "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb",
        "Futter: Polyester, neongelb",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Windbreaker, neongelb (Farbcode: 4000), 180 g/qm, besonders leicht und angenehmer Tragekomfort dank weichem Softshell-Material mit wasser- und windabweisenden Eigenschaften, Gummizug an den Ärmelabschlüssen für optimalen Sitz, dezentes und sportliches Design, angenehmes Innenfutter (Vorderseite), kleines Packmaß und besonders schnell zur Hand, umlaufende segmentierte Reflexstreifen für bessere Sichtbarkeit, durchgehender YKK-Reißverschluss mit Kinnschutz und Windstopper innen, Brust- und Seitentaschen, Kapuze mit Kordelzug zur optimalen Fixierung, Oberstoff: Wassersäule: 25.000 mmH2O / Atmungsaktivität: 2.000 g/qm/24h, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 227,
    imgSrc: "/images/products/protectiveclothing98.jpg",
    imgHoverSrc: "/images/products/protectiveclothing99.jpg",
    title: "7177 // MOTION TEX VIZ PLUS",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        // "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb",
        "Futter: -",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471, EN 343",
        "Beschreibung: NITRAS MOTION TEX VIZ PLUS, Warnschutz-Regenjacke, neongelb (Farbcode: 4000), 190 g/qm, angenehmer Tragekomfort dank verlängertem Rücken, windabweisende und wasserdichte Eigenschaften dank verschweißter Nähte, verstellbare Ärmelabschlüsse mit Klettverschluss und Kordelzug im Saum für optimalen Sitz und Halt, umlaufende segmentierte Reflexstreifen für bessere Sichtbarkeit, durchgängiger YKK-Reißverschluss mit Kinnschutz und Windstopper innen und außen, Innen-, Brust- und Seitentaschen, Unterarmbelüftung mit Reißverschluss für ein optimales Körperklima, Kapuze mit Kordelzug zur Fixierung, komplette Jacke: Wassersäule: 10.000 mmH2O, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 228,
    imgSrc: "/images/products/protectiveclothing100.jpg",
    imgHoverSrc: "/images/products/protectiveclothing101.jpg",
    title: "7196 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        // "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb",
        "Futter: -",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471, EN 343",
        "Beschreibung: NITRAS MOTION TEX VIZ PLUS, Warnschutz-Regenjacke, neongelb (Farbcode: 4000), 190 g/qm, angenehmer Tragekomfort dank verlängertem Rücken, windabweisende und wasserdichte Eigenschaften dank verschweißter Nähte, verstellbare Ärmelabschlüsse mit Klettverschluss und Kordelzug im Saum für optimalen Sitz und Halt, umlaufende segmentierte Reflexstreifen für bessere Sichtbarkeit, durchgängiger YKK-Reißverschluss mit Kinnschutz und Windstopper innen und außen, Innen-, Brust- und Seitentaschen, Unterarmbelüftung mit Reißverschluss für ein optimales Körperklima, Kapuze mit Kordelzug zur Fixierung, komplette Jacke: Wassersäule: 10.000 mmH2O, Regular fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 229,
    imgSrc: "/images/products/protectiveclothing104.jpg",
    imgHoverSrc: "/images/products/protectiveclothing105.jpg",
    title: "7196 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb",
        "Futter: Polyester, neongelb",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Windbreaker, neongelb (Farbcode: 4000), 180 g/qm, besonders leicht und angenehmer Tragekomfort dank weichem Softshell-Material mit wasser- und windabweisenden Eigenschaften, Gummizug an den Ärmelabschlüssen für optimalen Sitz, dezentes und sportliches Design, angenehmes Innenfutter (Vorderseite), kleines Packmaß und besonders schnell zur Hand, umlaufende segmentierte Reflexstreifen für bessere Sichtbarkeit, durchgehender YKK-Reißverschluss mit Kinnschutz und Windstopper innen, Brust- und Seitentaschen, Kapuze mit Kordelzug zur optimalen Fixierung, Oberstoff: Wassersäule: 25.000 mmH2O / Atmungsaktivität: 2.000 g/qm/24h, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 230,
    imgSrc: "/images/products/protectiveclothing106.jpg",
    imgHoverSrc: "/images/products/protectiveclothing107.jpg",
    title: "7177 // MOTION TEX VIZ PLUS",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb",
        "Futter: Polyester, neongelb",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Windbreaker, neongelb (Farbcode: 4000), 180 g/qm, besonders leicht und angenehmer Tragekomfort dank weichem Softshell-Material mit wasser- und windabweisenden Eigenschaften, Gummizug an den Ärmelabschlüssen für optimalen Sitz, dezentes und sportliches Design, angenehmes Innenfutter (Vorderseite), kleines Packmaß und besonders schnell zur Hand, umlaufende segmentierte Reflexstreifen für bessere Sichtbarkeit, durchgehender YKK-Reißverschluss mit Kinnschutz und Windstopper innen, Brust- und Seitentaschen, Kapuze mit Kordelzug zur optimalen Fixierung, Oberstoff: Wassersäule: 25.000 mmH2O / Atmungsaktivität: 2.000 g/qm/24h, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 231,
    imgSrc: "/images/products/protectiveclothing108.jpg",
    imgHoverSrc: "/images/products/protectiveclothing109.jpg",
    title: "7177 // MOTION TEX VIZ PLUS",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb",
        "Futter: Polyester, neongelb",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471",
        "Beschreibung: NITRAS MOTION TEX VIZ, Warnschutz-Windbreaker, neongelb (Farbcode: 4000), 180 g/qm, besonders leicht und angenehmer Tragekomfort dank weichem Softshell-Material mit wasser- und windabweisenden Eigenschaften, Gummizug an den Ärmelabschlüssen für optimalen Sitz, dezentes und sportliches Design, angenehmes Innenfutter (Vorderseite), kleines Packmaß und besonders schnell zur Hand, umlaufende segmentierte Reflexstreifen für bessere Sichtbarkeit, durchgehender YKK-Reißverschluss mit Kinnschutz und Windstopper innen, Brust- und Seitentaschen, Kapuze mit Kordelzug zur optimalen Fixierung, Oberstoff: Wassersäule: 25.000 mmH2O / Atmungsaktivität: 2.000 g/qm/24h, Regular Fit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 232,
    imgSrc: "/images/products/protectiveclothing110.jpg",
    imgHoverSrc: "/images/products/protectiveclothing111.jpg",
    title: "7179 // MOTION TEX VIZ PLUS",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb / marineblau",
        "Futter: Polyester, neongelb",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471, EN 343, EN 342",
        "Beschreibung: NITRAS MOTION TEX VIZ PLUS, Warn-Multifunktionsjacke, neongelb / marineblau (Farbcode: 4021), fünf Tragemöglichkeiten, Jacke außen: Warn-Parka, 230 g/qm, Fleece-Stehkragen, windabweisend, wasserdicht, verschweißte Nähte, verstellbare Ärmelabschlüsse mit Klettverschluss, Gummizug im Saum für optimalen Sitz, integrierte Kapuze, segmentierte Reflexstreifen, durchgängiger YKK-Reißverschluss mit Windstopper außen, Innen-, Brust- und Seitentaschen, Jacke innen: Außenseite Warn-Softshelljacke, 140 g/qm, windabweisend, wasserdicht, verschweißte Nähte, weiches Softshell-Material, neongelb, segmentierte Reflexstreifen, durchgängiger YKK-Reißverschluss, Seitentaschen, Innenseite Steppjacke, marineblau, zwei Seitentaschen, besonders wärmend, abnehmbare Ärmel, für jede Wetterbedingung, komplette Jacke: Wassersäule: 10.000 mmH2O, Comfort fit, PSA-Risikokategorie II",
      ],
    },
    isAvailable: true,
  },
  {
    id: 233,
    imgSrc: "/images/products/protectiveclothing112.jpg",
    imgHoverSrc: "/images/products/protectiveclothing113.jpg",
    title: "7179 // MOTION TEX VIZ PLUS",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
      ],
      product_details: [
        "Futter: Polyester: 100%; Wattierung: Polyester: 100%; Oberstoff: Polyester: 100%; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb / marineblau",
        "Futter: Polyester, neongelb",
        "Größe(n): XS-5XL",
        "Verpackungseinheit: 10 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471, EN 343, EN 342",
        "Beschreibung: NITRAS MOTION TEX VIZ PLUS, Warn-Multifunktionsjacke, neongelb / marineblau (Farbcode: 4021), fünf Tragemöglichkeiten, Jacke außen: Warn-Parka, 230 g/qm, Fleece-Stehkragen, windabweisend, wasserdicht, verschweißte Nähte, verstellbare Ärmelabschlüsse mit Klettverschluss, Gummizug im Saum für optimalen Sitz, integrierte Kapuze, segmentierte Reflexstreifen, durchgängiger YKK-Reißverschluss mit Windstopper außen, Innen-, Brust- und Seitentaschen, Jacke innen: Außenseite Warn-Softshelljacke, 140 g/qm, windabweisend, wasserdicht, verschweißte Nähte, weiches Softshell-Material, neongelb, segmentierte Reflexstreifen, durchgängiger YKK-Reißverschluss, Seitentaschen, Innenseite Steppjacke, marineblau, zwei Seitentaschen, besonders wärmend, abnehmbare Ärmel, für jede Wetterbedingung, komplette Jacke: Wassersäule: 10.000 mmH2O, Comfort fit, PSA-Risikokategorie II",
      ],
    },
    isAvailable: true,
  },
  {
    id: 234,
    imgSrc: "/images/products/protectiveclothing114.jpg",
    imgHoverSrc: "/images/products/protectiveclothing115.jpg",
    title: "7118",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        // "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
        "/images/description_product/Warnkleidung/17.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 100%; EN 1149; EN 1149; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb",
        "Futter: -",
        "Größe(n): S-4XL",
        "Verpackungseinheit: 50 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471, EN 1149",
        "Beschreibung: NITRAS Warnschutz-Weste, neongelb (Farbcode: 4000), 120 g/qm, mit Carbon-Faden, zwei umlaufende Reflexstreifen, mit Schulterreflexstreifen für besonders gute Sichtbarkeit, mit Reißverschluss, D-Ring, zwei Seitentaschen, Smartphone-, Stift- und ID-Tasche auf der Brust, praktische Schlaufe für z. B. Mobiltelefone oder Taschenlampen",
      ],
    },
    isAvailable: true,
  },
  {
    id: 235,
    imgSrc: "/images/products/protectiveclothing116.jpg",
    imgHoverSrc: "/images/products/protectiveclothing117.jpg",
    title: "7118",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        // "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
        "/images/description_product/Warnkleidung/17.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 100%; EN 1149; EN 1149; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb",
        "Futter: -",
        "Größe(n): S-4XL",
        "Verpackungseinheit: 50 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471, EN 1149",
        "Beschreibung: NITRAS Warnschutz-Weste, neongelb (Farbcode: 4000), 120 g/qm, mit Carbon-Faden, zwei umlaufende Reflexstreifen, mit Schulterreflexstreifen für besonders gute Sichtbarkeit, mit Reißverschluss, D-Ring, zwei Seitentaschen, Smartphone-, Stift- und ID-Tasche auf der Brust, praktische Schlaufe für z. B. Mobiltelefone oder Taschenlampen",
      ],
    },
    isAvailable: true,
  },
  {
    id: 236,
    imgSrc: "/images/products/protectiveclothing118.jpg",
    imgHoverSrc: "/images/products/protectiveclothing119.jpg",
    title: "7118",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        // "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
        "/images/description_product/Warnkleidung/17.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 100%; EN 1149; EN 1149; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb",
        "Futter: -",
        "Größe(n): S-4XL",
        "Verpackungseinheit: 50 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471, EN 1149",
        "Beschreibung: NITRAS Warnschutz-Weste, neongelb (Farbcode: 4000), 120 g/qm, mit Carbon-Faden, zwei umlaufende Reflexstreifen, mit Schulterreflexstreifen für besonders gute Sichtbarkeit, mit Reißverschluss, D-Ring, zwei Seitentaschen, Smartphone-, Stift- und ID-Tasche auf der Brust, praktische Schlaufe für z. B. Mobiltelefone oder Taschenlampen",
      ],
    },
    isAvailable: true,
  },
  {
    id: 237,
    imgSrc: "/images/products/protectiveclothing120.jpg",
    imgHoverSrc: "/images/products/protectiveclothing121.jpg",
    title: "7119",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        // "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
        "/images/description_product/Warnkleidung/17.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 100%; EN 1149; EN 1149; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb",
        "Futter: -",
        "Größe(n): S-4XL",
        "Verpackungseinheit: 50 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471, EN 1149",
        "Beschreibung: NITRAS Warnschutz-Weste, neongelb (Farbcode: 4000), 120 g/qm, mit Carbon-Faden, zwei umlaufende Reflexstreifen, mit Schulterreflexstreifen für besonders gute Sichtbarkeit, mit Reißverschluss, D-Ring, zwei Seitentaschen, Smartphone-, Stift- und ID-Tasche auf der Brust, praktische Schlaufe für z. B. Mobiltelefone oder Taschenlampen",
      ],
    },
    isAvailable: true,
  },
  {
    id: 238,
    imgSrc: "/images/products/protectiveclothing122.jpg",
    imgHoverSrc: "/images/products/protectiveclothing123.jpg",
    title: "7119",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        // "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
        "/images/description_product/Warnkleidung/17.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 100%; EN 1149; EN 1149; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb",
        "Futter: -",
        "Größe(n): S-4XL",
        "Verpackungseinheit: 50 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471, EN 1149",
        "Beschreibung: NITRAS Warnschutz-Weste, neongelb (Farbcode: 4000), 120 g/qm, mit Carbon-Faden, zwei umlaufende Reflexstreifen, mit Schulterreflexstreifen für besonders gute Sichtbarkeit, mit Reißverschluss, D-Ring, zwei Seitentaschen, Smartphone-, Stift- und ID-Tasche auf der Brust, praktische Schlaufe für z. B. Mobiltelefone oder Taschenlampen",
      ],
    },
    isAvailable: true,
  },
  {
    id: 239,
    imgSrc: "/images/products/protectiveclothing124.jpg",
    imgHoverSrc: "/images/products/protectiveclothing125.jpg",
    title: "7119",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Schutzkleidung",
    subcategory: "Warnkleidung",
    description: {
      images: [
        // "/images/description_product/Warnkleidung/1.png",
        // "/images/description_product/Warnkleidung/2.png",
        // "/images/description_product/Warnkleidung/3.png",
        "/images/description_product/Warnkleidung/4.png",
        "/images/description_product/Warnkleidung/5.png",
        "/images/description_product/Warnkleidung/6.png",
        "/images/description_product/Warnkleidung/7.png",
        "/images/description_product/Warnkleidung/8.png",
        "/images/description_product/Warnkleidung/9.png",
        // "/images/description_product/Warnkleidung/10.png",
        // "/images/description_product/Warnkleidung/11.png",
        "/images/description_product/Warnkleidung/12.png",
        // "/images/description_product/Warnkleidung/13.png",
        "/images/description_product/Warnkleidung/14.png",
        // "/images/description_product/Warnkleidung/15.png",
        // "/images/description_product/Warnkleidung/16.png",
        "/images/description_product/Warnkleidung/17.png",
      ],
      product_details: [
        "Oberstoff: Polyester: 100%; EN 1149; EN 1149; EN ISO 13688; EN ISO 20471",
      ],
      further_information: [
        "Oberstoff: Polyester, neongelb",
        "Futter: -",
        "Größe(n): S-4XL",
        "Verpackungseinheit: 50 Stück",
        "Zertifizierung: EN ISO 13688, EN ISO 20471, EN 1149",
        "Beschreibung: NITRAS Warnschutz-Weste, neongelb (Farbcode: 4000), 120 g/qm, mit Carbon-Faden, zwei umlaufende Reflexstreifen, mit Schulterreflexstreifen für besonders gute Sichtbarkeit, mit Reißverschluss, D-Ring, zwei Seitentaschen, Smartphone-, Stift- und ID-Tasche auf der Brust, praktische Schlaufe für z. B. Mobiltelefone oder Taschenlampen",
      ],
    },
    isAvailable: true,
  },
];

// * ================== Hand / arm protection ==================

export const handarmprotection = [
  {
    id: 240,
    imgSrc: "/images/products/handarmprotection1.jpg",
    imgHoverSrc: "/images/products/handarmprotection1.jpg",
    title: "03400",
    sizes: ["6", "7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Armschutz",
    subcategory: "Nitrilhandschuhe",
    description: {
      images: [
        "/images/description_product/Nitrilhandschuhe/1.png",
        "/images/description_product/Nitrilhandschuhe/2.png",
      ],
      product_details: ["EN 388; EN 388; 4111X; EN 420"],
      further_information: [
        "Material: Baumwoll-Trikot, naturfarben",
        "Beschichtung: Nitril, gelb",
        "Größe(n): 6-11",
        "Verpackungseinheit: 144 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Nitrilhandschuhe, Baumwoll-Trikot, naturfarben, Nitril-Beschichtung, gelb (Farbcode: 1643), 3/4-beschichtet, Strickbund",
      ],
    },
    isAvailable: true,
  },
  {
    id: 241,
    imgSrc: "/images/products/handarmprotection2.jpg",
    imgHoverSrc: "/images/products/handarmprotection2.jpg",
    title: "03400V",
    sizes: ["7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Armschutz",
    subcategory: "Nitrilhandschuhe",
    description: {
      images: [
        "/images/description_product/Nitrilhandschuhe/1.png",
        "/images/description_product/Nitrilhandschuhe/2.png",
      ],
      product_details: ["EN 388; EN 388; 4111X; EN 420"],
      further_information: [
        "Material: Baumwoll-Trikot, naturfarben",
        "Beschichtung: Nitril, gelb",
        "Größe(n): 7-11",
        "Verpackungseinheit: 144 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Nitrilhandschuhe, Baumwoll-Trikot, naturfarben, Nitril-Beschichtung, gelb (Farbcode: 4300), vollbeschichtet, Strickbund",
      ],
    },
    isAvailable: true,
  },
  {
    id: 242,
    imgSrc: "/images/products/handarmprotection3.jpg",
    imgHoverSrc: "/images/products/handarmprotection3.jpg",
    title: "03405",
    sizes: ["7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Armschutz",
    subcategory: "Nitrilhandschuhe",
    description: {
      images: [
        "/images/description_product/Nitrilhandschuhe/1.png",
        "/images/description_product/Nitrilhandschuhe/2.png",
        "/images/description_product/Nitrilhandschuhe/3.png",
      ],
      product_details: ["EN 388, EN 388, 3121X, EN ISO 21420"],
      further_information: [
        "Material: Polyester, weiß",
        "Beschichtung: Nitril, gelb",
        "Größe(n): 7-11",
        "Verpackungseinheit: 144 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Nitrilhandschuhe, Polyester, weiß, Nitril-Beschichtung, gelb (Farbcode: 4311), 3/4-beschichtet, Strickbund",
      ],
    },
    isAvailable: true,
  },
  {
    id: 243,
    imgSrc: "/images/products/handarmprotection4.jpg",
    imgHoverSrc: "/images/products/handarmprotection4.jpg",
    title: "03410",
    sizes: ["8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Armschutz",
    subcategory: "Nitrilhandschuhe",
    description: {
      images: [
        "/images/description_product/Nitrilhandschuhe/1.png",
        "/images/description_product/Nitrilhandschuhe/2.png",
        // "/images/description_product/Nitrilhandschuhe/3.png",
      ],
      product_details: ["EN 388, EN 388, 4221X, EN ISO 21420"],
      further_information: [
        "Material: Baumwoll-Jersey, naturfarben",
        "Beschichtung: Nitril, blau",
        "Größe(n): 8-11",
        "Verpackungseinheit: 144 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Nitrilhandschuhe, Baumwoll-Jersey, naturfarben, Nitril-Beschichtung, blau (Farbcode: 2216), 3/4-beschichtet, Strickbund",
      ],
    },
    isAvailable: true,
  },
  {
    id: 244,
    imgSrc: "/images/products/handarmprotection5.jpg",
    imgHoverSrc: "/images/products/handarmprotection5.jpg",
    title: "03420",
    sizes: ["8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Armschutz",
    subcategory: "Nitrilhandschuhe",
    description: {
      images: [
        "/images/description_product/Nitrilhandschuhe/1.png",
        "/images/description_product/Nitrilhandschuhe/2.png",
        // "/images/description_product/Nitrilhandschuhe/3.png",
      ],
      product_details: ["EN 388, EN 388, 4221X, EN ISO 21420"],
      further_information: [
        "Material: Baumwoll-Jersey, naturfarben",
        "Beschichtung: Nitril, blau",
        "Größe(n): 8-11",
        "Verpackungseinheit: 144 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Nitrilhandschuhe, Baumwoll-Jersey, naturfarben, Nitril-Beschichtung, blau (Farbcode: 2200), vollbeschichtet, Strickbund",
      ],
    },
    isAvailable: true,
  },
  {
    id: 245,
    imgSrc: "/images/products/handarmprotection6.jpg",
    imgHoverSrc: "/images/products/handarmprotection6.jpg",
    title: "03430",
    sizes: ["8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Armschutz",
    subcategory: "Nitrilhandschuhe",
    description: {
      images: [
        "/images/description_product/Nitrilhandschuhe/1.png",
        "/images/description_product/Nitrilhandschuhe/2.png",
        // "/images/description_product/Nitrilhandschuhe/3.png",
      ],
      product_details: ["EN 388, EN 388, 4221X, EN ISO 21420"],
      further_information: [
        "Material: Baumwoll-Jersey, naturfarben",
        "Beschichtung: Nitril, blau",
        "Größe(n): 8-11",
        "Verpackungseinheit: 144 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Nitrilhandschuhe, Baumwoll-Jersey, naturfarben, Nitril-Beschichtung, blau (Farbcode: 2216), 3/4-beschichtet, Segeltuchstulpe",
      ],
    },
    isAvailable: true,
  },
  {
    id: 246,
    imgSrc: "/images/products/handarmprotection8.jpg",
    imgHoverSrc: "/images/products/handarmprotection8.jpg",
    title: "03435",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Armschutz",
    subcategory: "Nitrilhandschuhe",
    description: {
      images: [
        "/images/description_product/Nitrilhandschuhe/1.png",
        "/images/description_product/Nitrilhandschuhe/2.png",
        // "/images/description_product/Nitrilhandschuhe/3.png",
      ],
      product_details: ["EN 388, EN 388, 4221X, EN ISO 21420"],
      further_information: [
        "Material: Baumwoll-Jersey, naturfarben",
        "Beschichtung: Nitril, blau",
        "Größe(n): 10",
        "Verpackungseinheit: 144 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Nitrilhandschuhe, Baumwoll-Jersey, naturfarben, Nitril-Beschichtung, blau (Farbcode: 2216), 3/4-beschichtet, Segeltuchstulpe, Innenhand mit Baumwoll-Schlingengewebe",
      ],
    },
    isAvailable: true,
  },
  {
    id: 247,
    imgSrc: "/images/products/handarmprotection9.jpg",
    imgHoverSrc: "/images/products/handarmprotection9.jpg",
    title: "03440",
    sizes: ["8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Armschutz",
    subcategory: "Nitrilhandschuhe",
    description: {
      images: [
        "/images/description_product/Nitrilhandschuhe/1.png",
        "/images/description_product/Nitrilhandschuhe/2.png",
        // "/images/description_product/Nitrilhandschuhe/3.png",
      ],
      product_details: ["EN 388, EN 388, 4221X, EN ISO 21420"],
      further_information: [
        "Material: Baumwoll-Jersey, naturfarben",
        "Beschichtung: Nitril, blau",
        "Größe(n): 8-11",
        "Verpackungseinheit: 144 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Nitrilhandschuhe, Baumwoll-Jersey, naturfarben, Nitril-Beschichtung, blau (Farbcode: 2200), vollbeschichtet, Segeltuchstulpe",
      ],
    },
    isAvailable: true,
  },
  {
    id: 248,
    imgSrc: "/images/products/handarmprotection10.jpg",
    imgHoverSrc: "/images/products/handarmprotection10.jpg",
    title: "1301C // SPLIT STAR 1",
    sizes: ["8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Nitrilhandschuhe/3.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, naturfarben | Rindspaltleder, grau",
        "Beschichtung: -",
        "Größe(n): 10",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS SPLIT STAR 1, Rindspaltlederhandschuhe, Baumwoll-Rücken, naturfarben, Rindspaltleder, grau (Farbcode: 1612), Innenhandfutter, Segeltuchstulpe, Doppelnähte",
      ],
    },
    isAvailable: true,
  },
  {
    id: 249,
    imgSrc: "/images/products/handarmprotection10.jpg",
    imgHoverSrc: "/images/products/handarmprotection10.jpg",
    title: "1301C // SPLIT STAR 1",
    sizes: ["8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Nitrilhandschuhe/3.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, naturfarben | Rindspaltleder, grau",
        "Beschichtung: -",
        "Größe(n): 10",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS SPLIT STAR 1, Rindspaltlederhandschuhe, Baumwoll-Rücken, naturfarben, Rindspaltleder, grau (Farbcode: 1612), Innenhandfutter, Segeltuchstulpe, Doppelnähte",
      ],
    },
    isAvailable: true,
  },
  {
    id: 250,
    imgSrc: "/images/products/handarmprotection11.jpg",
    imgHoverSrc: "/images/products/handarmprotection11.jpg",
    title: "1302 // SPLIT MASTER",
    sizes: ["8", "9", "10", "11", "12"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Nitrilhandschuhe/3.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, naturfarben | Rindspaltleder, grau",
        "Beschichtung: -",
        "Größe(n): 10",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS SPLIT STAR 1, Rindspaltlederhandschuhe, Baumwoll-Rücken, naturfarben, Rindspaltleder, grau (Farbcode: 1612), Innenhandfutter, Segeltuchstulpe, Doppelnähte",
      ],
    },
    isAvailable: true,
  },
  {
    id: 251,
    imgSrc: "/images/products/handarmprotection12.jpg",
    imgHoverSrc: "/images/products/handarmprotection12.jpg",
    title: "1302W // SPLIT STAR 2",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Nitrilhandschuhe/3.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, naturfarben | Rindspaltleder, grau",
        "Beschichtung: -",
        "Größe(n): 10",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS SPLIT STAR 2, Rindspaltlederhandschuhe, Baumwolldrell-Rücken, naturfarben, Rindspaltleder, grau (Farbcode: 1612), Innenhandfutter, Segeltuchstulpe, Doppelnähte, Stärke ca. 1,2 mm",
      ],
    },
    isAvailable: true,
  },
  {
    id: 252,
    imgSrc: "/images/products/handarmprotection13.jpg",
    imgHoverSrc: "/images/products/handarmprotection13.jpg",
    title: "1304 // SPLIT STAR+",
    sizes: ["11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        "/images/description_product/Lederhandschuhe/3.png",
        "/images/description_product/Lederhandschuhe/4.png",
        "/images/description_product/Lederhandschuhe/5.png",
        "/images/description_product/Lederhandschuhe/6.png",
        "/images/description_product/Lederhandschuhe/7.png",
        "/images/description_product/Lederhandschuhe/8.png",
        "/images/description_product/Lederhandschuhe/9.png",
        "/images/description_product/Lederhandschuhe/10.png",
        "/images/description_product/Lederhandschuhe/11.png",
        "/images/description_product/Lederhandschuhe/12.png",
        "/images/description_product/Lederhandschuhe/13.png",
        "/images/description_product/Lederhandschuhe/14.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, naturfarben | Rindspaltleder, grau",
        "Beschichtung: -",
        "Größe(n): 11",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS SPLIT STAR+, Rindspaltlederhandschuhe, Baumwolldrell-Rücken, naturfarben, Rindspaltleder, grau (Farbcode: 1612), Innenhandfutter, Segeltuchstulpe, Doppelnähte, Stärke ca. 1,3 mm",
      ],
    },
    isAvailable: true,
  },
  {
    id: 253,
    imgSrc: "/images/products/handarmprotection14.jpg",
    imgHoverSrc: "/images/products/handarmprotection14.jpg",
    title: "1302W // SPLIT STAR 2",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Lederhandschuhe/3.png",
        // "/images/description_product/Lederhandschuhe/4.png",
        // "/images/description_product/Lederhandschuhe/5.png",
        // "/images/description_product/Lederhandschuhe/6.png",
        // "/images/description_product/Lederhandschuhe/7.png",
        // "/images/description_product/Lederhandschuhe/8.png",
        // "/images/description_product/Lederhandschuhe/9.png",
        // "/images/description_product/Lederhandschuhe/10.png",
        // "/images/description_product/Lederhandschuhe/11.png",
        // "/images/description_product/Lederhandschuhe/12.png",
        // "/images/description_product/Lederhandschuhe/13.png",
        // "/images/description_product/Lederhandschuhe/14.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, naturfarben | Rindspaltleder, grau",
        "Beschichtung: -",
        "Größe(n): 10",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS SPLIT STAR 2, Rindspaltlederhandschuhe, Baumwolldrell-Rücken, naturfarben, Rindspaltleder, grau (Farbcode: 1612), Innenhandfutter, Segeltuchstulpe, Doppelnähte, Stärke ca. 1,2 mm",
      ],
    },
    isAvailable: true,
  },
  {
    id: 254,
    imgSrc: "/images/products/handarmprotection15.jpg",
    imgHoverSrc: "/images/products/handarmprotection15.jpg",
    title: "1304 // SPLIT STAR+",
    sizes: ["11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        "/images/description_product/Lederhandschuhe/3.png",
        "/images/description_product/Lederhandschuhe/4.png",
        "/images/description_product/Lederhandschuhe/5.png",
        "/images/description_product/Lederhandschuhe/6.png",
        "/images/description_product/Lederhandschuhe/7.png",
        "/images/description_product/Lederhandschuhe/8.png",
        "/images/description_product/Lederhandschuhe/9.png",
        "/images/description_product/Lederhandschuhe/10.png",
        "/images/description_product/Lederhandschuhe/11.png",
        "/images/description_product/Lederhandschuhe/12.png",
        "/images/description_product/Lederhandschuhe/13.png",
        "/images/description_product/Lederhandschuhe/14.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, naturfarben | Rindspaltleder, grau",
        "Beschichtung: -",
        "Größe(n): 11",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS SPLIT STAR+, Rindspaltlederhandschuhe, Baumwolldrell-Rücken, naturfarben, Rindspaltleder, grau (Farbcode: 1612), Innenhandfutter, Segeltuchstulpe, Doppelnähte, Stärke ca. 1,3 mm",
      ],
    },
    isAvailable: true,
  },
  {
    id: 255,
    imgSrc: "/images/products/handarmprotection16.jpg",
    imgHoverSrc: "/images/products/handarmprotection16.jpg",
    title: "1312 // SPLIT MASTER PRO",
    sizes: ["11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Lederhandschuhe/3.png",
        // "/images/description_product/Lederhandschuhe/4.png",
        // "/images/description_product/Lederhandschuhe/5.png",
        // "/images/description_product/Lederhandschuhe/6.png",
        // "/images/description_product/Lederhandschuhe/7.png",
        // "/images/description_product/Lederhandschuhe/8.png",
        // "/images/description_product/Lederhandschuhe/9.png",
        // "/images/description_product/Lederhandschuhe/10.png",
        // "/images/description_product/Lederhandschuhe/11.png",
        // "/images/description_product/Lederhandschuhe/12.png",
        // "/images/description_product/Lederhandschuhe/13.png",
        // "/images/description_product/Lederhandschuhe/14.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, grün / rot / gelb | Rindspaltleder, grau",
        "Beschichtung: -",
        "Größe(n): 10",
        "Verpackungseinheit: 72 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS SPLIT MASTER PRO, Rindspaltlederhandschuhe, Baumwoll-Rücken, grün / rot / gelb, gestreift, Rindspaltleder, grau (Farbcode: 3012), Innenhandfutter, gummierte Stulpe, Doppelnähte, verstärkt durch großen Innenhandbesatz aus Spaltleder inkl. Daumen und Zeigefinger",
      ],
    },
    isAvailable: true,
  },
  {
    id: 256,
    imgSrc: "/images/products/handarmprotection17.jpg",
    imgHoverSrc: "/images/products/handarmprotection17.jpg",
    title: "1315 // SPLIT STAR V",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Lederhandschuhe/3.png",
        // "/images/description_product/Lederhandschuhe/4.png",
        // "/images/description_product/Lederhandschuhe/5.png",
        // "/images/description_product/Lederhandschuhe/6.png",
        // "/images/description_product/Lederhandschuhe/7.png",
        // "/images/description_product/Lederhandschuhe/8.png",
        // "/images/description_product/Lederhandschuhe/9.png",
        // "/images/description_product/Lederhandschuhe/10.png",
        // "/images/description_product/Lederhandschuhe/11.png",
        // "/images/description_product/Lederhandschuhe/12.png",
        // "/images/description_product/Lederhandschuhe/13.png",
        // "/images/description_product/Lederhandschuhe/14.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, naturfarben | Rindspaltleder, grau",
        "Beschichtung: -",
        "Größe(n): 10",
        "Verpackungseinheit: 72 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS SPLIT STAR V, Rindspaltlederhandschuhe, Baumwoll-Rücken, naturfarben, Rindspaltleder, grau (Farbcode: 1612), Innenhandfutter, gummierte Stulpe, Doppelnähte, verstärkt durch großen Innenhandbesatz aus Spaltleder inkl. Daumen und Zeigefinger, Besatz reicht bis an die Finger",
      ],
    },
    isAvailable: true,
  },
  {
    id: 257,
    imgSrc: "/images/products/handarmprotection18.jpg",
    imgHoverSrc: "/images/products/handarmprotection18.jpg",
    title: "1403B // APOLLO",
    sizes: ["8", "9", "10", "11", "12"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Lederhandschuhe/3.png",
        // "/images/description_product/Lederhandschuhe/4.png",
        // "/images/description_product/Lederhandschuhe/5.png",
        // "/images/description_product/Lederhandschuhe/6.png",
        // "/images/description_product/Lederhandschuhe/7.png",
        // "/images/description_product/Lederhandschuhe/8.png",
        // "/images/description_product/Lederhandschuhe/9.png",
        // "/images/description_product/Lederhandschuhe/10.png",
        // "/images/description_product/Lederhandschuhe/11.png",
        // "/images/description_product/Lederhandschuhe/12.png",
        // "/images/description_product/Lederhandschuhe/13.png",
        // "/images/description_product/Lederhandschuhe/14.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, naturfarben | Rindvollleder, grau",
        "Beschichtung: -",
        "Größe(n): 8-12",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS APOLLO, Rindvolllederhandschuhe, Baumwoll-Rücken, naturfarben, Rindvollleder, grau (Farbcode: 1612), Innenhandfutter, Segeltuchstulpe, Doppelnähte, Lederselektion B",
      ],
    },
    isAvailable: true,
  },
  {
    id: 258,
    imgSrc: "/images/products/handarmprotection19.jpg",
    imgHoverSrc: "/images/products/handarmprotection19.jpg",
    title: "1403C // POSEIDON",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Lederhandschuhe/3.png",
        // "/images/description_product/Lederhandschuhe/4.png",
        // "/images/description_product/Lederhandschuhe/5.png",
        // "/images/description_product/Lederhandschuhe/6.png",
        // "/images/description_product/Lederhandschuhe/7.png",
        // "/images/description_product/Lederhandschuhe/8.png",
        // "/images/description_product/Lederhandschuhe/9.png",
        // "/images/description_product/Lederhandschuhe/10.png",
        // "/images/description_product/Lederhandschuhe/11.png",
        // "/images/description_product/Lederhandschuhe/12.png",
        // "/images/description_product/Lederhandschuhe/13.png",
        // "/images/description_product/Lederhandschuhe/14.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, gelb / blau | Rindvollleder, grau",
        "Beschichtung: -",
        "Größe(n): 10",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS POSEIDON, Rindvolllederhandschuhe, Baumwoll-Rücken, gelb / blau, gestreift, Rindvollleder, grau (Farbcode: 4312), Innenhandfutter, gummierte Stulpe",
      ],
    },
    isAvailable: true,
  },
  {
    id: 259,
    imgSrc: "/images/products/handarmprotection20.jpg",
    imgHoverSrc: "/images/products/handarmprotection20.jpg",
    title: "1403H TOP // ZEUS",
    sizes: ["8", "9", "10", "11", "12"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Lederhandschuhe/3.png",
        // "/images/description_product/Lederhandschuhe/4.png",
        // "/images/description_product/Lederhandschuhe/5.png",
        // "/images/description_product/Lederhandschuhe/6.png",
        // "/images/description_product/Lederhandschuhe/7.png",
        // "/images/description_product/Lederhandschuhe/8.png",
        // "/images/description_product/Lederhandschuhe/9.png",
        // "/images/description_product/Lederhandschuhe/10.png",
        // "/images/description_product/Lederhandschuhe/11.png",
        // "/images/description_product/Lederhandschuhe/12.png",
        // "/images/description_product/Lederhandschuhe/13.png",
        // "/images/description_product/Lederhandschuhe/14.png",
        "/images/description_product/Lederhandschuhe/15.png",
        "/images/description_product/Lederhandschuhe/16.png",
        "/images/description_product/Lederhandschuhe/17.png",
        "/images/description_product/Lederhandschuhe/18.png",
        "/images/description_product/Lederhandschuhe/19.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, gelb / blau | Rindvollleder, grau",
        "Beschichtung: -",
        "Größe(n): 10",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS POSEIDON, Rindvolllederhandschuhe, Baumwoll-Rücken, gelb / blau, gestreift, Rindvollleder, grau (Farbcode: 4312), Innenhandfutter, gummierte Stulpe",
      ],
    },
    isAvailable: true,
  },
  {
    id: 260,
    imgSrc: "/images/products/handarmprotection21.jpg",
    imgHoverSrc: "/images/products/handarmprotection21.jpg",
    title: "1403K // ARES",
    sizes: ["8", "9", "10", "11", "12"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Lederhandschuhe/3.png",
        // "/images/description_product/Lederhandschuhe/4.png",
        // "/images/description_product/Lederhandschuhe/5.png",
        // "/images/description_product/Lederhandschuhe/6.png",
        // "/images/description_product/Lederhandschuhe/7.png",
        // "/images/description_product/Lederhandschuhe/8.png",
        // "/images/description_product/Lederhandschuhe/9.png",
        // "/images/description_product/Lederhandschuhe/10.png",
        // "/images/description_product/Lederhandschuhe/11.png",
        // "/images/description_product/Lederhandschuhe/12.png",
        // "/images/description_product/Lederhandschuhe/13.png",
        // "/images/description_product/Lederhandschuhe/14.png",
        "/images/description_product/Lederhandschuhe/15.png",
        "/images/description_product/Lederhandschuhe/16.png",
        "/images/description_product/Lederhandschuhe/17.png",
        "/images/description_product/Lederhandschuhe/18.png",
        "/images/description_product/Lederhandschuhe/19.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, naturfarben | Rindvollleder, grau",
        "Beschichtung: -",
        "Größe(n): 8-12",
        "Verpackungseinheit: 60 Paar",
        "Zertifizierung: EN 388, EN 407",
        "Beschreibung: NITRAS ARES, Schnittschutzhandschuhe, Baumwoll-Rücken, naturfarben, Rindvollleder, grau (Farbcode: 1612), Innenhand mit Spezialgarn KATA 5 gefüttert, Daumen und Zeigefinger voll gefüttert, Segeltuchstulpe, Kevlar-Doppelnähte, Lederselektion A, hoher Schnittschutz, Schutz gegen Kontaktwärme (100° C, 15 Sekunden), höchster Tragekomfort, großer Schnitt, perfekte Passform, Premium-Qualität, besonders geschmeidig und strapazierfähig, PSA-Risikokategorie II",
      ],
    },
    isAvailable: true,
  },
  {
    id: 261,
    imgSrc: "/images/products/handarmprotection22.jpg",
    imgHoverSrc: "/images/products/handarmprotection22.jpg",
    title: "1403KSP // HADES",
    sizes: ["8", "9", "10", "11", "12"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Lederhandschuhe/3.png",
        // "/images/description_product/Lederhandschuhe/4.png",
        // "/images/description_product/Lederhandschuhe/5.png",
        // "/images/description_product/Lederhandschuhe/6.png",
        // "/images/description_product/Lederhandschuhe/7.png",
        // "/images/description_product/Lederhandschuhe/8.png",
        // "/images/description_product/Lederhandschuhe/9.png",
        // "/images/description_product/Lederhandschuhe/10.png",
        // "/images/description_product/Lederhandschuhe/11.png",
        // "/images/description_product/Lederhandschuhe/12.png",
        // "/images/description_product/Lederhandschuhe/13.png",
        // "/images/description_product/Lederhandschuhe/14.png",
        "/images/description_product/Lederhandschuhe/15.png",
        "/images/description_product/Lederhandschuhe/16.png",
        "/images/description_product/Lederhandschuhe/17.png",
        "/images/description_product/Lederhandschuhe/18.png",
        "/images/description_product/Lederhandschuhe/19.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, naturfarben | Rindvollleder, grau",
        "Beschichtung: -",
        "Größe(n): 8-12",
        "Verpackungseinheit: 60 Paar",
        "Zertifizierung: EN 388, EN 407",
        "Beschreibung: NITRAS ARES, Schnittschutzhandschuhe, Baumwoll-Rücken, naturfarben, Rindvollleder, grau (Farbcode: 1612), Innenhand mit Spezialgarn KATA 5 gefüttert, Daumen und Zeigefinger voll gefüttert, Segeltuchstulpe, Kevlar-Doppelnähte, Lederselektion A, hoher Schnittschutz, Schutz gegen Kontaktwärme (100° C, 15 Sekunden), höchster Tragekomfort, großer Schnitt, perfekte Passform, Premium-Qualität, besonders geschmeidig und strapazierfähig, PSA-Risikokategorie II",
      ],
    },
    isAvailable: true,
  },
  {
    id: 262,
    imgSrc: "/images/products/handarmprotection23.jpg",
    imgHoverSrc: "/images/products/handarmprotection23.jpg",
    title: "1403M // MERKUR",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Lederhandschuhe/3.png",
        // "/images/description_product/Lederhandschuhe/4.png",
        // "/images/description_product/Lederhandschuhe/5.png",
        // "/images/description_product/Lederhandschuhe/6.png",
        // "/images/description_product/Lederhandschuhe/7.png",
        // "/images/description_product/Lederhandschuhe/8.png",
        // "/images/description_product/Lederhandschuhe/9.png",
        // "/images/description_product/Lederhandschuhe/10.png",
        // "/images/description_product/Lederhandschuhe/11.png",
        // "/images/description_product/Lederhandschuhe/12.png",
        // "/images/description_product/Lederhandschuhe/13.png",
        // "/images/description_product/Lederhandschuhe/14.png",
        // "/images/description_product/Lederhandschuhe/15.png",
        // "/images/description_product/Lederhandschuhe/16.png",
        "/images/description_product/Lederhandschuhe/17.png",
        // "/images/description_product/Lederhandschuhe/18.png",
        // "/images/description_product/Lederhandschuhe/19.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, naturfarben | Rindvollleder, grau",
        "Beschichtung: -",
        "Größe(n): 10",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS MERKUR, Rindvolllederhandschuhe, Baumwoll-Rücken, naturfarben, Rindvollleder, grau (Farbcode: 1612), Innenhandfutter, gummierte Stulpe, Doppelnähte, Lederselektion B",
      ],
    },
    isAvailable: true,
  },
  {
    id: 263,
    imgSrc: "/images/products/handarmprotection24.jpg",
    imgHoverSrc: "/images/products/handarmprotection24.jpg",
    title: "1403NEO",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Lederhandschuhe/3.png",
        // "/images/description_product/Lederhandschuhe/4.png",
        // "/images/description_product/Lederhandschuhe/5.png",
        // "/images/description_product/Lederhandschuhe/6.png",
        // "/images/description_product/Lederhandschuhe/7.png",
        // "/images/description_product/Lederhandschuhe/8.png",
        // "/images/description_product/Lederhandschuhe/9.png",
        // "/images/description_product/Lederhandschuhe/10.png",
        // "/images/description_product/Lederhandschuhe/11.png",
        // "/images/description_product/Lederhandschuhe/12.png",
        // "/images/description_product/Lederhandschuhe/13.png",
        // "/images/description_product/Lederhandschuhe/14.png",
        // "/images/description_product/Lederhandschuhe/15.png",
        // "/images/description_product/Lederhandschuhe/16.png",
        "/images/description_product/Lederhandschuhe/17.png",
        // "/images/description_product/Lederhandschuhe/18.png",
        // "/images/description_product/Lederhandschuhe/19.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, naturfarben | Rindvollleder, grau",
        "Beschichtung: -",
        "Größe(n): 10",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS MERKUR, Rindvolllederhandschuhe, Baumwoll-Rücken, naturfarben, Rindvollleder, grau (Farbcode: 1612), Innenhandfutter, gummierte Stulpe, Doppelnähte, Lederselektion B",
      ],
    },
    isAvailable: true,
  },

  {
    id: 264,
    imgSrc: "/images/products/handarmprotection25.jpg",
    imgHoverSrc: "/images/products/handarmprotection25.jpg",
    title: "1403P // PLUTO",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Lederhandschuhe/3.png",
        // "/images/description_product/Lederhandschuhe/4.png",
        // "/images/description_product/Lederhandschuhe/5.png",
        // "/images/description_product/Lederhandschuhe/6.png",
        // "/images/description_product/Lederhandschuhe/7.png",
        // "/images/description_product/Lederhandschuhe/8.png",
        // "/images/description_product/Lederhandschuhe/9.png",
        // "/images/description_product/Lederhandschuhe/10.png",
        // "/images/description_product/Lederhandschuhe/11.png",
        // "/images/description_product/Lederhandschuhe/12.png",
        // "/images/description_product/Lederhandschuhe/13.png",
        // "/images/description_product/Lederhandschuhe/14.png",
        "/images/description_product/Lederhandschuhe/15.png",
        // "/images/description_product/Lederhandschuhe/16.png",
        // "/images/description_product/Lederhandschuhe/17.png",
        // "/images/description_product/Lederhandschuhe/18.png",
        // "/images/description_product/Lederhandschuhe/19.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, naturfarben | Rindvollleder, grau",
        "Beschichtung: -",
        "Größe(n): 10",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS MERKUR, Rindvolllederhandschuhe, Baumwoll-Rücken, naturfarben, Rindvollleder, grau (Farbcode: 1612), Innenhandfutter, gummierte Stulpe, Doppelnähte, Lederselektion B",
      ],
    },
    isAvailable: true,
  },
  {
    id: 265,
    imgSrc: "/images/products/handarmprotection26.jpg",
    imgHoverSrc: "/images/products/handarmprotection26.jpg",
    title: "1403W // WINTER WORKER",
    sizes: ["11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Winterhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Lederhandschuhe/3.png",
        // "/images/description_product/Lederhandschuhe/4.png",
        // "/images/description_product/Lederhandschuhe/5.png",
        // "/images/description_product/Lederhandschuhe/6.png",
        // "/images/description_product/Lederhandschuhe/7.png",
        // "/images/description_product/Lederhandschuhe/8.png",
        // "/images/description_product/Lederhandschuhe/9.png",
        // "/images/description_product/Lederhandschuhe/10.png",
        // "/images/description_product/Lederhandschuhe/11.png",
        // "/images/description_product/Lederhandschuhe/12.png",
        // "/images/description_product/Lederhandschuhe/13.png",
        // "/images/description_product/Lederhandschuhe/14.png",
        // "/images/description_product/Lederhandschuhe/15.png",
        // "/images/description_product/Lederhandschuhe/16.png",
        // "/images/description_product/Lederhandschuhe/17.png",
        // "/images/description_product/Lederhandschuhe/18.png",
        // "/images/description_product/Lederhandschuhe/19.png",
        "/images/description_product/Lederhandschuhe/20.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, naturfarben | Rindvollleder, grau",
        "Beschichtung: -",
        "Größe(n): 10",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS MERKUR, Rindvolllederhandschuhe, Baumwoll-Rücken, naturfarben, Rindvollleder, grau (Farbcode: 1612), Innenhandfutter, gummierte Stulpe, Doppelnähte, Lederselektion B",
      ],
    },
    isAvailable: true,
  },
  {
    id: 266,
    imgSrc: "/images/products/handarmprotection27.jpg",
    imgHoverSrc: "/images/products/handarmprotection27.jpg",
    title: "1409 // DRIVER",
    sizes: ["8", "9", "10", "11", "12"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Lederhandschuhe/3.png",
        // "/images/description_product/Lederhandschuhe/4.png",
        // "/images/description_product/Lederhandschuhe/5.png",
        // "/images/description_product/Lederhandschuhe/6.png",
        // "/images/description_product/Lederhandschuhe/7.png",
        // "/images/description_product/Lederhandschuhe/8.png",
        // "/images/description_product/Lederhandschuhe/9.png",
        // "/images/description_product/Lederhandschuhe/10.png",
        // "/images/description_product/Lederhandschuhe/11.png",
        // "/images/description_product/Lederhandschuhe/12.png",
        // "/images/description_product/Lederhandschuhe/13.png",
        // "/images/description_product/Lederhandschuhe/14.png",
        "/images/description_product/Lederhandschuhe/15.png",
        // "/images/description_product/Lederhandschuhe/16.png",
        // "/images/description_product/Lederhandschuhe/17.png",
        // "/images/description_product/Lederhandschuhe/18.png",
        // "/images/description_product/Lederhandschuhe/19.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Baumwolle, naturfarben | Rindvollleder, grau",
        "Beschichtung: -",
        "Größe(n): 10",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS MERKUR, Rindvolllederhandschuhe, Baumwoll-Rücken, naturfarben, Rindvollleder, grau (Farbcode: 1612), Innenhandfutter, gummierte Stulpe, Doppelnähte, Lederselektion B",
      ],
    },
    isAvailable: true,
  },
  {
    id: 267,
    imgSrc: "/images/products/handarmprotection28.jpg",
    imgHoverSrc: "/images/products/handarmprotection28.jpg",
    title: "1409W // DRIVER WINTER",
    sizes: ["8", "9", "10", "11", "12"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Lederhandschuhe/3.png",
        // "/images/description_product/Lederhandschuhe/4.png",
        // "/images/description_product/Lederhandschuhe/5.png",
        // "/images/description_product/Lederhandschuhe/6.png",
        // "/images/description_product/Lederhandschuhe/7.png",
        // "/images/description_product/Lederhandschuhe/8.png",
        // "/images/description_product/Lederhandschuhe/9.png",
        // "/images/description_product/Lederhandschuhe/10.png",
        // "/images/description_product/Lederhandschuhe/11.png",
        // "/images/description_product/Lederhandschuhe/12.png",
        // "/images/description_product/Lederhandschuhe/13.png",
        // "/images/description_product/Lederhandschuhe/14.png",
        "/images/description_product/Lederhandschuhe/15.png",
        // "/images/description_product/Lederhandschuhe/16.png",
        // "/images/description_product/Lederhandschuhe/17.png",
        // "/images/description_product/Lederhandschuhe/18.png",
        // "/images/description_product/Lederhandschuhe/19.png",
      ],
      product_details: ["EN 388, EN 388, 3133X, EN ISO 21420"],
      further_information: [
        "Material: Rindvollleder, grau",
        "Beschichtung: -",
        "Größe(n): 8-12",
        "Verpackungseinheit: 60 Paar",
        "Zertifizierung: EN 388, EN 407, EN 511",
        "Beschreibung: NITRAS DRIVER WINTER, Rindvolllederhandschuhe, grau (Farbcode: 1200), mit Gummizug am Handrücken, Winterfutter bis in die Stulpe, Flügeldaumen, großer Schnitt, perfekte Passform, Schutz gegen Kontaktwärme (100° C, 15 Sekunden), Premium-Qualität",
      ],
    },
    isAvailable: true,
  },
  {
    id: 268,
    imgSrc: "/images/products/handarmprotection29.jpg",
    imgHoverSrc: "/images/products/handarmprotection29.jpg",
    title: "160227",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "PVC-Handschuhe",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Lederhandschuhe/3.png",
        // "/images/description_product/Lederhandschuhe/4.png",
        // "/images/description_product/Lederhandschuhe/5.png",
        // "/images/description_product/Lederhandschuhe/6.png",
        // "/images/description_product/Lederhandschuhe/7.png",
        // "/images/description_product/Lederhandschuhe/8.png",
        // "/images/description_product/Lederhandschuhe/9.png",
        // "/images/description_product/Lederhandschuhe/10.png",
        // "/images/description_product/Lederhandschuhe/11.png",
        // "/images/description_product/Lederhandschuhe/12.png",
        // "/images/description_product/Lederhandschuhe/13.png",
        // "/images/description_product/Lederhandschuhe/14.png",
        // "/images/description_product/Lederhandschuhe/15.png",
        // "/images/description_product/Lederhandschuhe/16.png",
        // "/images/description_product/Lederhandschuhe/17.png",
        // "/images/description_product/Lederhandschuhe/18.png",
        // "/images/description_product/Lederhandschuhe/19.png",
        "/images/description_product/Lederhandschuhe/21.png",
      ],
      product_details: ["EN 388, 4121X, EN ISO 21420"],
      further_information: [
        "Material: Baumwoll-Trikot, naturfarben",
        "Beschichtung: PVC, rot",
        "Größe(n): 10",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS PVC-Handschuhe, Baumwoll-Trikot, naturfarben, PVC, rot (Farbcode: 6000), vollbeschichtet, Länge 27 cm",
      ],
    },
    isAvailable: true,
  },
  {
    id: 269,
    imgSrc: "/images/products/handarmprotection30.jpg",
    imgHoverSrc: "/images/products/handarmprotection30.jpg",
    title: "160235",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "PVC-Handschuhe",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Lederhandschuhe/3.png",
        // "/images/description_product/Lederhandschuhe/4.png",
        // "/images/description_product/Lederhandschuhe/5.png",
        // "/images/description_product/Lederhandschuhe/6.png",
        // "/images/description_product/Lederhandschuhe/7.png",
        // "/images/description_product/Lederhandschuhe/8.png",
        // "/images/description_product/Lederhandschuhe/9.png",
        // "/images/description_product/Lederhandschuhe/10.png",
        // "/images/description_product/Lederhandschuhe/11.png",
        // "/images/description_product/Lederhandschuhe/12.png",
        // "/images/description_product/Lederhandschuhe/13.png",
        // "/images/description_product/Lederhandschuhe/14.png",
        // "/images/description_product/Lederhandschuhe/15.png",
        // "/images/description_product/Lederhandschuhe/16.png",
        // "/images/description_product/Lederhandschuhe/17.png",
        // "/images/description_product/Lederhandschuhe/18.png",
        // "/images/description_product/Lederhandschuhe/19.png",
        "/images/description_product/Lederhandschuhe/21.png",
      ],
      product_details: ["EN 388, 4121X, EN ISO 21420"],
      further_information: [
        "Material: Baumwoll-Trikot, naturfarben",
        "Beschichtung: PVC, rot",
        "Größe(n): 10",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS PVC-Handschuhe, Baumwoll-Trikot, naturfarben, PVC, rot, vollbeschichtet (Farbcode: 6000), Länge 35 cm",
      ],
    },
    isAvailable: true,
  },
  {
    id: 270,
    imgSrc: "/images/products/handarmprotection31.jpg",
    imgHoverSrc: "/images/products/handarmprotection31.jpg",
    title: "160240",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Armschutz",
    subcategory: "PVC-Handschuhe",
    description: {
      images: [
        // "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        // "/images/description_product/Lederhandschuhe/3.png",
        // "/images/description_product/Lederhandschuhe/4.png",
        // "/images/description_product/Lederhandschuhe/5.png",
        // "/images/description_product/Lederhandschuhe/6.png",
        // "/images/description_product/Lederhandschuhe/7.png",
        // "/images/description_product/Lederhandschuhe/8.png",
        // "/images/description_product/Lederhandschuhe/9.png",
        // "/images/description_product/Lederhandschuhe/10.png",
        // "/images/description_product/Lederhandschuhe/11.png",
        // "/images/description_product/Lederhandschuhe/12.png",
        // "/images/description_product/Lederhandschuhe/13.png",
        // "/images/description_product/Lederhandschuhe/14.png",
        // "/images/description_product/Lederhandschuhe/15.png",
        // "/images/description_product/Lederhandschuhe/16.png",
        // "/images/description_product/Lederhandschuhe/17.png",
        // "/images/description_product/Lederhandschuhe/18.png",
        // "/images/description_product/Lederhandschuhe/19.png",
        "/images/description_product/Lederhandschuhe/21.png",
      ],
      product_details: ["EN 388, 4121X, EN ISO 21420"],
      further_information: [
        "Material: Baumwoll-Trikot, naturfarben",
        "Beschichtung: PVC, rot",
        "Größe(n): 10",
        "Verpackungseinheit: 72 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS PVC-Handschuhe, Baumwoll-Trikot, naturfarben, PVC, rot (Farbcode: 6000), vollbeschichtet, Länge 40 cm",
      ],
    },
    isAvailable: true,
  },
  {
    id: 271,
    imgSrc: "/images/products/handarmprotection32.jpg",
    imgHoverSrc: "/images/products/handarmprotection32.jpg",
    title: "1603 // GRIP",
    sizes: ["8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Armschutz",
    subcategory: "Strickhandschuhe",
    description: {
      images: [
        "/images/description_product/Strickhandschuhe/1.png",
        "/images/description_product/Strickhandschuhe/2.png",
        "/images/description_product/Strickhandschuhe/3.png",
      ],
      product_details: ["EN 388, 2142X, EN ISO 21420"],
      further_information: [
        "Material: Polyester, gelb",
        "Beschichtung: Latex, grün",
        "Größe(n): 8-11",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS GRIP, Latexhandschuhe, Polyester, gelb, Latex-Beschichtung, grün (Farbcode: 4330), teilbeschichtet auf Innenhand und Fingerkuppen, schrumpfgeraut, extreme Griffigkeit, Strickbund",
      ],
    },
    isAvailable: true,
  },
  {
    id: 272,
    imgSrc: "/images/products/handarmprotection33.jpg",
    imgHoverSrc: "/images/products/handarmprotection33.jpg",
    title: "1603W // WINTER BLOCKER",
    sizes: ["8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Armschutz",
    subcategory: "Winterhandschuhe",
    description: {
      images: [
        "/images/description_product/Winterhandschuhe/1.png",
        "/images/description_product/Winterhandschuhe/2.png",
        "/images/description_product/Winterhandschuhe/3.png",
        "/images/description_product/Winterhandschuhe/4.png",
      ],
      product_details: ["EN 388, 2141X, EN 511, 02X, EN ISO 21420"],
      further_information: [
        "Material: Baumwoll-Schlingengewebe, neongelb",
        "Beschichtung: Latex, blau",
        "Größe(n): 8-11",
        "Verpackungseinheit: 72 Paar",
        "Zertifizierung: EN 388, EN 511",
        "Beschreibung: NITRAS WINTER BLOCKER, Winterhandschuhe, nahtloses Terry-Schlingengewebe innen, neongelb, Latex-Beschichtung, blau (Farbcode: 4022), teilbeschichtet auf Innenhand und Fingerkuppen, schrumpfgeraut, extreme Griffigkeit, Strickbund, höchster Tragekomfort, höchste Fingerfertigkeit",
      ],
    },
    isAvailable: true,
  },
  {
    id: 273,
    imgSrc: "/images/products/handarmprotection34.jpg",
    imgHoverSrc: "/images/products/handarmprotection34.jpg",
    title: "20035 // VULCANUS 35",
    sizes: ["9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Armschutz",
    subcategory: "Schweißerhandschuhe",
    description: {
      images: [
        "/images/description_product/Schweißerhandschuhe/1.png",
        "/images/description_product/Schweißerhandschuhe/2.png",
        "/images/description_product/Schweißerhandschuhe/3.png",
        "/images/description_product/Schweißerhandschuhe/4.png",
      ],
      product_details: ["EN 388, 2141X, EN 511, 02X, EN ISO 21420"],
      further_information: [
        "Material: Vollleder, grau | Spaltleder, grau",
        "Beschichtung: -",
        "Größe(n): 9-11",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388, EN 12477",
        "Beschreibung: NITRAS VULCANUS 35, Schweißerhandschuhe, Vollleder und Spaltleder kombiniert, grau (Farbcode: 1200), Spaltlederstulpe, Länge 35 cm, Schutz gegen Kontaktwärme (100° C, 15 Sekunden), besonders geschmeidig und strapazierfähig, langlebig, robust, PSA-Risikokategorie II",
      ],
    },
    isAvailable: true,
  },
  {
    id: 274,
    imgSrc: "/images/products/handarmprotection35.jpg",
    imgHoverSrc: "/images/products/handarmprotection35.jpg",
    title: "160435",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Armschutz",
    subcategory: "Chemikalienschutzhandschuhe",
    description: {
      images: [
        "/images/description_product/Chemikalienschutzhandschuhe/1.png",
        "/images/description_product/Chemikalienschutzhandschuhe/2.png",
        "/images/description_product/Chemikalienschutzhandschuhe/3.png",
        "/images/description_product/Chemikalienschutzhandschuhe/4.png",
      ],
      product_details: [
        "EN 388, 4131X, EN ISO 21420, ISO 374-1/Type B, AKL, ISO 374-5:2016",
      ],
      further_information: [
        "Material: Baumwoll-Trikot, naturfarben",
        "Beschichtung: PVC, rot",
        "Größe(n): 10",
        "Verpackungseinheit: 60 Paar",
        "Zertifizierung: EN 388, EN ISO 374",
        "Beschreibung: NITRAS Chemikalienschutzhandschuhe, Baumwoll-Trikot, naturfarben, PVC, rot (Farbcode: 6000), vollbeschichtet, doppelt getaucht, Länge 35 cm, Schutz gegen Mikroorganismen und Bakterien, hohe mechanische Beständigkeit, licht- und alterungsbeständig, hohe Abriebfestigkeit, PSA-Risikokategorie III",
      ],
    },
    isAvailable: true,
  },
  {
    id: 275,
    imgSrc: "/images/products/handarmprotection36.jpg",
    imgHoverSrc: "/images/products/handarmprotection36.jpg",
    title: "1606W // SNOW FIGHTER",
    sizes: ["8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Armschutz",
    subcategory: "Winterhandschuhe",
    description: {
      images: [
        // "/images/description_product/Winterhandschuhe/1.png",
        "/images/description_product/Winterhandschuhe/2.png",
        "/images/description_product/Winterhandschuhe/3.png",
        "/images/description_product/Winterhandschuhe/4.png",
        "/images/description_product/Winterhandschuhe/5.png",
        "/images/description_product/Winterhandschuhe/6.png",
        "/images/description_product/Winterhandschuhe/7.png",
      ],
      product_details: ["EN 388, 3242X, EN 511, X2X, EN ISO 21420"],
      further_information: [
        "Material: Nylon, schwarz",
        "Beschichtung: Nitril, gesandet, schwarz",
        "Größe(n): 8-11",
        "Verpackungseinheit: 72 Paar",
        "Zertifizierung: EN 388, EN 511",
        "Beschreibung: NITRAS SNOW FIGHTER, Winterhandschuhe, Nylon, schwarz, nahtloses Terry-Schlingengewebe innen, Nitril-Beschichtung, schwarz (Farbcode: 1000), gesandet, teilbeschichtet auf Innenhand und Fingerkuppen, Strickbund, höchster Tragekomfort, höchste Fingerfertigkeit, touchscreenfähig",
      ],
    },
    isAvailable: true,
  },
  {
    id: 276,
    imgSrc: "/images/products/handarmprotection37.jpg",
    imgHoverSrc: "/images/products/handarmprotection37.jpg",
    title: "1606WV // SNOW FIGHTER+",
    sizes: ["8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Armschutz",
    subcategory: "Winterhandschuhe",
    description: {
      images: [
        // "/images/description_product/Winterhandschuhe/1.png",
        "/images/description_product/Winterhandschuhe/2.png",
        "/images/description_product/Winterhandschuhe/3.png",
        "/images/description_product/Winterhandschuhe/4.png",
        "/images/description_product/Winterhandschuhe/5.png",
        "/images/description_product/Winterhandschuhe/6.png",
        "/images/description_product/Winterhandschuhe/7.png",
      ],
      product_details: ["EN 388, 3242X, EN 511, X2X, EN ISO 21420"],
      further_information: [
        "Material: Nylon, schwarz",
        "Beschichtung: Nitril, gesandet, schwarz",
        "Größe(n): 8-11",
        "Verpackungseinheit: 72 Paar",
        "Zertifizierung: EN 388, EN 511",
        "Beschreibung: NITRAS SNOW FIGHTER+, Winterhandschuhe, Nylon, schwarz, nahtloses Terry-Schlingengewebe innen, Nitril-Beschichtung, schwarz (Farbcode: 1000), gesandet, 3/4-beschichtet, Strickbund, höchster Tragekomfort, höchste Fingerfertigkeit, touchscreenfähig",
      ],
    },
    isAvailable: true,
  },
  {
    id: 277,
    imgSrc: "/images/products/handarmprotection38.jpg",
    imgHoverSrc: "/images/products/handarmprotection38.jpg",
    title: "1611 // BLUE POWER GRIP",
    sizes: ["7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Armschutz",
    subcategory: "Chemikalienschutzhandschuhe",
    description: {
      images: [
        "/images/description_product/Chemikalienschutzhandschuhe/1.png",
        "/images/description_product/Chemikalienschutzhandschuhe/2.png",
        "/images/description_product/Chemikalienschutzhandschuhe/3.png",
        "/images/description_product/Chemikalienschutzhandschuhe/4.png",
        "/images/description_product/Chemikalienschutzhandschuhe/5.png",
        "/images/description_product/Chemikalienschutzhandschuhe/6.png",
        "/images/description_product/Chemikalienschutzhandschuhe/7.png",
      ],
      product_details: [
        "EN 388, 2131X, EN ISO 21420, ISO 374-1:2016-Type A, AKLNOT, ISO 374-5:2016, Latex",
      ],
      further_information: [
        "Material: Baumwoll-Trikot, naturfarben",
        "Beschichtung: Latex, blau",
        "Größe(n): 7-11",
        "Verpackungseinheit: 72 Paar",
        "Zertifizierung: EN 388, EN ISO 374",
        "Beschreibung: NITRAS BLUE POWER GRIP, Chemikalienschutzhandschuhe, Baumwoll-Trikot, naturfarben, Latex, blau (Farbcode: 2200), Länge 30 cm, geraute Hand, Stärke ca. 1,7 mm, sehr weich, angenehm zu tragen, Schutz gegen Mikroorganismen und Bakterien, für Lebensmittelkontakt, Premium-Qualität, besonders feinfühlig, perfekt geeignet für Arbeiten mit kleinen Teilen, sehr rutschfest, sehr gutes Tastempfinden, sehr guter Trocken- und Nassgriff, PSA-Risikokategorie III",
      ],
    },
    isAvailable: true,
  },
  {
    id: 278,
    imgSrc: "/images/products/handarmprotection39.jpg",
    imgHoverSrc: "/images/products/handarmprotection39.jpg",
    title: "20028 // VULCANUS 28",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Lederhandschuhe",
    description: {
      images: [
        // "/images/description_product/Lederhandschuhe/1.png",
        "/images/description_product/Lederhandschuhe/2.png",
        "/images/description_product/Lederhandschuhe/22.png",
        "/images/description_product/Lederhandschuhe/17.png",
        "/images/description_product/Lederhandschuhe/23.png",
      ],
      product_details: ["EN 388, 3122X, EN 407, X1XXXX, EN 420"],
      further_information: [
        "Material: Vollleder, grau | Spaltleder, grau",
        "Beschichtung: -",
        "Größe(n): 10",
        "Verpackungseinheit: 120 Paar",
        "Zertifizierung: EN 388, EN 407",
        "Beschreibung: NITRAS VULCANUS 28, Lederhandschuhe, Vollleder und Spaltleder kombiniert, grau (Farbcode: 1200), Spaltlederstulpe, Länge 28 cm, Schutz gegen Kontaktwärme (100° C, 15 Sekunden)",
      ],
    },
    isAvailable: true,
  },
  {
    id: 279,
    imgSrc: "/images/products/handarmprotection40.jpg",
    imgHoverSrc: "/images/products/handarmprotection40.jpg",
    title: "5310",
    sizes: ["7"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Baumwollhandschuhe",
    description: {
      images: ["/images/description_product/Baumwollhandschuhe/1.png"],
      product_details: ["-"],
      further_information: [
        "Material: Baumwoll-Trikot, weiß, gebleicht",
        "Beschichtung: -",
        "Größe(n): 7",
        "Verpackungseinheit: 600 Paar",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Baumwoll-Trikot-Handschuhe, weiß (Farbcode: 1100), gebleicht, mit Schichtel",
      ],
    },
    isAvailable: true,
  },
  {
    id: 280,
    imgSrc: "/images/products/handarmprotection41.jpg",
    imgHoverSrc: "/images/products/handarmprotection41.jpg",
    title: "5311",
    sizes: ["8"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Baumwollhandschuhe",
    description: {
      images: ["/images/description_product/Baumwollhandschuhe/1.png"],
      product_details: ["-"],
      further_information: [
        "Material: Baumwoll-Trikot, weiß, gebleicht",
        "Beschichtung: -",
        "Größe(n): 8",
        "Verpackungseinheit: 600 Paar",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Baumwoll-Trikot-Handschuhe, weiß (Farbcode: 1100), gebleicht, mit Schichtel",
      ],
    },
    isAvailable: true,
  },
  {
    id: 281,
    imgSrc: "/images/products/handarmprotection42.jpg",
    imgHoverSrc: "/images/products/handarmprotection42.jpg",
    title: "5312",
    sizes: ["9"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Baumwollhandschuhe",
    description: {
      images: ["/images/description_product/Baumwollhandschuhe/1.png"],
      product_details: ["-"],
      further_information: [
        "Material: Baumwoll-Trikot, weiß, gebleicht",
        "Beschichtung: -",
        "Größe(n): 9",
        "Verpackungseinheit: 600 Paar",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Baumwoll-Trikot-Handschuhe, weiß (Farbcode: 1100), gebleicht, mit Schichtel",
      ],
    },
    isAvailable: true,
  },
  {
    id: 282,
    imgSrc: "/images/products/handarmprotection43.jpg",
    imgHoverSrc: "/images/products/handarmprotection43.jpg",
    title: "5312",
    sizes: ["9"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Baumwollhandschuhe",
    description: {
      images: ["/images/description_product/Baumwollhandschuhe/1.png"],
      product_details: ["-"],
      further_information: [
        "Material: Baumwoll-Trikot, weiß, gebleicht",
        "Beschichtung: -",
        "Größe(n): 9",
        "Verpackungseinheit: 600 Paar",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Baumwoll-Trikot-Handschuhe, weiß (Farbcode: 1100), gebleicht, mit Schichtel",
      ],
    },
    isAvailable: true,
  },
  {
    id: 283,
    imgSrc: "/images/products/handarmprotection44.jpg",
    imgHoverSrc: "/images/products/handarmprotection44.jpg",
    title: "5313",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Baumwollhandschuhe",
    description: {
      images: ["/images/description_product/Baumwollhandschuhe/1.png"],
      product_details: ["-"],
      further_information: [
        "Material: Baumwoll-Trikot, weiß, gebleicht",
        "Beschichtung: -",
        "Größe(n): 10",
        "Verpackungseinheit: 600 Paar",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Baumwoll-Trikot-Handschuhe, weiß (Farbcode: 1100), gebleicht, mit Schichtel",
      ],
    },
    isAvailable: true,
  },
  {
    id: 284,
    imgSrc: "/images/products/handarmprotection45.jpg",
    imgHoverSrc: "/images/products/handarmprotection45.jpg",
    title: "5314",
    sizes: ["11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Baumwollhandschuhe",
    description: {
      images: ["/images/description_product/Baumwollhandschuhe/1.png"],
      product_details: ["-"],
      further_information: [
        "Material: Baumwoll-Trikot, weiß, gebleicht",
        "Beschichtung: -",
        "Größe(n): 11",
        "Verpackungseinheit: 600 Paar",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Baumwoll-Trikot-Handschuhe, weiß (Farbcode: 1100), gebleicht, mit Schichtel",
      ],
    },
    isAvailable: true,
  },
  {
    id: 285,
    imgSrc: "/images/products/handarmprotection46.jpg",
    imgHoverSrc: "/images/products/handarmprotection46.jpg",
    title: "5315",
    sizes: ["11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Baumwollhandschuhe",
    description: {
      images: ["/images/description_product/Baumwollhandschuhe/1.png"],
      product_details: ["-"],
      further_information: [
        "Material: Baumwoll-Trikot, weiß, gebleicht",
        "Beschichtung: -",
        "Größe(n): 12",
        "Verpackungseinheit: 600 Paar",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Baumwoll-Trikot-Handschuhe, weiß (Farbcode: 1100), gebleicht, mit Schichtel",
      ],
    },
    isAvailable: true,
  },
  {
    id: 286,
    imgSrc: "/images/products/handarmprotection47.jpg",
    imgHoverSrc: "/images/products/handarmprotection47.jpg",
    title: "5316",
    sizes: ["13"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Baumwollhandschuhe",
    description: {
      images: ["/images/description_product/Baumwollhandschuhe/1.png"],
      product_details: ["-"],
      further_information: [
        "Material: Baumwoll-Trikot, weiß, gebleicht",
        "Beschichtung: -",
        "Größe(n): 12",
        "Verpackungseinheit: 600 Paar",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Baumwoll-Trikot-Handschuhe, weiß (Farbcode: 1100), gebleicht, mit Schichtel",
      ],
    },
    isAvailable: true,
  },
  {
    id: 287,
    imgSrc: "/images/products/handarmprotection48.jpg",
    imgHoverSrc: "/images/products/handarmprotection48.jpg",
    title: "5320",
    sizes: ["7"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Baumwollhandschuhe",
    description: {
      images: ["/images/description_product/Baumwollhandschuhe/1.png"],
      product_details: ["-"],
      further_information: [
        "Material: Baumwoll-Trikot, weiß, gebleicht",
        "Beschichtung: -",
        "Größe(n): 7",
        "Verpackungseinheit: 300 Paar",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Baumwoll-Trikot-Handschuhe, weiß (Farbcode: 1100), gebleicht, doppelte Innenhand, mit Schichtel",
      ],
    },
    isAvailable: true,
  },
  {
    id: 288,
    imgSrc: "/images/products/handarmprotection49.jpg",
    imgHoverSrc: "/images/products/handarmprotection49.jpg",
    title: "5321",
    sizes: ["8"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Baumwollhandschuhe",
    description: {
      images: ["/images/description_product/Baumwollhandschuhe/1.png"],
      product_details: ["-"],
      further_information: [
        "Material: Baumwoll-Trikot, weiß, gebleicht",
        "Beschichtung: -",
        "Größe(n): 8",
        "Verpackungseinheit: 300 Paar",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Baumwoll-Trikot-Handschuhe, weiß (Farbcode: 1100), gebleicht, doppelte Innenhand, mit Schichtel",
      ],
    },
    isAvailable: true,
  },
  {
    id: 289,
    imgSrc: "/images/products/handarmprotection50.jpg",
    imgHoverSrc: "/images/products/handarmprotection50.jpg",
    title: "5322",
    sizes: ["9"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Baumwollhandschuhe",
    description: {
      images: ["/images/description_product/Baumwollhandschuhe/1.png"],
      product_details: ["-"],
      further_information: [
        "Material: Baumwoll-Trikot, weiß, gebleicht",
        "Beschichtung: -",
        "Größe(n): 9",
        "Verpackungseinheit: 300 Paar",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Baumwoll-Trikot-Handschuhe, weiß (Farbcode: 1100), gebleicht, doppelte Innenhand, mit Schichtel",
      ],
    },
    isAvailable: true,
  },
  {
    id: 290,
    imgSrc: "/images/products/handarmprotection50.jpg",
    imgHoverSrc: "/images/products/handarmprotection50.jpg",
    title: "5323",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Baumwollhandschuhe",
    description: {
      images: ["/images/description_product/Baumwollhandschuhe/1.png"],
      product_details: ["-"],
      further_information: [
        "Material: Baumwoll-Trikot, weiß, gebleicht",
        "Beschichtung: -",
        "Größe(n): 9",
        "Verpackungseinheit: 300 Paar",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Baumwoll-Trikot-Handschuhe, weiß (Farbcode: 1100), gebleicht, doppelte Innenhand, mit Schichtel",
      ],
    },
    isAvailable: true,
  },
  {
    id: 291,
    imgSrc: "/images/products/handarmprotection51.jpg",
    imgHoverSrc: "/images/products/handarmprotection51.jpg",
    title: "20535 // SAFE",
    sizes: ["9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Schweißerhandschuhe",
    description: {
      images: [
        "/images/description_product/Schweißerhandschuhe/1.png",
        "/images/description_product/Schweißerhandschuhe/2.png",
        "/images/description_product/Schweißerhandschuhe/3.png",
        // "/images/description_product/Schweißerhandschuhe/4.png",
        "/images/description_product/Schweißerhandschuhe/5.png",
      ],
      product_details: ["EN 12477, 413X4XA, EN 388, 3143X, EN ISO 21420"],
      further_information: [
        "Material: Spaltleder, grau",
        "Beschichtung: -",
        "Größe(n): 9-11",
        "Verpackungseinheit: 60 Paar",
        "Zertifizierung: EN 388, EN 12477",
        "Beschreibung: NITRAS SAFE, Schweißerhandschuhe (amerikanischer Sicherheitsschweißer), Spaltleder, grau (Farbcode: 1200), Länge 35 cm, innen voll gefüttert, Schutz gegen Kontaktwärme (100° C, 15 Sekunden), langlebig, robust, gute Griffigkeit, PSA-Risikokategorie II",
      ],
    },
    isAvailable: true,
  },
  {
    id: 292,
    imgSrc: "/images/products/handarmprotection52.jpg",
    imgHoverSrc: "/images/products/handarmprotection52.jpg",
    title: "5324",
    sizes: ["11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Schweißerhandschuhe",
    description: {
      images: ["/images/description_product/Baumwollhandschuhe/1.png"],
      product_details: ["EN 12477, 413X4XA, EN 388, 3143X, EN ISO 21420"],
      further_information: [
        "Material: Spaltleder, grau",
        "Beschichtung: -",
        "Größe(n): 9-11",
        "Verpackungseinheit: 60 Paar",
        "Zertifizierung: EN 388, EN 12477",
        "Beschreibung: NITRAS SAFE, Schweißerhandschuhe (amerikanischer Sicherheitsschweißer), Spaltleder, grau (Farbcode: 1200), Länge 35 cm, innen voll gefüttert, Schutz gegen Kontaktwärme (100° C, 15 Sekunden), langlebig, robust, gute Griffigkeit, PSA-Risikokategorie II",
      ],
    },
    isAvailable: true,
  },
  {
    id: 293,
    imgSrc: "/images/products/handarmprotection53.jpg",
    imgHoverSrc: "/images/products/handarmprotection53.jpg",
    title: "20535PK // SAFE PRO",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Schweißerhandschuhe",
    description: {
      images: [
        "/images/description_product/Schweißerhandschuhe/1.png",
        "/images/description_product/Schweißerhandschuhe/2.png",
        "/images/description_product/Schweißerhandschuhe/3.png",
        // "/images/description_product/Schweißerhandschuhe/4.png",
        // "/images/description_product/Schweißerhandschuhe/5.png",
        "/images/description_product/Schweißerhandschuhe/6.png",
      ],
      product_details: ["EN 12477, 413X4XA, EN 388, 4133X, EN 420"],
      further_information: [
        "Material: Baumwoll-Trikot, weiß, gebleicht",
        "Beschichtung: -",
        "Größe(n): 11",
        "Verpackungseinheit: 300 Paar",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Baumwoll-Trikot-Handschuhe, weiß (Farbcode: 1100), gebleicht, doppelte Innenhand, mit Schichtel",
      ],
    },
    isAvailable: true,
  },
  {
    id: 294,
    imgSrc: "/images/products/handarmprotection54.jpg",
    imgHoverSrc: "/images/products/handarmprotection54.jpg",
    title: "5325",
    sizes: ["12"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Baumwollhandschuhe",
    description: {
      images: ["/images/description_product/Baumwollhandschuhe/1.png"],
      product_details: ["EN 12477, 413X4XA, EN 388, 4133X, EN 420"],
      further_information: [
        "Material: Baumwoll-Trikot, weiß, gebleicht",
        "Beschichtung: -",
        "Größe(n): 12",
        "Verpackungseinheit: 300 Paar",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Baumwoll-Trikot-Handschuhe, weiß (Farbcode: 1100), gebleicht, doppelte Innenhand, mit Schichtel",
      ],
    },
    isAvailable: true,
  },
  {
    id: 295,
    imgSrc: "/images/products/handarmprotection55.jpg",
    imgHoverSrc: "/images/products/handarmprotection55.jpg",
    title: "5400",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Baumwollhandschuhe",
    description: {
      images: ["/images/description_product/Baumwollhandschuhe/1.png"],
      product_details: ["-"],
      further_information: [
        "Material: Baumwoll-Köper, naturfarben",
        "Beschichtung: -",
        "Größe(n): 10",
        "Verpackungseinheit: 300 Paar",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Baumwoll-Köper-Handschuhe, naturfarben (Farbcode: 1600), Strickbund, Herrengröße",
      ],
    },
    isAvailable: true,
  },
  {
    id: 296,
    imgSrc: "/images/products/handarmprotection56.jpg",
    imgHoverSrc: "/images/products/handarmprotection56.jpg",
    title: "5410",
    sizes: ["/"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Baumwollhandschuhe",
    description: {
      images: ["/images/description_product/Baumwollhandschuhe/1.png"],
      product_details: ["-"],
      further_information: [
        "Material: Baumwoll-Segeltuch, naturfarben",
        "Beschichtung: -",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 60 Paar",
        "Zertifizierung: -",
        "Beschreibung: NITRAS Fauster aus Baumwoll-Segeltuch, naturfarben (Farbcode: 1600), Futter gedoppelt, 3-lagig, Einheitsgröße",
      ],
    },
    isAvailable: true,
  },
  {
    id: 297,
    imgSrc: "/images/products/handarmprotection57.jpg",
    imgHoverSrc: "/images/products/handarmprotection57.jpg",
    title: "6100",
    sizes: ["6", "7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Strickhandschuhe",
    description: {
      images: ["/images/description_product/Strickhandschuhe/1.png",
              "/images/description_product/Strickhandschuhe/2.png",
              "/images/description_product/Strickhandschuhe/5.png"
    ],
      product_details: ["EN 388, 2142X, EN ISO 21420"],
      further_information: [
        "Material: Nylon / Baumwolle, weiß",
        "Beschichtung: PVC-Noppen, blau",
        "Größe(n): 6-11",
        "Verpackungseinheit: 240 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Strickhandschuhe, Nylon / Baumwolle, weiß, PVC-Noppen, blau (Farbcode: 1122), genoppt auf Innenhand, Strickbund",
      ],
    },
    isAvailable: true,
  },
  {
    id: 298,
    imgSrc: "/images/products/handarmprotection58.jpg",
    imgHoverSrc: "/images/products/handarmprotection58.jpg",
    title: "6100ND",
    sizes: ["6", "7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Strickhandschuhe",
    description: {
      images: ["/images/description_product/Strickhandschuhe/1.png",
                "/images/description_product/Strickhandschuhe/2.png",
                "/images/description_product/Strickhandschuhe/4.png",
                "/images/description_product/Strickhandschuhe/6.png"],
      product_details: ["EN 388, 1141X, EN 407, EN 407, X1XXXX, EN ISO 21420"],
      further_information: [
        "Material: Nylon / Baumwolle, weiß",
        "Beschichtung: -",
        "Größe(n): 6-11",
        "Verpackungseinheit: 240 Paar",
        "Zertifizierung: EN 388, EN 407",
        "Beschreibung: NITRAS Strickhandschuhe, Nylon / Baumwolle, weiß (Farbcode: 1100), ohne Beschichtung, Strickbund, beidseitig tragbar (linke und rechte Hand), Schutz gegen Kontaktwärme (100° C, 15 Sekunden)",
      ],
    },
    isAvailable: true,
  },
  {
    id: 299,
    imgSrc: "/images/products/handarmprotection59.jpg",
    imgHoverSrc: "/images/products/handarmprotection59.jpg",
    title: "6101",
    sizes: ["6", "7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Strickhandschuhe",
    description: {
      images: ["/images/description_product/Strickhandschuhe/1.png",
              "/images/description_product/Strickhandschuhe/2.png",
              "/images/description_product/Strickhandschuhe/5.png"],
      product_details: ["EN 388, 1141X, EN 407, X1XXXX, EN ISO 21420"],
      further_information: [
        "Material: Nylon / Baumwolle, blau",
        "Beschichtung: PVC-Noppen, blau",
        "Größe(n): 6-11",
        "Verpackungseinheit: 240 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Strickhandschuhe, Nylon / Baumwolle, blau, PVC-Noppen, blau (Farbcode: 2200), genoppt auf Innenhand, Strickbund",
      ],
    },
    isAvailable: true,
  },
  {
    id: 300,
    imgSrc: "/images/products/handarmprotection60.jpg",
    imgHoverSrc: "/images/products/handarmprotection60.jpg",
    title: "6200",
    sizes: ["6", "7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Strickhandschuhe",
    description: {
      images: ["/images/description_product/Strickhandschuhe/1.png",
      "/images/description_product/Strickhandschuhe/2.png",
      "/images/description_product/Strickhandschuhe/3.png"
    ],
      product_details: ["EN 388, 1141X, EN 407, X1XXXX, EN ISO 21420"],
      further_information: [
        "Material: Nylon / Baumwolle, blau",
        "Beschichtung: PVC-Noppen, blau",
        "Größe(n): 6-11",
        "Verpackungseinheit: 240 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Strickhandschuhe, Nylon / Baumwolle, blau, PVC-Noppen, blau (Farbcode: 2200), genoppt auf Innenhand, Strickbund",
    ],    
    },
    isAvailable: true,
  },
  {
    id: 301,
    imgSrc: "/images/products/handarmprotection62.jpg",
    imgHoverSrc: "/images/products/handarmprotection62.jpg",
    title: "6205",
    sizes: ["6", "7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Strickhandschuhe",
    description: {
      images: ["/images/description_product/Strickhandschuhe/1.png",
               "/images/description_product/Strickhandschuhe/7.png",
               "/images/description_product/Strickhandschuhe/8.png"
          ],
      product_details: ["EN 388, 4131X, EN 420, EN ISO 21420"],
      further_information: [
        "Material: Nylon, grau",
        "Beschichtung: PU, grau",
        "Größe(n): 6-11",
        "Verpackungseinheit: 240 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Nylonhandschuhe, grau, PU-Beschichtung, grau (Farbcode: 1200), teilbeschichtet auf Innenhand und Fingerkuppen, Strickbund",
    ],     
    },
    isAvailable: true,
  },
  {
    id: 302,
    imgSrc: "/images/products/handarmprotection63.jpg",
    imgHoverSrc: "/images/products/handarmprotection63.jpg",
    title: "3200 // ARGON",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Schweißerhandschuhe",
    description: {
      images: ["/images/description_product/Schweißerhandschuhe/1.png",
               "/images/description_product/Schweißerhandschuhe/2.png",
               "/images/description_product/Schweißerhandschuhe/3.png",
               "/images/description_product/Schweißerhandschuhe/4.png",
          ],
      product_details: ["EN 388, 4131X, EN 420, EN ISO 21420"],
      further_information: [
        "Material: Nappaleder, grau | Spaltleder, grau",
        "Beschichtung: -",
        "Größe(n): 10",
        "Verpackungseinheit: 150 Paar",
        "Zertifizierung: EN 388, EN 12477",
        "Beschreibung: NITRAS ARGON, Schweißerhandschuhe, grau, Stulpe aus Spaltleder, grau (Farbcode: 1200), Länge 35 cm, Kevlar-Nähte, Schutz gegen Kontaktwärme (100 °C, 15 Sekunden), besonders feinfühlig, perfekt für Arbeiten mit kleinen Teilen, besonders geschmeidig und widerstandsfähig, langlebig, robust, PSA Risikokategorie II",
    ],     
    },
    isAvailable: true,
  },
  {
    id: 303,
    imgSrc: "/images/products/handarmprotection64.jpg",
    imgHoverSrc: "/images/products/handarmprotection64.jpg",
    title: "6210",
    sizes: ["6", "7", "8", "9", "10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Knitted gloves",
    description: {
      images: ["/images/description_product/Strickhandschuhe/1.png",
                "/images/description_product/Strickhandschuhe/7.png",
               "/images/description_product/Strickhandschuhe/8.png",
          ],
      product_details: ["EN 388, 4131X, EN 420, EN ISO 21420"],
      further_information: [
        "Material: Nylon, white",
        "Coating: PU, white",
        "Size(s): 6-10",
        "Packaging unit: 240 pair(s)",
        "Certification: EN 388",
        "Description: NITRAS nylon gloves, white, PU coating, white (colour code: 1100), partly coated on fingertips, knitted wrist",
    ],    
    },
    isAvailable: true,
  },
  {
    id: 304,
    imgSrc: "/images/products/handarmprotection65.jpg",
    imgHoverSrc: "/images/products/handarmprotection65.jpg",
    title: "3201 // ARGON",
    sizes: ["11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Schweißerhandschuhe",
    description: {
      images: ["/images/description_product/Schweißerhandschuhe/1.png",
               "/images/description_product/Schweißerhandschuhe/2.png",
               "/images/description_product/Schweißerhandschuhe/3.png",
               "/images/description_product/Schweißerhandschuhe/4.png",
          ],
      product_details: ["EN 388, 4131X, EN 420, EN ISO 21420"],
      further_information: [
        "Material: Nappa leather, grey | Split leather, grey",
        "Coating: -",
        "Size(s): 11",
        "Packaging unit: 150 pair(s)",
        "Certification: EN 388, EN 12477",
        "Description: NITRAS ARGON, welding gloves, grey, split leather cuff, grey (colour code: 1200), length 35 cm, Kevlar seams, protection against contact heat (100° C, 15 seconds), particularly sensitive, perfect for working with small parts, particularly supple and resistant, durable, robust, PPE risk category II",
    ],         
    },
    isAvailable: true,
  },
  {
    id: 305,
    imgSrc: "/images/products/handarmprotection66.jpg",
    imgHoverSrc: "/images/products/handarmprotection66.jpg",
    title: "6215",
    sizes: ["6", "7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Knitted gloves",
    description: {
      images: ["/images/description_product/Strickhandschuhe/1.png",
               "/images/description_product/Strickhandschuhe/4.png",
               "/images/description_product/Strickhandschuhe/7.png",
               "/images/description_product/Strickhandschuhe/8.png",
          ],
      product_details: ["EN 388, 4131X, EN 420, EN ISO 21420"],
      further_information: [
        "Material: Nylon, black",
        "Coating: PU, black",
        "Size(s): 6-11",
        "Packaging unit: 240 pair(s)",
        "Certification: EN 388",
        "Description: NITRAS nylon gloves, black, PU coating, black (colour code 1000), partly coated on palm and fingertips, knitted wrist",
    ],         
    },
    isAvailable: true,
  },
  {
    id: 306,
    imgSrc: "/images/products/handarmprotection67.jpg",
    imgHoverSrc: "/images/products/handarmprotection67.jpg",
    title: "3202 // ARGON",
    sizes: ["9"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Schweißerhandschuhe",
    description: {
      images: ["/images/description_product/Schweißerhandschuhe/1.png",
               "/images/description_product/Schweißerhandschuhe/2.png",
               "/images/description_product/Schweißerhandschuhe/3.png",
               "/images/description_product/Schweißerhandschuhe/4.png",
          ],
      product_details: ["EN 388, 4131X, EN 420, EN ISO 21420"],
      further_information: [
        "Material: Nappa leather, grey | Split leather, grey",
        "Coating: -",
        "Size(s): 9",
        "Packaging unit: 150 pair(s)",
        "Certification: EN 388, EN 12477",
        "Description: NITRAS ARGON, welding gloves, grey, split leather cuff, grey (colour code: 1200), length 35 cm, Kevlar seams, protection against contact heat (100° C, 15 seconds), particularly sensitive, perfect for working with small parts, particularly supple and resistant, durable, robust, PPE risk category II",
    ],        
    },
    isAvailable: true,
  },
  {
    id: 307,
    imgSrc: "/images/products/handarmprotection68.jpg",
    imgHoverSrc: "/images/products/handarmprotection68.jpg",
    title: "6220",
    sizes: ["6", "7", "8", "9", "10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Knitted gloves",
    description: {
      images: ["/images/description_product/Strickhandschuhe/1.png",
               "/images/description_product/Strickhandschuhe/4.png",
               "/images/description_product/Strickhandschuhe/7.png",
               "/images/description_product/Strickhandschuhe/6.png",
               "/images/description_product/Strickhandschuhe/9.png",
          ],
      product_details: ["EN 388, 0131X, EN ISO 21420"],
      further_information: [
        "Material: Nylon, white",
        "Coating: -",
        "Size(s): 6-10",
        "Packaging unit: 240 pair(s)",
        "Certification: EN 388",
        "Description: NITRAS nylon gloves, white (colour code: 1100), uncoated, knitted wrist, particularly sensitive, perfect for working with small parts, very good tactile sensitivity, breathable, PPE risk category II",
    ],     
    },
    isAvailable: true,
  },
  {
    id: 308,
    imgSrc: "/images/products/handarmprotection69.jpg",
    imgHoverSrc: "/images/products/handarmprotection69.jpg",
    title: "3203 // ARGON",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Knitted gloves",
    description: {
      images: ["/images/description_product/Strickhandschuhe/1.png",
               "/images/description_product/Strickhandschuhe/4.png",
              //  "/images/description_product/Strickhandschuhe/7.png",
               "/images/description_product/Strickhandschuhe/6.png",
               "/images/description_product/Strickhandschuhe/9.png",
          ],
      product_details: ["EN 12477, 41324XB, EN 388, 2111X, EN 420"],
      further_information: [
        "Material: Nylon, white",
        "Coating: -",
        "Size(s): 6-10",
        "Packaging unit: 240 pair(s)",
        "Certification: EN 388",
        "Description: NITRAS nylon gloves, white (colour code: 1100), uncoated, knitted wrist, particularly sensitive, perfect for working with small parts, very good tactile sensitivity, breathable, PPE risk category II",
    ],     
    },
    isAvailable: true,
  },
  {
    id: 309,
    imgSrc: "/images/products/handarmprotection70.jpg",
    imgHoverSrc: "/images/products/handarmprotection70.jpg",
    title: "6230",
    sizes: ["5", "6", "7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Knitted gloves",
    description: {
      images: ["/images/description_product/Strickhandschuhe/1.png",
               "/images/description_product/Strickhandschuhe/4.png",
              //  "/images/description_product/Strickhandschuhe/7.png",
               "/images/description_product/Strickhandschuhe/6.png",
               "/images/description_product/Strickhandschuhe/9.png",
               "/images/description_product/Strickhandschuhe/10.png",
          ],
      product_details: ["EN 16350m, EN 16350, EN 388, 3141X, EN ISO 21420"],
      further_information: [
        "Material: Nylon-Carbon-Strick, grau",
        "Beschichtung: PU, weiß",
        "Größe(n): 5-11",
        "Verpackungseinheit: 240 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Antistatikhandschuhe, Nylon-Carbon-Strick, grau, eingearbeiteter Carbonfaden, PU-Beschichtung, weiß (Farbcode: 1211), teilbeschichtet auf Innenhand und Fingerkuppen, Strickbund, antistatisch (ableitfähig), touchscreenfähig, besonders feinfühlig, perfekt geeignet für Arbeiten mit kleinen Teilen, sehr gutes Tastempfinden, PSA-Risikokategorie II",
    ],    
    },
    isAvailable: true,
  },
  {
    id: 310,
    imgSrc: "/images/products/handarmprotection71.jpg",
    imgHoverSrc: "/images/products/handarmprotection71.jpg",
    title: "3220 // YELLOW CLEANER",
    sizes: ["7", "8", "9", "10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Chemikalienschutzhandschuhe",
    description: {
      images: ["/images/description_product/Chemikalienschutzhandschuhe/1.png",
               "/images/description_product/Chemikalienschutzhandschuhe/2.png",
               "/images/description_product/Chemikalienschutzhandschuhe/4.png",
               "/images/description_product/Chemikalienschutzhandschuhe/6.png",
               "/images/description_product/Chemikalienschutzhandschuhe/8.png",
          ],
      product_details: ["EN 388, 0010X, EN ISO 21420, ISO 374-1/Type A, KLMPST, ISO 374-5:2016"],
      further_information: [
        "Material: Latex, gelb",
        "Beschichtung: -",
        "Größe(n): 7-10",
        "Verpackungseinheit: 144 Paar",
        "Zertifizierung: EN 388, EN ISO 374",
        "Beschreibung: NITRAS YELLOW CLEANER, Chemikalienschutzhandschuhe, Latex, gelb (Farbcode: 4300), Länge 30 cm, velourisiert, Schutz gegen Mikroorganismen und Bakterien, für Lebensmittelkontakt, besonders feinfühlig, perfekt geeignet für Arbeiten mit kleinen Teilen, sehr rutschfest, sehr gutes Tastempfinden, sehr guter Trocken- und Nassgriff, PSA-Risikokategorie III",
    ],    
    },
    isAvailable: true,
  },
  {
    id: 311,
    imgSrc: "/images/products/handarmprotection72.jpg",
    imgHoverSrc: "/images/products/handarmprotection72.jpg",
    title: "6230T",
    sizes: ["7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Antistatikhandschuhe",
    description: {
      images: ["/images/description_product/Antistatikhandschuhe/1.png",
               "/images/description_product/Antistatikhandschuhe/2.png",
               "/images/description_product/Antistatikhandschuhe/3.png",
               "/images/description_product/Antistatikhandschuhe/4.png",
               "/images/description_product/Antistatikhandschuhe/5.png",
          ],
      product_details: ["EN 388, 0010X, EN ISO 21420, ISO 374-1/Type A, KLMPST, ISO 374-5:2016"],
      further_information: [
        "Material: Latex, gelb",
        "Beschichtung: -",
        "Größe(n): 7-10",
        "Verpackungseinheit: 144 Paar",
        "Zertifizierung: EN 388, EN ISO 374",
        "Beschreibung: NITRAS YELLOW CLEANER, Chemikalienschutzhandschuhe, Latex, gelb (Farbcode: 4300), Länge 30 cm, velourisiert, Schutz gegen Mikroorganismen und Bakterien, für Lebensmittelkontakt, besonders feinfühlig, perfekt geeignet für Arbeiten mit kleinen Teilen, sehr rutschfest, sehr gutes Tastempfinden, sehr guter Trocken- und Nassgriff, PSA-Risikokategorie III",
    ],    
    },
    isAvailable: true,
  },
  {
    id: 312,
    imgSrc: "/images/products/handarmprotection73.jpg",
    imgHoverSrc: "/images/products/handarmprotection73.jpg",
    title: "3221 // BLUE CLEANER",
    sizes: ["7", "8", "9", "10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Antistatikhandschuhe",
    description: {
      images: ["/images/description_product/Antistatikhandschuhe/1.png",
               "/images/description_product/Antistatikhandschuhe/2.png",
               "/images/description_product/Antistatikhandschuhe/3.png",
               "/images/description_product/Antistatikhandschuhe/4.png",
               "/images/description_product/Antistatikhandschuhe/5.png",
          ],
      product_details: ["EN 388, 0010X, EN ISO 21420, ISO 374-1/Type A, KLMPST, ISO 374-5:2016"],
      further_information: [
        "Material: Latex, blau",
        "Beschichtung: -",
        "Größe(n): 7-10",
        "Verpackungseinheit: 144 Paar",
        "Zertifizierung: EN 388, EN ISO 374",
        "Beschreibung: NITRAS BLUE CLEANER, Chemikalienschutzhandschuhe, Latex, blau (Farbcode: 2200), Länge 30 cm, velourisiert, Schutz gegen Mikroorganismen und Bakterien, für Lebensmittelkontakt, besonders feinfühlig, perfekt geeignet für Arbeiten mit kleinen Teilen, sehr rutschfest, sehr gutes Tastempfinden, sehr guter Trocken- und Nassgriff, PSA-Risikokategorie III",
    ],    
    },
    isAvailable: true,
  },
  {
    id: 313,
    imgSrc: "/images/products/handarmprotection74.jpg",
    imgHoverSrc: "/images/products/handarmprotection74.jpg",
    title: "6230UC",
    sizes: ["7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Antistatikhandschuhe",
    description: {
      images: ["/images/description_product/Antistatikhandschuhe/1.png",
               "/images/description_product/Antistatikhandschuhe/2.png",
               "/images/description_product/Antistatikhandschuhe/3.png",
               "/images/description_product/Antistatikhandschuhe/4.png",
               "/images/description_product/Antistatikhandschuhe/5.png",
          ],
      product_details: ["EN 388, 0010X, EN ISO 21420, ISO 374-1/Type A, KLMPST, ISO 374-5:2016"],
      further_information: [
        "Material: Nylon-Carbon-Strick, grau",
        "Beschichtung: -",
        "Größe(n): 5-11",
        "Verpackungseinheit: 240 Paar",
        "Zertifizierung: EN 388, EN 16350",
        "Beschreibung: NITRAS Antistatikhandschuhe, Nylon-Carbon-Strick, grau (Farbcode: 1200), eingearbeiteter Carbonfaden, ohne Beschichtung, Strickbund, antistatisch (ableitfähig), touchscreenfähig, besonders feinfühlig, perfekt geeignet für Arbeiten mit kleinen Teilen, sehr gutes Tastempfinden, PSA-Risikokategorie II",
    ],    
    },
    isAvailable: true,
  },
  {
    id: 314,
    imgSrc: "/images/products/handarmprotection76.jpg",
    imgHoverSrc: "/images/products/handarmprotection76.jpg",
    title: "6750",
    sizes: ["8", "10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Schnittschutzhandschuhe",
    description: {
      images: ["/images/description_product/Schnittschutzhandschuhe/1.png",
               "/images/description_product/Schnittschutzhandschuhe/2.png",
               "/images/description_product/Schnittschutzhandschuhe/3.png",
               "/images/description_product/Schnittschutzhandschuhe/4.png",
               "/images/description_product/Schnittschutzhandschuhe/5.png",
               "/images/description_product/Schnittschutzhandschuhe/6.png",
          ],
      product_details: ["EN 388, 3X42C, EN 407, X2XXXX, EN 420"],
      further_information: [
        "Material: TAEKI-Spezialgarn, gelb",
        "Beschichtung: -",
        "Größe(n): 8,10",
        "Verpackungseinheit: 100 Paar",
        "Zertifizierung: EN 388, EN 407",
        "Beschreibung: NITRAS TAEKI Schnittschutzhandschuhe, TAEKI-Spezialgarn, gelb (Farbcode: 4300), ohne Beschichtung, Strickbund, hoher Schnittschutz, Schutz gegen Kontaktwärme (250° C, 15 Sekunden), höchster Tragekomfort, höchste Fingerfertigkeit, schwere Ausführung, verstärkte Daumenbeuge",
    ],     
    },
    isAvailable: true,
  },
  {
    id: 315,
    imgSrc: "/images/products/handarmprotection77.jpg",
    imgHoverSrc: "/images/products/handarmprotection77.jpg",
    title: "6240 // SKIN",
    sizes: ["8", "10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Strickhandschuhe",
    description: {
      images: ["/images/description_product/Strickhandschuhe/1.png",
               "/images/description_product/Strickhandschuhe/8.png",
               "/images/description_product/Strickhandschuhe/10.png",
               "/images/description_product/Strickhandschuhe/11.png",
          ],
      product_details: ["EN 388, 3X42C, EN 407, X2XXXX, EN 420"],
      further_information: [
        "Material: TAEKI-Spezialgarn, gelb",
        "Beschichtung: -",
        "Größe(n): 8,10",
        "Verpackungseinheit: 100 Paar",
        "Zertifizierung: EN 388, EN 407",
        "Beschreibung: NITRAS TAEKI Schnittschutzhandschuhe, TAEKI-Spezialgarn, gelb (Farbcode: 4300), ohne Beschichtung, Strickbund, hoher Schnittschutz, Schutz gegen Kontaktwärme (250° C, 15 Sekunden), höchster Tragekomfort, höchste Fingerfertigkeit, schwere Ausführung, verstärkte Daumenbeuge",
    ],     
    },
    isAvailable: true,
  },
  {
    id: 316,
    imgSrc: "/images/products/handarmprotection78.jpg",
    imgHoverSrc: "/images/products/handarmprotection78.jpg",
    title: "3400X",
    sizes: ["7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Nitrilhandschuhe",
    description: {
      images: ["/images/description_product/Nitrilhandschuhe/1.png",
               "/images/description_product/Nitrilhandschuhe/2.png",
               "/images/description_product/Nitrilhandschuhe/4.png",
               "/images/description_product/Nitrilhandschuhe/5.png",
          ],
      product_details: ["EN 388, 4111X, EN ISO 21420"],
      further_information: [
        "Material: TAEKI-Spezialgarn, gelb",
        "Beschichtung: -",
        "Größe(n): 8,10",
        "Verpackungseinheit: 100 Paar",
        "Zertifizierung: EN 388, EN 407",
        "Beschreibung: NITRAS TAEKI Schnittschutzhandschuhe, TAEKI-Spezialgarn, gelb (Farbcode: 4300), ohne Beschichtung, Strickbund, hoher Schnittschutz, Schutz gegen Kontaktwärme (250° C, 15 Sekunden), höchster Tragekomfort, höchste Fingerfertigkeit, schwere Ausführung, verstärkte Daumenbeuge",
    ],     
    },
    isAvailable: true,
  },
  {
    id: 317,
    imgSrc: "/images/products/handarmprotection79.jpg",
    imgHoverSrc: "/images/products/handarmprotection79.jpg",
    title: "6300",
    sizes: ["6", "7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Nitrilhandschuhe",
    description: {
      images: ["/images/description_product/Nitrilhandschuhe/1.png",
               "/images/description_product/Nitrilhandschuhe/2.png",
               "/images/description_product/Nitrilhandschuhe/3.png",
               "/images/description_product/Nitrilhandschuhe/4.png",
          ],
      product_details: ["EN 388, 4X42C, EN ISO 21420"],
      further_information: [
        "Material: Spezialgarn, weiß",
        "Beschichtung: PU, weiß",
        "Größe(n): 6-11",
        "Verpackungseinheit: 100 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Schnittschutzhandschuhe, Spezialgarn, weiß, PU-Beschichtung, weiß (Farbcode: 1100), teilbeschichtet auf Innenhand und Fingerkuppen, Strickbund, hoher Schnittschutz, höchster Tragekomfort, für Lebensmittelkontakt, besonders feinfühlig, perfekt geeignet für Arbeiten mit kleinen Teilen, sehr gutes Tastempfinden, PSA-Risikokategorie II",
    ],      
    },
    isAvailable: true,
  },
  {
    id: 318,
    imgSrc: "/images/products/handarmprotection80.jpg",
    imgHoverSrc: "/images/products/handarmprotection80.jpg",
    title: "6305",
    sizes: ["6", "7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Schnittschutzhandschuhe",
    description: {
      images: ["/images/description_product/Nitrilhandschuhe/1.png",
               "/images/description_product/Nitrilhandschuhe/2.png",
               "/images/description_product/Nitrilhandschuhe/3.png",
               "/images/description_product/Nitrilhandschuhe/4.png",
          ],
      product_details: ["EN 388, 4X42C, EN ISO 21420"],
      further_information: [
        "Material: Spezialgarn, weiß",
        "Beschichtung: PU, weiß",
        "Größe(n): 6-11",
        "Verpackungseinheit: 100 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Schnittschutzhandschuhe, Spezialgarn, weiß, PU-Beschichtung, weiß (Farbcode: 1100), teilbeschichtet auf Innenhand und Fingerkuppen, Strickbund, hoher Schnittschutz, höchster Tragekomfort, für Lebensmittelkontakt, besonders feinfühlig, perfekt geeignet für Arbeiten mit kleinen Teilen, sehr gutes Tastempfinden, PSA-Risikokategorie II",
    ],      
    },
    isAvailable: true,
  },
  {
    id: 319,
    imgSrc: "/images/products/handarmprotection81.jpg",
    imgHoverSrc: "/images/products/handarmprotection81.jpg",
    title: "6315",
    sizes: ["5", "6", "7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Schnittschutzhandschuhe",
    description: {
      images: ["/images/description_product/Schnittschutzhandschuhe/3.png",
               "/images/description_product/Schnittschutzhandschuhe/7.png",
               "/images/description_product/Schnittschutzhandschuhe/8.png",
          ],
      product_details: ["EN 388, 4X42C, EN ISO 21420"],
      further_information: [
        "Material: Spezialgarn, weiß",
        "Beschichtung: PU, grau",
        "Größe(n): 6-11",
        "Verpackungseinheit: 100 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Schnittschutzhandschuhe, Spezialgarn, weiß, PU-Beschichtung, grau (Farbcode: 1100), teilbeschichtet auf Innenhand und Fingerkuppen, Strickbund, hoher Schnittschutz, höchster Tragekomfort, besonders feinfühlig, perfekt geeignet für Arbeiten mit kleinen Teilen, sehr gutes Tastempfinden, PSA-Risikokategorie II",
    ],    
    },
    isAvailable: true,
  },
  {
    id: 320,
    imgSrc: "/images/products/handarmprotection82.jpg",
    imgHoverSrc: "/images/products/handarmprotection82.jpg",
    title: "3410P",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Nitrilhandschuhe",
    description: {
      images: ["/images/description_product/Nitrilhandschuhe/1.png",
               "/images/description_product/Nitrilhandschuhe/2.png",
               "/images/description_product/Nitrilhandschuhe/5.png",
          ],
      product_details: ["EN 388, 4X42C, EN ISO 21420"],
      further_information: [
        "Material: Baumwoll-Jersey, weiß, gebleicht",
        "Beschichtung: Nitril, blau",
        "Größe(n): 10",
        "Verpackungseinheit: 144 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Nitrilhandschuhe, Baumwoll-Jersey, weiß, gebleicht, Nitril-Beschichtung, blau (Farbcode: 1211), 3/4-beschichtet, Strickbund, sehr weich, sehr angenehm zu tragen, Premium-Qualität",
    ],    
    },
    isAvailable: true,
  },
  {
    id: 321,
    imgSrc: "/images/products/handarmprotection83.jpg",
    imgHoverSrc: "/images/products/handarmprotection83.jpg",
    title: "6790",
    sizes: ["/"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Armschutz",
    description: {
      images: ["/images/description_product/Armschutz/1.png",
               "/images/description_product/Armschutz/2.png",
               "/images/description_product/Armschutz/5.png",
          ],
      product_details: ["EN 388, 3X41E, EN 407, X1XXXX, EN ISO 21420"],
      further_information: [
        "Material: TAEKI-Spezialgarn, gelb",
        "Beschichtung: -",
        "Größe(n): Einheitsgröße",
        "Verpackungseinheit: 100 Paar",
        "Zertifizierung: EN 388, EN 407",
        "Beschreibung: NITRAS TAEKI Schnittschutz-Armschoner, TAEKI-Spezialgarn, gelb (Farbcode: 4300), Länge 30 cm, sehr hoher Schnittschutz, Schutz gegen Kontaktwärme (100° C, 15 Sekunden), höchster Tragekomfort, PSA-Risikokategorie II",
    ],    
    },
    isAvailable: true,
  },
  {
    id: 322,
    imgSrc: "/images/products/handarmprotection84.jpg",
    imgHoverSrc: "/images/products/handarmprotection84.jpg",
    title: "3420P",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Armschutz",
    description: {
      images: ["/images/description_product/Armschutz/1.png",
               "/images/description_product/Armschutz/2.png",
               "/images/description_product/Armschutz/3.png",
          ],
      product_details: ["EN 388, 3X41E, EN 407, X1XXXX, EN ISO 21420"],
      further_information: [
        "Material: Baumwoll-Jersey, weiß, gebleicht",
        "Beschichtung: Nitril, blau",
        "Größe(n): 10",
        "Verpackungseinheit: 144 Paar",
      ],    
    },
    isAvailable: true,
  },
  {
    id: 323,
    imgSrc: "/images/products/handarmprotection85.jpg",
    imgHoverSrc: "/images/products/handarmprotection85.jpg",
    title: "3430P",
    sizes: ["10"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Nitrilhandschuhe",
    description: {
      images: ["/images/description_product/Nitrilhandschuhe/1.png",
               "/images/description_product/Nitrilhandschuhe/2.png",
               "/images/description_product/Nitrilhandschuhe/5.png",
          ],
      product_details: ["EN 388, 3X41E, EN 407, X1XXXX, EN ISO 21420"],
      further_information: [
        "Material: Baumwoll-Jersey, weiß, gebleicht",
        "Beschichtung: Nitril, blau",
        "Größe(n): 10",
        "Verpackungseinheit: 144 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Nitrilhandschuhe, Baumwoll-Jersey, weiß, gebleicht, Nitril-Beschichtung, blau, 3/4-beschichtet, Segeltuchstulpe, sehr weich, sehr angenehm zu tragen, Premium-Qualität",
    ],    
    },
    isAvailable: true,
  },
  {
    id: 324,
    imgSrc: "/images/products/handarmprotection86.jpg",
    imgHoverSrc: "/images/products/handarmprotection86.jpg",
    title: "3440P",
    sizes: ["10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Nitrilhandschuhe",
    description: {
      images: ["/images/description_product/Nitrilhandschuhe/1.png",
               "/images/description_product/Nitrilhandschuhe/2.png",
               "/images/description_product/Nitrilhandschuhe/5.png",
          ],
      product_details: ["EN 388, 3X41E, EN 407, X1XXXX, EN ISO 21420"],
      further_information: [
        "Material: Baumwoll-Jersey, weiß, gebleicht",
        "Beschichtung: Nitril, blau",
        "Größe(n): 10",
        "Verpackungseinheit: 144 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Nitrilhandschuhe, Baumwoll-Jersey, weiß, gebleicht, Nitril-Beschichtung, blau, 3/4-beschichtet, Segeltuchstulpe, sehr weich, sehr angenehm zu tragen, Premium-Qualität",
    ],    
    },
    isAvailable: true,
  },
  {
    id: 325,
    imgSrc: "/images/products/handarmprotection87.jpg",
    imgHoverSrc: "/images/products/handarmprotection87.jpg",
    title: "3450 // GREEN BARRIER",
    sizes: ["7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Chemikalienschutzhandschuhe",
    description: {
      images: ["/images/description_product/Chemikalienschutzhandschuhe/1.png",
               "/images/description_product/Chemikalienschutzhandschuhe/2.png",
               "/images/description_product/Chemikalienschutzhandschuhe/4.png",
               "/images/description_product/Chemikalienschutzhandschuhe/5.png",
               "/images/description_product/Chemikalienschutzhandschuhe/9.png",
          ],
      product_details: ["EN 388, 3X41E, EN 407, X1XXXX, EN ISO 21420"],
      further_information: [
        "Material: Nitril, grün",
        "Beschichtung: -",
        "Größe(n): 7-11",
        "Verpackungseinheit: 144 Paar",
        "Zertifizierung: EN 388, EN ISO 374",
        "Beschreibung: NITRAS GREEN BARRIER, Chemikalienschutzhandschuhe, Nitril, grün (Farbcode: 3000), Länge 33 cm, velourisiert, Schutz gegen Mikroorganismen, Bakterien und Viren, für Lebensmittelkontakt",
    ],     
    },
    isAvailable: true,
  },
  {
    id: 326,
    imgSrc: "/images/products/handarmprotection88.jpg",
    imgHoverSrc: "/images/products/handarmprotection88.jpg",
    title: "3451 // GREEN BARRIER FLEX",
    sizes: ["7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Chemikalienschutzhandschuhe",
    description: {
      images: ["/images/description_product/Chemikalienschutzhandschuhe/1.png",
               "/images/description_product/Chemikalienschutzhandschuhe/2.png",
               "/images/description_product/Chemikalienschutzhandschuhe/4.png",
               "/images/description_product/Chemikalienschutzhandschuhe/5.png",
               "/images/description_product/Chemikalienschutzhandschuhe/9.png",
          ],
      product_details: ["EN 388, 2101X, EN ISO 21420, ISO 374-1:2016-Type A, JKLMNO, ISO 374-5:2016, VIRUS"],
      further_information: [
        "Material: Nitril, grün",
        "Beschichtung: -",
        "Größe(n): 7-11",
        "Verpackungseinheit: 144 Paar",
        "Zertifizierung: EN 388, EN ISO 374",
        "Beschreibung: NITRAS GREEN BARRIER FLEX, Chemikalienschutzhandschuhe, Nitril, grün (Farbcode: 3000), Länge 33 cm, velourisiert, Schutz gegen Mikroorganismen, Bakterien und Viren, für Lebensmittelkontakt, besonders feinfühlig, perfekt geeignet für Arbeiten mit kleinen Teilen, sehr gutes Tastempfinden, sehr guter Trocken- und Ölgriff, sehr hohe Abriebfestigkeit, besonders robust und widerstandsfähig, PSA-Risikokategorie III",
    ],
    },
    isAvailable: true,
  },
  {
    id: 327,
    imgSrc: "/images/products/handarmprotection89.jpg",
    imgHoverSrc: "/images/products/handarmprotection89.jpg",
    title: "3455 // GREEN BARRIER GRIP",
    sizes: ["8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Chemikalienschutzhandschuhe",
    description: {
      images: ["/images/description_product/Chemikalienschutzhandschuhe/1.png",
               "/images/description_product/Chemikalienschutzhandschuhe/2.png",
               "/images/description_product/Chemikalienschutzhandschuhe/4.png",
               "/images/description_product/Chemikalienschutzhandschuhe/5.png",
               "/images/description_product/Chemikalienschutzhandschuhe/9.png",
          ],
      product_details: ["EN 388, 2101X, EN ISO 21420, ISO 374-1:2016-Type A, JKLMNO, ISO 374-5:2016, VIRUS"],
      further_information: [
        "Material: Baumwoll-Trikot, naturfarben",
        "Beschichtung: Nitril, petrol | Nitril, gesandet, schwarz",
        "Größe(n): 8-11",
        "Verpackungseinheit: 72 Paar",
        "Zertifizierung: EN 388, EN ISO 374",
        "Beschreibung: NITRAS GREEN BARRIER GRIP, Chemikalienschutzhandschuhe, Baumwoll-Trikot, naturfarben, mit zweifacher Beschichtung, untere Beschichtung: Nitril, grün, vollbeschichtet, obere Beschichtung: gesandetes Nitril, schwarz (Farbcode: 2510), teilbeschichtet auf Innenhand und Fingerkuppen, Länge 32 cm, sehr guter Trocken- und Ölgriff, Schutz gegen Mikroorganismen und Bakterien, für Lebensmittelkontakt, Premium-Qualität, besonders feinfühlig, perfekt geeignet für Arbeiten mit kleinen Teilen, sehr gutes Tastempfinden, sehr hoher Trocken- und Ölgriff, sehr hohe Abriebfestigkeit, besonders robust und widerstandsfähig, PSA-Risikokategorie III",
    ],    
    },
    isAvailable: true,
  },
  {
    id: 328,
    imgSrc: "/images/products/handarmprotection90.jpg",
    imgHoverSrc: "/images/products/handarmprotection90.jpg",
    title: "3460 // BLACK BARRIER",
    sizes: ["7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Chemikalienschutzhandschuhe",
    description: {
      images: ["/images/description_product/Chemikalienschutzhandschuhe/1.png",
               "/images/description_product/Chemikalienschutzhandschuhe/2.png",
               "/images/description_product/Chemikalienschutzhandschuhe/4.png",
               "/images/description_product/Chemikalienschutzhandschuhe/5.png",
               "/images/description_product/Chemikalienschutzhandschuhe/10.png",
          ],
      product_details: ["EN 388, 2101X, EN ISO 21420, ISO 374-1:2016-Type A, JKLMNO, ISO 374-5:2016, VIRUS"],
      further_information: [
        "Material: Baumwoll-Trikot, naturfarben",
        "Beschichtung: Nitril, petrol | Nitril, gesandet, schwarz",
        "Größe(n): 8-11",
        "Verpackungseinheit: 72 Paar",
        "Zertifizierung: EN 388, EN ISO 374",
        "Beschreibung: NITRAS GREEN BARRIER GRIP, Chemikalienschutzhandschuhe, Baumwoll-Trikot, naturfarben, mit zweifacher Beschichtung, untere Beschichtung: Nitril, grün, vollbeschichtet, obere Beschichtung: gesandetes Nitril, schwarz (Farbcode: 2510), teilbeschichtet auf Innenhand und Fingerkuppen, Länge 32 cm, sehr guter Trocken- und Ölgriff, Schutz gegen Mikroorganismen und Bakterien, für Lebensmittelkontakt, Premium-Qualität, besonders feinfühlig, perfekt geeignet für Arbeiten mit kleinen Teilen, sehr gutes Tastempfinden, sehr hoher Trocken- und Ölgriff, sehr hohe Abriebfestigkeit, besonders robust und widerstandsfähig, PSA-Risikokategorie III",
    ],    
    },
    isAvailable: true,
  },
  {
    id: 329,
    imgSrc: "/images/products/handarmprotection91.jpg",
    imgHoverSrc: "/images/products/handarmprotection91.jpg",
    title: "3470 // DUAL BARRIER",
    sizes: ["7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Chemikalienschutzhandschuhe",
    description: {
      images: ["/images/description_product/Chemikalienschutzhandschuhe/1.png",
               "/images/description_product/Chemikalienschutzhandschuhe/2.png",
               "/images/description_product/Chemikalienschutzhandschuhe/4.png",
               "/images/description_product/Chemikalienschutzhandschuhe/5.png",
               "/images/description_product/Chemikalienschutzhandschuhe/6.png",
               "/images/description_product/Chemikalienschutzhandschuhe/10.png",
          ],
      product_details: ["EN 388, 2101X, EN ISO 21420, ISO 374-1:2016-Type A, JKLMNO, ISO 374-5:2016, VIRUS"],
      further_information: [
        "Material: Latex, gelb | Chloropren, blau",
        "Beschichtung: -",
        "Größe(n): 7-11",
        "Verpackungseinheit: 144 Paar",
        "Zertifizierung: EN 388, EN ISO 374",
        "Beschreibung: NITRAS DUAL BARRIER, Chemikalienschutzhandschuhe, Latex, gelb, Chloropren, blau (Farbcode: 2243), Länge 30 cm, velourisiert, Schutz gegen Mikroorganismen und Bakterien, für Lebensmittelkontakt, besonders feinfühlig, perfekt geeignet für Arbeiten mit kleinen Teilen, sehr gutes Tastempfinden, sehr guter Trocken- und Nassgriff, beständig gegen Öle und Fette, flexibel, PSA-Risikokategorie III",
    ],    
    },
    isAvailable: true,
  },
  {
    id: 330,
    imgSrc: "/images/products/handarmprotection92.jpg",
    imgHoverSrc: "/images/products/handarmprotection92.jpg",
    title: "3500",
    sizes: ["6", "7", "8", "9", "10", "11"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: " Armschutz",
    subcategory: "Strickhandschuhe",
    description: {
      images: ["/images/description_product/Chemikalienschutzhandschuhe/1.png",
               "/images/description_product/Chemikalienschutzhandschuhe/7.png",
               "/images/description_product/Chemikalienschutzhandschuhe/12.png",
          ],
      product_details: ["EN 399, EN420, 4131X"],
      further_information: [
        "Material: Nylon, schwarz",
        "Beschichtung: Nitril, schwarz",
        "Größe(n): 6-11",
        "Verpackungseinheit: 144 Paar",
        "Zertifizierung: EN 388",
        "Beschreibung: NITRAS Nylonhandschuhe, schwarz, Nitril-Beschichtung, schwarz (Farbcode: 1000), teilbeschichtet auf Innenhand und Fingerkuppen, Strickbund",
    ],      
    },
    isAvailable: true,
  },
  {
    id: 331,
    imgSrc: "/images/products/handarmprotection93.jpg",
    imgHoverSrc: "/images/products/handarmprotection93.jpg",
    title: "3510",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 332,
    imgSrc: "/images/products/handarmprotection94.jpg",
    imgHoverSrc: "/images/products/handarmprotection94.jpg",
    title: "3515 // NEON",
    sizes: ["7", "8", "9", "10", "11", "12"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 333,
    imgSrc: "/images/products/handarmprotection95.jpg",
    imgHoverSrc: "/images/products/handarmprotection95.jpg",
    title: "3520 // NYLOTEX",
    sizes: ["7", "8", "9", "10", "11", "12"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 334,
    imgSrc: "/images/products/handarmprotection96.jpg",
    imgHoverSrc: "/images/products/handarmprotection96.jpg",
    title: "3521",
    sizes: ["7", "8", "9", "10", "11", "12"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 335,
    imgSrc: "/images/products/handarmprotection97.jpg",
    imgHoverSrc: "/images/products/handarmprotection97.jpg",
    title: "3550 // OIL GRIP",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 336,
    imgSrc: "/images/products/handarmprotection98.jpg",
    imgHoverSrc: "/images/products/handarmprotection98.jpg",
    title: "3560 // SOFT GRIP",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 337,
    imgSrc: "/images/products/handarmprotection99.jpg",
    imgHoverSrc: "/images/products/handarmprotection99.jpg",
    title: "3560W // SOFT GRIP W",
    sizes: ["8", "9", "10", "11"],
    subcategory: "Winter gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 338,
    imgSrc: "/images/products/handarmprotection100.jpg",
    imgHoverSrc: "/images/products/handarmprotection100.jpg",
    title: "5010",
    sizes: ["10"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 339,
    imgSrc: "/images/products/handarmprotection101.jpg",
    imgHoverSrc: "/images/products/handarmprotection101.jpg",
    title: "5011",
    sizes: ["8"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 340,
    imgSrc: "/images/products/handarmprotection102.jpg",
    imgHoverSrc: "/images/products/handarmprotection102.jpg",
    title: "5102",
    sizes: ["10"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 341,
    imgSrc: "/images/products/handarmprotection103.jpg",
    imgHoverSrc: "/images/products/handarmprotection103.jpg",
    title: "6330",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 342,
    imgSrc: "/images/products/handarmprotection104.jpg",
    imgHoverSrc: "/images/products/handarmprotection104.jpg",
    title: "6340 // SKIN FLEX CUT3",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 343,
    imgSrc: "/images/products/handarmprotection105.jpg",
    imgHoverSrc: "/images/products/handarmprotection105.jpg",
    title: "6791",
    sizes: ["/"],
    subcategory: "Arm protection",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 344,
    imgSrc: "/images/products/handarmprotection106.jpg",
    imgHoverSrc: "/images/products/handarmprotection106.jpg",
    title: "6350 // CUT3",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 345,
    imgSrc: "/images/products/handarmprotection107.jpg",
    imgHoverSrc: "/images/products/handarmprotection107.jpg",
    title: "6795",
    sizes: ["/"],
    subcategory: "Arm protection",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 346,
    imgSrc: "/images/products/handarmprotection108.jpg",
    imgHoverSrc: "/images/products/handarmprotection108.jpg",
    title: "6355 // CUT3 NF",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 347,
    imgSrc: "/images/products/handarmprotection109.jpg",
    imgHoverSrc: "/images/products/handarmprotection109.jpg",
    title: "6796",
    sizes: ["/"],
    subcategory: "Arm protection",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 348,
    imgSrc: "/images/products/handarmprotection110.jpg",
    imgHoverSrc: "/images/products/handarmprotection110.jpg",
    title: "6360 // OIL GRIP CUT",
    sizes: ["7", "8", "9", "10", "11", "12"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 349,
    imgSrc: "/images/products/handarmprotection111.jpg",
    imgHoverSrc: "/images/products/handarmprotection111.jpg",
    title: "6806 // CUT F",
    sizes: ["7", "8", "9", "10", "11", "12"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 350,
    imgSrc: "/images/products/handarmprotection112.jpg",
    imgHoverSrc: "/images/products/handarmprotection112.jpg",
    title: "6605 // CUT5",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 351,
    imgSrc: "/images/products/handarmprotection113.jpg",
    imgHoverSrc: "/images/products/handarmprotection113.jpg",
    title: "6835 // CUT F PRO",
    sizes: ["6", "7", "8", "9", "10", "11", "12"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 352,
    imgSrc: "/images/products/handarmprotection114.jpg",
    imgHoverSrc: "/images/products/handarmprotection114.jpg",
    title: "6640 // SKIN FLEX CUT5",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 353,
    imgSrc: "/images/products/handarmprotection115.jpg",
    imgHoverSrc: "/images/products/handarmprotection115.jpg",
    title: "6655 // STEEL",
    sizes: ["9", "10", "11"],
    subcategory: "Puncture protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 354,
    imgSrc: "/images/products/handarmprotection116.jpg",
    imgHoverSrc: "/images/products/handarmprotection116.jpg",
    title: "6705",
    sizes: ["5", "6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 355,
    imgSrc: "/images/products/handarmprotection117.jpg",
    imgHoverSrc: "/images/products/handarmprotection117.jpg",
    title: "6706",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 356,
    imgSrc: "/images/products/handarmprotection118.jpg",
    imgHoverSrc: "/images/products/handarmprotection118.jpg",
    title: "6710",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 357,
    imgSrc: "/images/products/handarmprotection119.jpg",
    imgHoverSrc: "/images/products/handarmprotection119.jpg",
    title: "6720",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 358,
    imgSrc: "/images/products/handarmprotection120.jpg",
    imgHoverSrc: "/images/products/handarmprotection120.jpg",
    title: "6720W // WINTER CUT",
    sizes: ["8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 359,
    imgSrc: "/images/products/handarmprotection121.jpg",
    imgHoverSrc: "/images/products/handarmprotection121.jpg",
    title: "8300 // SUPREME NITRILE",
    sizes: ["S", "M", "L", "XL", "2XL"],
    subcategory: "Disposable gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 360,
    imgSrc: "/images/products/handarmprotection122.jpg",
    imgHoverSrc: "/images/products/handarmprotection122.jpg",
    title: "6726",
    sizes: ["9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 361,
    imgSrc: "/images/products/handarmprotection123.jpg",
    imgHoverSrc: "/images/products/handarmprotection123.jpg",
    title: "6730",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 362,
    imgSrc: "/images/products/handarmprotection124.jpg",
    imgHoverSrc: "/images/products/handarmprotection124.jpg",
    title: "6735",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 363,
    imgSrc: "/images/products/handarmprotection125.jpg",
    imgHoverSrc: "/images/products/handarmprotection125.jpg",
    title: "5103",
    sizes: ["8"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 364,
    imgSrc: "/images/products/handarmprotection126.jpg",
    imgHoverSrc: "/images/products/handarmprotection126.jpg",
    title: "5202",
    sizes: ["10"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 365,
    imgSrc: "/images/products/handarmprotection127.jpg",
    imgHoverSrc: "/images/products/handarmprotection127.jpg",
    title: "5203",
    sizes: ["8"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 366,
    imgSrc: "/images/products/handarmprotection128.jpg",
    imgHoverSrc: "/images/products/handarmprotection128.jpg",
    title: "5210",
    sizes: ["10"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 367,
    imgSrc: "/images/products/handarmprotection129.jpg",
    imgHoverSrc: "/images/products/handarmprotection129.jpg",
    title: "5211",
    sizes: ["8"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 368,
    imgSrc: "/images/products/handarmprotection130.jpg",
    imgHoverSrc: "/images/products/handarmprotection130.jpg",
    title: "5309",
    sizes: ["6"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 369,
    imgSrc: "/images/products/handarmprotection131.jpg",
    imgHoverSrc: "/images/products/handarmprotection131.jpg",
    title: "8330 // TOUGH GRIP N",
    sizes: ["S", "M", "L", "XL", "2XL"],
    subcategory: "Disposable gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 370,
    imgSrc: "/images/products/handarmprotection132.jpg",
    imgHoverSrc: "/images/products/handarmprotection132.jpg",
    title: "8335 // TOUGH GRIP N",
    sizes: ["S", "M", "L", "XL", "2XL"],
    subcategory: "Disposable gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 371,
    imgSrc: "/images/products/handarmprotection133.jpg",
    imgHoverSrc: "/images/products/handarmprotection133.jpg",
    title: "8340 // TOUGH GRIP N 300",
    sizes: ["S", "M", "L", "XL", "2XL"],
    subcategory: "Disposable gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 372,
    imgSrc: "/images/products/handarmprotection134.jpg",
    imgHoverSrc: "/images/products/handarmprotection134.jpg",
    title: "8700 // SKIN FLEX 3D",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 373,
    imgSrc: "/images/products/handarmprotection135.jpg",
    imgHoverSrc: "/images/products/handarmprotection135.jpg",
    title: "8710 // SKIN FLEX V",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 374,
    imgSrc: "/images/products/handarmprotection136.jpg",
    imgHoverSrc: "/images/products/handarmprotection136.jpg",
    title: "8711 // SKIN FLEX 3D",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 375,
    imgSrc: "/images/products/handarmprotection137.jpg",
    imgHoverSrc: "/images/products/handarmprotection137.jpg",
    title: "8720 // SKIN CLEAN",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 376,
    imgSrc: "/images/products/handarmprotection138.jpg",
    imgHoverSrc: "/images/products/handarmprotection138.jpg",
    title: "8800 // FLEXIBLE FIT",
    sizes: ["6", "7", "8", "9", "10", "11", "12"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 377,
    imgSrc: "/images/products/handarmprotection139.jpg",
    imgHoverSrc: "/images/products/handarmprotection139.jpg",
    title: "8805 // FLEXIBLE FIT+",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 378,
    imgSrc: "/images/products/handarmprotection140.jpg",
    imgHoverSrc: "/images/products/handarmprotection140.jpg",
    title: "8810 // FLEXIBLE FIT K",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 379,
    imgSrc: "/images/products/handarmprotection141.jpg",
    imgHoverSrc: "/images/products/handarmprotection141.jpg",
    title: "8815 // FLEXIBLE FIT MF",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 380,
    imgSrc: "/images/products/handarmprotection142.jpg",
    imgHoverSrc: "/images/products/handarmprotection142.jpg",
    title: "8900 // TROPIC",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Mechanic's gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 381,
    imgSrc: "/images/products/handarmprotection143.jpg",
    imgHoverSrc: "/images/products/handarmprotection143.jpg",
    title: "8905 // DEXTER 1",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Mechanic's gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 382,
    imgSrc: "/images/products/handarmprotection144.jpg",
    imgHoverSrc: "/images/products/handarmprotection144.jpg",
    title: "8905W // DEXTER WINTER",
    sizes: ["7", "8", "9", "10", "11", "12"],
    subcategory: "Mechanic's gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 383,
    imgSrc: "/images/products/handarmprotection145.jpg",
    imgHoverSrc: "/images/products/handarmprotection145.jpg",
    title: "8910 // DEXTER 2",
    sizes: ["8", "9", "10", "11"],
    subcategory: "Mechanic's gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 384,
    imgSrc: "/images/products/handarmprotection146.jpg",
    imgHoverSrc: "/images/products/handarmprotection146.jpg",
    title: "8200 // IDEAL CARE",
    sizes: ["XS", "S", "M", "L", "XL"],
    subcategory: "Disposable gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 385,
    imgSrc: "/images/products/handarmprotection147.jpg",
    imgHoverSrc: "/images/products/handarmprotection147.jpg",
    title: "8201 // IDEAL CARE",
    sizes: ["S", "M", "L", "XL"],
    subcategory: "Disposable gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 386,
    imgSrc: "/images/products/handarmprotection148.jpg",
    imgHoverSrc: "/images/products/handarmprotection148.jpg",
    title: "6102 // SMART SWIPE",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 387,
    imgSrc: "/images/products/handarmprotection149.jpg",
    imgHoverSrc: "/images/products/handarmprotection149.jpg",
    title: "3525 // NYLOTEX SOFT HV",
    sizes: ["7", "8", "9", "10", "11", "12"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 388,
    imgSrc: "/images/products/handarmprotection150.jpg",
    imgHoverSrc: "/images/products/handarmprotection150.jpg",
    title: "20029 // CUT F",
    sizes: ["8", "9", "10", "11", "12"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 389,
    imgSrc: "/images/products/handarmprotection151.jpg",
    imgHoverSrc: "/images/products/handarmprotection151.jpg",
    title: "1104 // UNIVERSAL",
    sizes: ["10"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 390,
    imgSrc: "/images/products/handarmprotection152.jpg",
    imgHoverSrc: "/images/products/handarmprotection152.jpg",
    title: "8065 // HIGH RISK NITRILE",
    sizes: ["XS", "S", "M", "L", "XL", "2XL"],
    subcategory: "Disposable gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 391,
    imgSrc: "/images/products/handarmprotection153.jpg",
    imgHoverSrc: "/images/products/handarmprotection153.jpg",
    title: "8700ESD3D",
    sizes: ["5", "6", "7", "8", "9", "10", "11", "12"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 392,
    imgSrc: "/images/products/handarmprotection154.jpg",
    imgHoverSrc: "/images/products/handarmprotection154.jpg",
    title: "20540 // SAFE PRO",
    sizes: ["9", "10", "11", "12"],
    subcategory: "Welding gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 393,
    imgSrc: "/images/products/handarmprotection155.jpg",
    imgHoverSrc: "/images/products/handarmprotection155.jpg",
    title: "20440 // WELDER PRO",
    sizes: ["9", "10", "11", "12"],
    subcategory: "Welding gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 394,
    imgSrc: "/images/products/handarmprotection156.jpg",
    imgHoverSrc: "/images/products/handarmprotection156.jpg",
    title: "6845 // ESD 3D CUT F",
    sizes: ["6", "7", "8", "9", "10", "11", "12"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 395,
    imgSrc: "/images/products/handarmprotection157.jpg",
    imgHoverSrc: "/images/products/handarmprotection157.jpg",
    title: "6346 // ESD 3D CUT D",
    sizes: ["6", "7", "8", "9", "10", "11", "12"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 396,
    imgSrc: "/images/products/handarmprotection158.jpg",
    imgHoverSrc: "/images/products/handarmprotection158.jpg",
    title: "6345 // ESD 3D CUT C",
    sizes: ["6", "7", "8", "9", "10", "11", "12"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 397,
    imgSrc: "/images/products/handarmprotection159.jpg",
    imgHoverSrc: "/images/products/handarmprotection159.jpg",
    title: "8331 // TOUGH GRIP LIGHT",
    sizes: ["S", "M", "L", "XL", "2XL"],
    subcategory: "Disposable gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 398,
    imgSrc: "/images/products/handarmprotection160.jpg",
    imgHoverSrc: "/images/products/handarmprotection160.jpg",
    title: "3456 // GREEN BARRIER CUT",
    sizes: ["8", "9", "10", "11", "12"],
    subcategory: "Chemical protective gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
];

// * ================== Foot protection ==================

export const footprotection = [
  {
    id: 399,
    imgSrc: "/images/products/footprotection1.jpg",
    imgHoverSrc: "/images/products/footprotection1.jpg",
    title: "700C // COMFORT STEP",
    sizes: ["35", "45", "46", "47", "48", "49", "50"],
    subcategory: "Accessories",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 400,
    imgSrc: "/images/products/footprotection2.jpg",
    imgHoverSrc: "/images/products/footprotection2.jpg",
    title: "700H // ERGO STEP HIGH",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],

    subcategory: "Accessories",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 401,
    imgSrc: "/images/products/footprotection3.jpg",
    imgHoverSrc: "/images/products/footprotection3.jpg",
    title: "700L // ERGO STEP LOW",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Accessories",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 402,
    imgSrc: "/images/products/footprotection4.jpg",
    imgHoverSrc: "/images/products/footprotection4.jpg",
    title: "700M // ERGO STEP MEDIUM",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Accessories",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 403,
    imgSrc: "/images/products/footprotection5.jpg",
    imgHoverSrc: "/images/products/footprotection5.jpg",
    title: "7312 // EASY STEP SA",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Sandals",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 404,
    imgSrc: "/images/products/footprotection6.jpg",
    imgHoverSrc: "/images/products/footprotection7.jpg",
    title: "7200 // STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 405,
    imgSrc: "/images/products/footprotection8.jpg",
    imgHoverSrc: "/images/products/footprotection9.jpg",
    title: "7313 // EASY STEP P",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 406,
    imgSrc: "/images/products/footprotection10.jpg",
    imgHoverSrc: "/images/products/footprotection11.jpg",
    title: "7200MF // STEP MF",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 407,
    imgSrc: "/images/products/footprotection12.jpg",
    imgHoverSrc: "/images/products/footprotection13.jpg",
    title: "7410 // PRO STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 408,
    imgSrc: "/images/products/footprotection14.jpg",
    imgHoverSrc: "/images/products/footprotection15.jpg",
    title: "7411 // PRO STEP MID",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 409,
    imgSrc: "/images/products/footprotection16.jpg",
    imgHoverSrc: "/images/products/footprotection17.jpg",
    title: "7201 // STEP MID",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 410,
    imgSrc: "/images/products/footprotection18.jpg",
    imgHoverSrc: "/images/products/footprotection19.jpg",
    title: "7201 MF // STEP MID MF",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 411,
    imgSrc: "/images/products/footprotection20.jpg",
    imgHoverSrc: "/images/products/footprotection21.jpg",
    title: "7420 // MICRO STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 412,
    imgSrc: "/images/products/footprotection22.jpg",
    imgHoverSrc: "/images/products/footprotection23.jpg",
    title: "7201W // STEP MID+ W",
    sizes: ["38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48"],
    subcategory: "Winter boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 413,
    imgSrc: "/images/products/footprotection24.jpg",
    imgHoverSrc: "/images/products/footprotection25.jpg",
    title: "7421 // MICRO STEP MID",
    sizes: ["38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48"],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 414,
    imgSrc: "/images/products/footprotection26.jpg",
    imgHoverSrc: "/images/products/footprotection27.jpg",
    title: "7201W MF // STEP MID+ MF W",
    sizes: ["38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48"],
    subcategory: "Winter boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 415,
    imgSrc: "/images/products/footprotection28.jpg",
    imgHoverSrc: "/images/products/footprotection29.jpg",
    title: "7205 // BASIC STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 416,
    imgSrc: "/images/products/footprotection31.jpg",
    imgHoverSrc: "/images/products/footprotection32.jpg",
    title: "7206 // BASIC STEP MID",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 417,
    imgSrc: "/images/products/footprotection32.jpg",
    imgHoverSrc: "/images/products/footprotection33.jpg",
    title: "7207 // BASIC STEP SA",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Sandals",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 418,
    imgSrc: "/images/products/footprotection34.jpg",
    imgHoverSrc: "/images/products/footprotection35.jpg",
    title: "7208 // BASIC STEP MID+ W",
    sizes: ["38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48"],
    subcategory: "Winter boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 419,
    imgSrc: "/images/products/footprotection36.jpg",
    imgHoverSrc: "/images/products/footprotection37.jpg",
    title: "7210 // POWER STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 420,
    imgSrc: "/images/products/footprotection38.jpg",
    imgHoverSrc: "/images/products/footprotection39.jpg",
    title: "7211 // POWER STEP MID",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 421,
    imgSrc: "/images/products/footprotection40.jpg",
    imgHoverSrc: "/images/products/footprotection41.jpg",
    title: "7213 // POWER STEP MID+",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 422,
    imgSrc: "/images/products/footprotection42.jpg",
    imgHoverSrc: "/images/products/footprotection43.jpg",
    title: "7213W // POWER STEP MID+ W",
    sizes: [
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Winter boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 423,
    imgSrc: "/images/products/footprotection44.jpg",
    imgHoverSrc: "/images/products/footprotection45.jpg",
    title: "7250 // CLEAN STEP SL",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Slippers",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 424,
    imgSrc: "/images/products/footprotection46.jpg",
    imgHoverSrc: "/images/products/footprotection47.jpg",
    title: "7251 // CLEAN STEP MID SL",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Slippers",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 425,
    imgSrc: "/images/products/footprotection48.jpg",
    imgHoverSrc: "/images/products/footprotection49.jpg",
    title: "7252 // CLEAN STEP CL",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Clogs",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 426,
    imgSrc: "/images/products/footprotection50.jpg",
    imgHoverSrc: "/images/products/footprotection51.jpg",
    title: "7255 // CLEAN STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 427,
    imgSrc: "/images/products/footprotection52.jpg",
    imgHoverSrc: "/images/products/footprotection53.jpg",
    title: "7256 // CLEAN STEP MID",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 428,
    imgSrc: "/images/products/footprotection54.jpg",
    imgHoverSrc: "/images/products/footprotection55.jpg",
    title: "7300 // SPORT STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 429,
    imgSrc: "/images/products/footprotection54.jpg",
    imgHoverSrc: "/images/products/footprotection55.jpg",
    title: "7300 // SPORT STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 430,
    imgSrc: "/images/products/footprotection56.jpg",
    imgHoverSrc: "/images/products/footprotection57.jpg",
    title: "7301 // SPORT STEP MID",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 431,
    imgSrc: "/images/products/footprotection58.jpg",
    imgHoverSrc: "/images/products/footprotection59.jpg",
    title: "7301W // SPORT STEP MID W",
    sizes: ["38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48"],
    subcategory: "Winter boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 432,
    imgSrc: "/images/products/footprotection60.jpg",
    imgHoverSrc: "/images/products/footprotection61.jpg",
    title: "7302 // SPORT STEP SA",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Sandals",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 433,
    imgSrc: "/images/products/footprotection62.jpg",
    imgHoverSrc: "/images/products/footprotection63.jpg",
    title: "7305 // SPORT STEP WH",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 434,
    imgSrc: "/images/products/footprotection64.jpg",
    imgHoverSrc: "/images/products/footprotection65.jpg",
    title: "7310 // EASY STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 435,
    imgSrc: "/images/products/footprotection66.jpg",
    imgHoverSrc: "/images/products/footprotection66.jpg",
    title: "712C // COMFORT STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Accessories",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 436,
    imgSrc: "/images/products/footprotection67.jpg",
    imgHoverSrc: "/images/products/footprotection67.jpg",
    title: "711C // COMFORT STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Accessories",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 437,
    imgSrc: "/images/products/footprotection67.jpg",
    imgHoverSrc: "/images/products/footprotection67.jpg",
    title: "711C // COMFORT STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Accessories",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 438,
    imgSrc: "/images/products/footprotection68.jpg",
    imgHoverSrc: "/images/products/footprotection68.jpg",
    title: "710C // COMFORT STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Accessories",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 439,
    imgSrc: "/images/products/footprotection69.jpg",
    imgHoverSrc: "/images/products/footprotection70.jpg",
    title: "7414 // PRO STEP FIT",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 440,
    imgSrc: "/images/products/footprotection71.jpg",
    imgHoverSrc: "/images/products/footprotection72.jpg",
    title: "7413 // PRO STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 441,
    imgSrc: "/images/products/footprotection73.jpg",
    imgHoverSrc: "/images/products/footprotection74.jpg",
    title: "7413 // PRO STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 442,
    imgSrc: "/images/products/footprotection75.jpg",
    imgHoverSrc: "/images/products/footprotection76.jpg",
    title: "7413 // PRO STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 443,
    imgSrc: "/images/products/footprotection77.jpg",
    imgHoverSrc: "/images/products/footprotection78.jpg",
    title: "7440 // SONIC STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 444,
    imgSrc: "/images/products/footprotection79.jpg",
    imgHoverSrc: "/images/products/footprotection80.jpg",
    title: "7440 // SONIC STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 445,
    imgSrc: "/images/products/footprotection81.jpg",
    imgHoverSrc: "/images/products/footprotection82.jpg",
    title: "7440 // SONIC STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 446,
    imgSrc: "/images/products/footprotection83.jpg",
    imgHoverSrc: "/images/products/footprotection83.jpg",
    title: "7440 // SONIC STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 447,
    imgSrc: "/images/products/footprotection84.jpg",
    imgHoverSrc: "/images/products/footprotection85.jpg",
    title: "7306 // SPORT STEP FIT",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 448,
    imgSrc: "/images/products/footprotection86.jpg",
    imgHoverSrc: "/images/products/footprotection87.jpg",
    title: "7307 // SPORT STEP MID FIT",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 449,
    imgSrc: "/images/products/footprotection88.jpg",
    imgHoverSrc: "/images/products/footprotection89.jpg",
    title: "7435 // SOLID STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 450,
    imgSrc: "/images/products/footprotection90.jpg",
    imgHoverSrc: "/images/products/footprotection91.jpg",
    title: "7435 // SOLID STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 451,
    imgSrc: "/images/products/footprotection92.jpg",
    imgHoverSrc: "/images/products/footprotection93.jpg",
    title: "7436 // SOLID STEP MID",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 452,
    imgSrc: "/images/products/footprotection94.jpg",
    imgHoverSrc: "/images/products/footprotection95.jpg",
    title: "7320 // CLASSIC STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 453,
    imgSrc: "/images/products/footprotection96.jpg",
    imgHoverSrc: "/images/products/footprotection97.jpg",
    title: "7320 // CLASSIC STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 454,
    imgSrc: "/images/products/footprotection98.jpg",
    imgHoverSrc: "/images/products/footprotection99.jpg",
    title: "7215 // UTILITY STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 455,
    imgSrc: "/images/products/footprotection100.jpg",
    imgHoverSrc: "/images/products/footprotection101.jpg",
    title: "7216 // UTILITY STEP MID",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 456,
    imgSrc: "/images/products/footprotection102.jpg",
    imgHoverSrc: "/images/products/footprotection103.jpg",
    title: "7220 // ULTIMATE STEP MID FIT",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 457,
    imgSrc: "/images/products/footprotection104.jpg",
    imgHoverSrc: "/images/products/footprotection105.jpg",
    title: "7221 // ULTIMATE STEP HIGH FIT",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
];

// * ================== Respiratory protection ==================

export const respiratoryprotection = [
  {
    id: 458,
    imgSrc: "/images/products/respiratoryprotection1.jpg",
    imgHoverSrc: "/images/products/respiratoryprotection1.jpg",
    title: "4120SI // SAFE AIR",
    sizes: ["/"],
    subcategory: "Respirator masks",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Respiratory protection",
    isAvailable: true,
  },
  {
    id: 459,
    imgSrc: "/images/products/respiratoryprotection2.jpg",
    imgHoverSrc: "/images/products/respiratoryprotection2.jpg",
    title: "4130SI // SAFE AIR",
    sizes: ["/"],
    subcategory: "Respirator masks",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Respiratory protection",
    isAvailable: true,
  },
  {
    id: 460,
    imgSrc: "/images/products/respiratoryprotection3.jpg",
    imgHoverSrc: "/images/products/respiratoryprotection3.jpg",
    title: "4140SI // SAFE AIR",
    sizes: ["/"],
    subcategory: "Respirator masks",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Respiratory protection",
    isAvailable: true,
  },
];

// * ================== Eye protection ==================

export const eyeprotection = [
  {
    id: 461,
    imgSrc: "/images/products/eyeprotection1.jpg",
    imgHoverSrc: "/images/products/eyeprotection2.jpg",
    title: "9000 // VISION PROTECT BASIC",
    sizes: ["/"],
    subcategory: "Spectacles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 462,
    imgSrc: "/images/products/eyeprotection3.jpg",
    imgHoverSrc: "/images/products/eyeprotection4.jpg",
    title: "9001 // VISION PROTECT BASIC",
    sizes: ["/"],
    subcategory: "Spectacles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 463,
    imgSrc: "/images/products/eyeprotection5.jpg",
    imgHoverSrc: "/images/products/eyeprotection6.jpg",
    title: "9010 // VISION PROTECT",
    sizes: ["/"],
    subcategory: "Spectacles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 464,
    imgSrc: "/images/products/eyeprotection7.jpg",
    imgHoverSrc: "/images/products/eyeprotection8.jpg",
    title: "9011 // VISION PROTECT",
    sizes: ["/"],
    subcategory: "Spectacles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 465,
    imgSrc: "/images/products/eyeprotection9.jpg",
    imgHoverSrc: "/images/products/eyeprotection10.jpg",
    title: "9012 // VISION PROTECT",
    sizes: ["/"],
    subcategory: "Spectacles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 466,
    imgSrc: "/images/products/eyeprotection11.jpg",
    imgHoverSrc: "/images/products/eyeprotection12.jpg",
    title: "9013 // VISION PROTECT",
    sizes: ["/"],
    subcategory: "Spectacles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 467,
    imgSrc: "/images/products/eyeprotection13.jpg",
    imgHoverSrc: "/images/products/eyeprotection14.jpg",
    title: "9015 // VISION PROTECT OTG",
    sizes: ["/"],
    subcategory: "Spectacles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 468,
    imgSrc: "/images/products/eyeprotection15.jpg",
    imgHoverSrc: "/images/products/eyeprotection16.jpg",
    title: "9020 // VISION PROTECT PREMIUM",
    sizes: ["/"],
    subcategory: "Spectacles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 469,
    imgSrc: "/images/products/eyeprotection17.jpg",
    imgHoverSrc: "/images/products/eyeprotection18.jpg",
    title: "9021 // VISION PROTECT PREMIUM",
    sizes: ["/"],
    subcategory: "Spectacles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 470,
    imgSrc: "/images/products/eyeprotection19.jpg",
    imgHoverSrc: "/images/products/eyeprotection20.jpg",
    title: "9021 // VISION PROTECT PREMIUM",
    sizes: ["One Size"],
    subcategory: "Full-vision goggles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
];

// * ================== Head protection ==================

export const headprotection = [
  {
    id: 471,
    imgSrc: "/images/products/headprotection1.jpg",
    imgHoverSrc: "/images/products/headprotection2.jpg",
    title: "9500 // HEAD PROTECT",
    sizes: ["One Size"],
    subcategory: "Safety helmets",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 472,
    imgSrc: "/images/products/headprotection3.jpg",
    imgHoverSrc: "/images/products/headprotection4.jpg",
    title: "9500 // HEAD PROTECT",
    sizes: ["One Size"],
    subcategory: "Safety helmets",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 473,
    imgSrc: "/images/products/headprotection5.jpg",
    imgHoverSrc: "/images/products/headprotection6.jpg",
    title: "9500 // HEAD PROTECT",
    sizes: ["One Size"],
    subcategory: "Safety helmets",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 474,
    imgSrc: "/images/products/headprotection7.jpg",
    imgHoverSrc: "/images/products/headprotection8.jpg",
    title: "9500 // HEAD PROTECT",
    sizes: ["One Size"],
    subcategory: "Safety helmets",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 475,
    imgSrc: "/images/products/headprotection9.jpg",
    imgHoverSrc: "/images/products/headprotection10.jpg",
    title: "9500 // HEAD PROTECT",
    sizes: ["One Size"],
    subcategory: "Safety helmets",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 476,
    imgSrc: "/images/products/headprotection11.jpg",
    imgHoverSrc: "/images/products/headprotection12.jpg",
    title: "9500 // HEAD PROTECT",
    sizes: ["One Size"],
    subcategory: "Safety helmets",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 477,
    imgSrc: "/images/products/headprotection13.jpg",
    imgHoverSrc: "/images/products/headprotection14.jpg",
    title: "9500 // HEAD PROTECT",
    sizes: ["One Size"],
    subcategory: "Safety helmets",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 478,
    imgSrc: "/images/products/headprotection13.jpg",
    imgHoverSrc: "/images/products/headprotection14.jpg",
    title: "9500 // HEAD PROTECT",
    sizes: ["One Size"],
    subcategory: "Safety helmets",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
];

// * ================== Kids selection ==================

export const kidsselection = [
  {
    id: 479,
    imgSrc: "/images/products/kidsselection1.jpg",
    imgHoverSrc: "/images/products/kidsselection1.jpg",
    title: "1408NITRAS",
    sizes: ["2", "3", "4"],
    subcategory: "Hand protection",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 480,
    imgSrc: "/images/products/kidsselection2.jpg",
    imgHoverSrc: "/images/products/kidsselection3.jpg",
    title: "7171KIDS // MOTION TEX KIDS",
    sizes: ["98/104", "110/116", "122/128", "134/140", "146/152", "158/164"],
    subcategory: "Garments",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 481,
    imgSrc: "/images/products/kidsselection4.jpg",
    imgHoverSrc: "/images/products/kidsselection5.jpg",
    title: "7180KIDS // MOTION TEX KIDS",
    sizes: ["98/104", "110/116", "122/128", "134/140", "146/152", "158/164"],
    subcategory: "Garments",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 482,
    imgSrc: "/images/products/kidsselection6.jpg",
    imgHoverSrc: "/images/products/kidsselection7.jpg",
    title: "7190KIDS // MOTION TEX KIDS",
    sizes: ["98/104", "110/116", "122/128", "134/140", "146/152", "158/164"],
    subcategory: "Garments",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 483,
    imgSrc: "/images/products/kidsselection8.jpg",
    imgHoverSrc: "/images/products/kidsselection9.jpg",
    title: "7192KIDS // MOTION TEX KIDS",
    sizes: ["98/104", "110/116", "122/128", "134/140", "146/152", "158/164"],
    subcategory: "Garments",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 484,
    imgSrc: "/images/products/kidsselection10.jpg",
    imgHoverSrc: "/images/products/kidsselection11.jpg",
    title: "7602KIDS // MOTION TEX KIDS",
    sizes: ["98/104", "110/116", "122/128", "134/140", "146/152", "158/164"],
    subcategory: "Garments",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 485,
    imgSrc: "/images/products/kidsselection12.jpg",
    imgHoverSrc: "/images/products/kidsselection13.jpg",
    title: "7612KIDS // MOTION TEX KIDS",
    sizes: ["98/104", "110/116", "122/128", "134/140", "146/152", "158/164"],
    subcategory: "Garments",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 486,
    imgSrc: "/images/products/kidsselection14.jpg",
    imgHoverSrc: "/images/products/kidsselection14.jpg",
    title: "7180W KIDS/ MOTION TEX KIDS",
    sizes: ["98/104", "110/116", "122/128", "134/140", "146/152", "158/164"],
    subcategory: "Garments",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 487,
    imgSrc: "/images/products/kidsselection15.jpg",
    imgHoverSrc: "/images/products/kidsselection16.jpg",
    title: "7175KIDS // MOTION TEX KIDS",
    sizes: ["98/104", "110/116", "122/128", "134/140", "146/152", "158/164"],
    subcategory: "Garments",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 488,
    imgSrc: "/images/products/kidsselection17.jpg",
    imgHoverSrc: "/images/products/kidsselection18.jpg",
    title: "7175KIDS // MOTION TEX KIDS",
    sizes: ["One Size"],
    subcategory: "Headgear",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 489,
    imgSrc: "/images/products/kidsselection19.jpg",
    imgHoverSrc: "/images/products/kidsselection20.jpg",
    title: "730KIDS",
    sizes: ["One Size"],
    subcategory: "Headgear",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
];

export const allProducts = [
  ...workweare1,
  ...protectiveclothing,
  ...respiratoryprotection,
  ...eyeprotection,
  ...headprotection,
  ...kidsselection,
  ...handarmprotection,
  ...footprotection,
  ...products1,
];
